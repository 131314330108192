import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../context/UserContext";
import { getClassesBasedOnBranchId } from "../../../api/api";
// import CustomDropdown from "../../FeeManagementModule/components/Custom-Dropdown";
import { CustomDropdownWithCustomStyle } from "./CustomDropdownWithCustomStyle";
import { useLocation } from "react-router-dom";
export const Classsectiondetailsdropdown = ({
  defaultText = "Select a Class-Section",
  handledatachange=()=>{},
  getclassname=()=>{},
  width = "32.5rem",
}) => {
  const { userInfo } = useContext(UserContext);
  const [initialGrades, setInitialGrades] = useState({});
  const [classsectionmappingIds, setclasssectionMappingIds] = useState([]);
  //eslint-disable-next-line
  const [classidandsectionid, setclassidandsectionid] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const index = queryParams.get("index");
  useEffect(() => {
    console.log("index", index, initialGrades[index]);
    if (classsectionmappingIds.length) {
      getclassname(initialGrades[index]);
      handledatachange(
        "class_section_mapping_id",
        classsectionmappingIds[index]
      );
    }
    //eslint-disable-next-line
  }, [location]);


  useEffect(() => {
    if (userInfo.branch_id !== undefined && userInfo.branch_id !== null) {
      getClassesBasedOnBranchId(userInfo.branch_id).then((res) => {
        const sections = res.response.sections;
        console.log(sections);
        let grades = [];
        sections.reduce((acc, { class: className, section }) => {
          if (!acc[className]) {
            acc[className] = [];
          }
          // acc[className].push(`${className}-${section}`);
          grades.push(`${className}-${section}`);
          return acc;
        }, {});

        const ids = sections.map(
          ({ class_section_mapping_id }) => class_section_mapping_id
        );
        const classAndSectionIdTogether = sections.map(
          ({ class_id, section_id }) => ({ class_id, section_id })
        );

        console.log(classAndSectionIdTogether);
        setclassidandsectionid(classAndSectionIdTogether);
        setInitialGrades(grades);
        setclasssectionMappingIds(ids);

        console.log("intial gradesss", grades);
        console.log("mappings", ids);

        // const url = new URL(window.location.href);
        // url.searchParams.set("grades", "all");
        // navigate(url.pathname + url.search);
      });
    }
    //eslint-disable-next-line
  }, [userInfo.branch_id]);
  const classsectionchange = (...value) => {
    console.log(value);
  };
  return (
    <div>
      <CustomDropdownWithCustomStyle
        defaultText={defaultText}
        // icon={<FilterIcon />}
        items={initialGrades}
        width={width}
        dropdownwidth={width}
        scrollenable={true}
        dropdownName={"Class-Section"}
        field={"class-section"}
        handledatachange={classsectionchange}
      />
    </div>
  );
};
