import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./CheckBoxDropDown.css";
import UserContext from "../../../context/UserContext";
import {
  useOutsideClick,
  useToggle,
} from "../../../modules/FeeManagementModule/FeeStructureModule/hooks";
export const CheckBoxDropDown=({ Items =["item1","item2"] ,
righticon,
lefticon,
dropdownname = "Select Items",
defaultname = "Select Items",
dropdownwidth = '',
width = '',
subitems=true,
scrollenable=true,
showselected=false,
selecteditems=[],
handledatachange = () => {},})=>{
  console.log("selected classses",selecteditems);
  const scrollstylingcustom = {
    "max-height": "10rem",
    overflow: "auto",
    width: dropdownwidth!==""?dropdownwidth:width,
  };
  const [checkedList, setCheckedList] = useState(new Array(Items.length).fill(false));
  const [checkAll, setCheckAll] = useState(false);
  const [list, setList] = useState([]);
  //eslint-disable-next-line
  const [dropdownItems, setdropdownItems] = useState(Items);
  const dropdownRef = useRef(null);
  const { state: isOpen, handlers } = useToggle();
  useOutsideClick(dropdownRef, handlers.off);
const [selecteditem,setselecteditems]=useState(selecteditems);
  // const { userInfo } = useContext(UserContext);
  // const navigate = useNavigate();
 useEffect(() => {
 console.log("hellooo");
 if(selecteditems.length)
 {let temp = [...checkedList];
 
  selecteditems.forEach((selectedItem) => {
    const index = Items.indexOf(selectedItem);
    if (index !== -1) {
      temp[index] = true;
    }
  });console.log(selecteditems,Items)
  console.log(temp);
  setCheckedList(temp);}
  //eslint-disable-next-line
 }, [])
 
  
  useEffect(() => {
    const filteredCheckedList = checkedList.filter(
      (item) => !["Select all", ...Object.keys(dropdownItems)].includes(item)
    );
    setList(filteredCheckedList);
  }, [checkedList, dropdownItems]);
  // const onChange = (option) => {
  //   setCheckedList((prevCheckedList) => {
  //     let updatedCheckedList = [...prevCheckedList];

  //     if (updatedCheckedList.includes(option)) {
  //       updatedCheckedList = updatedCheckedList.filter((item) => item !== option);
  //     } else {
  //       updatedCheckedList.push(option);
  //     }

  //     // handledatachange("filter", updatedCheckedList);
  //     return updatedCheckedList;
  //   });
  // }
  useEffect(() => {
    if(checkedList.length!==0)
    setCheckAll(checkedList.every(item => item === true));

    handledatachange(dropdownname, checkedList);
    let temp=[];
  for(let i=0;i<Items.length;i++){
    if(checkedList[i]){
temp.push(Items[i]);
    }
  }
    setselecteditems(temp);
    //eslint-disable-next-line               
  }, [checkedList])
  const onCheckAllChange=()=>{
    if(!checkAll){
      setCheckedList(new Array(Items.length).fill(true));
    }
    else {
      setCheckedList(new Array(Items.length).fill(false));}
      setCheckAll(!checkAll);
  }
  const chunkSize = 5; // Number of items per row

  // Function to chunk the array into arrays of specified size
  const chunkArray = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
      arr.slice(index * size, index * size + size)
    );
  };

  // Chunk the classNames array
  const classChunks = chunkArray(selecteditem, chunkSize);
  return(
    <div className="dropdown_container" ref={dropdownRef}>
   {!showselected && <button
      className="dropdown_dropdown-button"
      style={{ justifyContent: 'space-between', width: width, gap: '0.5rem' }}
      onClick={handlers.toggle}
    >
      {lefticon}
      <>
        {defaultname}
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="custom__dropdown--icon"
        >
          <path
            d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"
            fill="#99A0AE"
          ></path>
        </svg>
      </>
    </button>}
  
   {showselected && <div className="selected-classes" style={{  alignContent: 'left', justifyContent: 'left' ,width:width,border: '1px solid var(--stroke-soft-200, rgba(225, 228, 234, 1))',paddingBottom:selecteditem.length===0?'0.5rem':'0'
   }}  onClick={handlers.toggle}>
            <div className="divselectclass" >   
            {classChunks.map((chunk, rowIndex) => (
          <div key={rowIndex} className="class-row" style={{display:'flex'}}>
            {chunk.map((item,index) => (
              <span key={index} className="class-tag" style={{ width: '64px' }}>{item}</span>
            ))}
          </div>
         
        ))}
        </div>
        <span className="dropdown-icon1"> <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="custom__dropdown--icon"
        >
          <path
            d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"
            fill="#99A0AE"
          ></path>
        </svg></span>
          </div>}
    {isOpen && (
      <div className="dropdown_dropdown"  style={scrollenable ? scrollstylingcustom :  { width: width}}>
        <div className="dropdown_checkbox-group"  >
          <label className="dropdown_checkbox-label">
            <div>
              <input
                type="checkbox"
                checked={checkAll}
                className="dropdown_table-checkbox"
                onChange={onCheckAllChange}
              />
              <span className="dropdown_label-text">Select all</span>
            </div>
            {list.length !== 0 && (
              <span className="dropdown_checked-count">{list.length}</span>
            )}
          </label>

          {Items.map((item, index) => (
  <React.Fragment key={index}>
    <label className="dropdown_checkbox-label">
      <input
        type="checkbox"
        className="dropdown_table-checkbox dropdown_table-parent-checkbox"
        checked={checkedList[index]}
        onChange={() => {
          console.log(index);
          const newArray = [...checkedList];
          newArray[index] = !newArray[index];
          setCheckedList(newArray);
        }}
      />
      {item}
    </label>
  </React.Fragment>
))}
        </div>
      </div>
    )}
  </div>
  )
}



export const CheckBoxDropDownWithSubItems = ({
  Items = {
    "Profile Status": ["Completed Profiles", "InCompleted Profiles"],
    "Class Status": ["Classes assigned", "Classes not assigned"],
  },
  righticon,
  lefticon,
  dropdownname = "Select Items",
  dropdownwidth = '',
  width = '',
  subitems=true,
  handledatachange = () => {},
}) => {
  console.log(Items);
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [list, setList] = useState([]);
  const [dropdownItems, setdropdownItems] = useState({});
  const dropdownRef = useRef(null);
  const { state: isOpen, handlers } = useToggle();
  useOutsideClick(dropdownRef, handlers.off);

  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.branch_id !== undefined && userInfo.branch_id !== null) {
      let formattedItems = Items;
      if (Array.isArray(Items)) {
        formattedItems = { "Items": Items };
      } else if (typeof Items === 'object' && !Array.isArray(Items)) {
        formattedItems = Object.keys(Items).reduce((acc, key) => {
          acc[key] = Array.isArray(Items[key]) ? Items[key] : [];
          return acc;
        }, {});
      }
      setdropdownItems(formattedItems);
      setCheckedList(
        Object.keys(formattedItems).flatMap((item) => [item, ...formattedItems[item]])
      );
      console.log("initial items", dropdownItems);

      const url = new URL(window.location.href);
      url.searchParams.set("grades", "all");
      navigate(url.pathname + url.search);
    }
    //eslint-disable-next-line
  }, [userInfo.branch_id, navigate]);

  const allOptions = useMemo(
    () => [
      "Select all",
      ...Object.keys(dropdownItems).flatMap((grade) => [
        grade,
        ...dropdownItems[grade],
      ]),
    ],
    [dropdownItems]
  );

  useEffect(() => {
    const filteredCheckedList = checkedList.filter(
      (item) => !["Select all", ...Object.keys(dropdownItems)].includes(item)
    );
    setList(filteredCheckedList);
  }, [checkedList, dropdownItems]);

  useEffect(() => {
    if (checkedList.length === allOptions.length - 1) {
      setCheckAll(true);
      const url = new URL(window.location.href);
      url.searchParams.set("grades", "all");
      navigate(url.pathname + url.search);
    } else {
      setCheckAll(false);
    }
  }, [checkedList, allOptions.length, navigate]);

  const onChange = (option, subOption) => {
    setCheckedList((prevCheckedList) => {
      let updatedCheckedList = [...prevCheckedList];

      if (subOption) {
        if (updatedCheckedList.includes(subOption)) {
          updatedCheckedList = updatedCheckedList.filter(
            (item) => item !== subOption
          );
        } else {
          updatedCheckedList.push(subOption);
        }

        const parentGrade = option;
       
        const allSubGradesChecked = dropdownItems[parentGrade].every(
          (subGrade) => updatedCheckedList.includes(subGrade)
        );
        if (allSubGradesChecked && !updatedCheckedList.includes(parentGrade)) {
          updatedCheckedList.push(parentGrade);
        } else if (
          !allSubGradesChecked &&
          updatedCheckedList.includes(parentGrade)
        ) {
          updatedCheckedList = updatedCheckedList.filter(
            (item) => item !== parentGrade
          );
        }
      } else {
        const subGrades = dropdownItems[option];
        const allSubGradesChecked = subGrades.every((subGrade) =>
          updatedCheckedList.includes(subGrade)
        );

        if (allSubGradesChecked) {
          updatedCheckedList = updatedCheckedList.filter(
            (item) => !subGrades.includes(item)
          );
          updatedCheckedList = updatedCheckedList.filter(
            (item) => item !== option
          );
        } else {
          updatedCheckedList = [
            ...updatedCheckedList,
            option,
            ...subGrades.filter(
              (subGrade) => !updatedCheckedList.includes(subGrade)
            ),
          ];
        }
      }

      const allSelected = Object.keys(dropdownItems).every((grade) =>
        [grade, ...dropdownItems[grade]].every((item) =>
          updatedCheckedList.includes(item)
        )
      );
      setCheckAll(allSelected);

      handledatachange("filter", updatedCheckedList);
      return updatedCheckedList;
    });
  };

  const onCheckAllChange = () => {
    if (checkAll) {
      setCheckedList([]);
      setCheckAll(false);
      const url = new URL(window.location.href);
      url.searchParams.delete("grades");
      navigate(url.pathname + url.search);
    } else {
      setCheckedList(allOptions.slice(1));
      setCheckAll(true);
      const url = new URL(window.location.href);
      url.searchParams.set("grades", "all");
      navigate(url.pathname + url.search);
    }
  };

  return (
    <div className="dropdown_container" ref={dropdownRef}>
      <button
        className="dropdown_dropdown-button"
        style={{ justifyContent: 'space-between', width: width, gap: '0.5rem' }}
        onClick={handlers.toggle}
      >
        {lefticon}
        <>
          {dropdownname}
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="custom__dropdown--icon"
          >
            <path
              d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"
              fill="#99A0AE"
            ></path>
          </svg>
        </>
      </button>
      {isOpen && (
        <div className="dropdown_dropdown" style={{ width: width }}>
          <div className="dropdown_checkbox-group">
            <label className="dropdown_checkbox-label">
              <div>
                <input
                  type="checkbox"
                  checked={checkAll}
                  className="dropdown_table-checkbox"
                  onChange={onCheckAllChange}
                />
                <span className="dropdown_label-text">Select all</span>
              </div>
              {list.length !== 0 && (
                <span className="dropdown_checked-count">{list.length}</span>
              )}
            </label>

            {Object.entries(dropdownItems).map(([grade, subGrades]) => (
              <React.Fragment key={grade}>
                <label className="dropdown_checkbox-label">
                  <input
                    type="checkbox"
                    className="dropdown_table-checkbox dropdown_table-parent-checkbox"
                    checked={checkedList.includes(grade)}
                    onChange={() => onChange(grade)}
                  />
                  {grade}
                </label>
                {(checkedList.includes(grade) ||
                  subGrades.some((subGrade) =>
                    checkedList.includes(subGrade)
                  )) &&
                  subGrades.length > 0 && Array.isArray(subGrades) && subGrades.map((subGrade) => (
                    <label
                      className="dropdown_checkbox-child-label"
                      key={subGrade}
                    >
                      <input
                        type="checkbox"
                        className="dropdown_table-checkbox"
                        checked={checkedList.includes(subGrade)}
                        onChange={() => onChange(grade, subGrade)}
                      />
                      <span>{subGrade}</span>
                    </label>
                  ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};