import React, { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import { SlArrowLeft,SlArrowRight } from 'react-icons/sl';
// import { useStartDate } from '../../FeeManagementModule/FeeStructureModule/hooks';
const parseTime = (timeString) => {
    const time = timeString.slice(0, -2);
    const modifier = timeString.slice(-2);
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
        hours = '00';
    }
    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }
    return new Date(new Date().setHours(hours, minutes, 0, 0));
};
const TimeSelector = ({inputtime,index,setslotdetails}) => {
    const [time, setTime] = useState(parseTime(inputtime)); // Initialize with inputtime
    const formatTime = (date) => {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };
  
    const changeTime = (minutes) => {
      const newTime = new Date(time.getTime() + minutes * 60000);
      setTime(newTime);
      setslotdetails(prev => ({
        ...prev,
        [index]: formatTime(newTime)
    }));
      
    };
  
    return (
      <div style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between', backgroundColor: 'rgba(245, 247, 250, 1)', padding: '0.5rem 0rem', borderRadius: '4px' ,height:'1.8rem'}}>
        <button onClick={() => changeTime(-5)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          <span style={{  backgroundColor: 'white',padding:'0.5rem',borderRadius:'6px',cursor:'pointer' }}> <SlArrowLeft size={10} strokeWidth={90} color="rgba(82, 88, 102, 1)" /></span>
        </button>
        <span style={{ fontSize: '1rem',fontWeight:'500', color: 'rgba(82, 88, 102, 1)' }}>{formatTime(time)}</span>
        <button onClick={() => changeTime(5)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          <span style={{  backgroundColor: 'white',padding:'0.5rem',borderRadius:'6px',cursor:'pointer' }}> <SlArrowRight size={10} strokeWidth={90} color="rgba(82, 88, 102, 1)" /></span>
        </button>
      </div>
    );
  };
export const EditPeriodTimingsModal = ({ onClose, position,slot,handleedittimeslot ,handledeletebreak}) => {
    console.log(slot)
    const [slotdetails,setslotdetails]=useState(slot);
    const calculateDuration = (startTime, endTime) => {
        const start = parseTime(startTime);
        const end = parseTime(endTime);
        const durationInMinutes = (end - start) / 60000; // Convert milliseconds to minutes
        const hours = Math.floor(durationInMinutes / 60);
        const minutes = durationInMinutes % 60;
        return `${hours}h ${minutes}m`;
    };
    const duration = calculateDuration(slotdetails.start, slotdetails.end);

  return (
    <div className='assignperiodmodal' style={{ position: 'absolute', top: position.top +20, left: position.left,width:'16.5rem' }}>
      <div className="assignperiodmodalheader">
       {slot.isbreak?"Edit and Delete Break":"Edit Period Timings"}
        <RxCross1
          className="crossicon"
          size={11}
          strokeWidth={0.5}
          color="rgba(251, 55, 72, 1)"
          onClick={onClose} // Close modal on click
        />
      </div>
      <div className="assignperiodmodalbody" style={{gap:'0.8rem'}}>
  
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.8rem' }}>
            From
            <TimeSelector inputtime={slot.start} index={"start"} setslotdetails={setslotdetails}/>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.8rem' }}>
            To
            <TimeSelector inputtime={slot.end} index={"end"} setslotdetails={setslotdetails}/> 
        </div>
        <div style={{display:'flex',justifyContent:'space-between',fontSize:'14px',fontWeight:'500'
        }}>

        <div style={{ color: 'rgba(153, 160, 174, 1)' }}>
          TOTAL DURATION
        </div>
        <div>
          {duration}
        </div>
        </div>
        <div style={{display:'flex',justifyContent:'space-between'}}>

        <button className="button" style={{ width: '6.5rem', fontWeight: '500' }} onClick={()=>{handleedittimeslot(slotdetails);onClose();}}>Apply</button>
        {slot.isbreak && <button className="button" style={{ width: '6.5rem', fontWeight: '500',backgroundColor:'rgba(251, 55, 72, 1)' ,border:'0'}} onClick={()=>{handledeletebreak(slot);onClose();}}>Delete</button>}
        </div>
      </div>
    </div>
  );
};

export default EditPeriodTimingsModal;