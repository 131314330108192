import PropTypes from "prop-types";

export const UserFill = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M6.3999 28.0008C6.3999 25.4547 7.41133 23.0129 9.21168 21.2126C11.012 19.4122 13.4538 18.4008 15.9999 18.4008C18.546 18.4008 20.9878 19.4122 22.7881 21.2126C24.5885 23.0129 25.5999 25.4547 25.5999 28.0008H6.3999ZM15.9999 17.2008C12.0219 17.2008 8.7999 13.9788 8.7999 10.0008C8.7999 6.02278 12.0219 2.80078 15.9999 2.80078C19.9779 2.80078 23.1999 6.02278 23.1999 10.0008C23.1999 13.9788 19.9779 17.2008 15.9999 17.2008Z"
          fill="#525866"
        />
      </svg>
    </div>
  );
};

UserFill.propTypes = {
  className: PropTypes.string,
};

export const MailSvg = ({ className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
      >
        <path
          d="M1.25 0.75H14.75C14.9489 0.75 15.1397 0.829018 15.2803 0.96967C15.421 1.11032 15.5 1.30109 15.5 1.5V12.5C15.5 12.6989 15.421 12.8897 15.2803 13.0303C15.1397 13.171 14.9489 13.25 14.75 13.25H1.25C1.05109 13.25 0.860322 13.171 0.71967 13.0303C0.579018 12.8897 0.5 12.6989 0.5 12.5V1.5C0.5 1.30109 0.579018 1.11032 0.71967 0.96967C0.860322 0.829018 1.05109 0.75 1.25 0.75ZM14 3.9285L8.054 9.2535L2 3.912V11.75H14V3.9285ZM2.38325 2.25L8.04575 7.2465L13.6265 2.25H2.38325Z"
          fill="#99A0AE"
        />
      </svg>
    </span>
  );
};

MailSvg.propTypes = {
  className: PropTypes.string,
};

export const LockerSvg = ({ className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="17"
        viewBox="0 0 14 17"
        fill="none"
      >
        <path
          d="M2.5 6V5.25C2.5 4.05653 2.97411 2.91193 3.81802 2.06802C4.66193 1.22411 5.80653 0.75 7 0.75C8.19347 0.75 9.33807 1.22411 10.182 2.06802C11.0259 2.91193 11.5 4.05653 11.5 5.25V6H13C13.1989 6 13.3897 6.07902 13.5303 6.21967C13.671 6.36032 13.75 6.55109 13.75 6.75V15.75C13.75 15.9489 13.671 16.1397 13.5303 16.2803C13.3897 16.421 13.1989 16.5 13 16.5H1C0.801088 16.5 0.610322 16.421 0.46967 16.2803C0.329018 16.1397 0.25 15.9489 0.25 15.75V6.75C0.25 6.55109 0.329018 6.36032 0.46967 6.21967C0.610322 6.07902 0.801088 6 1 6H2.5ZM12.25 7.5H1.75V15H12.25V7.5ZM6.25 11.799C5.96404 11.6339 5.74054 11.3791 5.61418 11.074C5.48782 10.7689 5.46565 10.4307 5.55111 10.1117C5.63657 9.79278 5.82489 9.51095 6.08686 9.30993C6.34882 9.10892 6.6698 8.99996 7 8.99996C7.3302 8.99996 7.65118 9.10892 7.91314 9.30993C8.17511 9.51095 8.36343 9.79278 8.44889 10.1117C8.53435 10.4307 8.51218 10.7689 8.38582 11.074C8.25946 11.3791 8.03596 11.6339 7.75 11.799V13.5H6.25V11.799ZM4 6H10V5.25C10 4.45435 9.68393 3.69129 9.12132 3.12868C8.55871 2.56607 7.79565 2.25 7 2.25C6.20435 2.25 5.44129 2.56607 4.87868 3.12868C4.31607 3.69129 4 4.45435 4 5.25V6Z"
          fill="#99A0AE"
        />
      </svg>
    </span>
  );
};

LockerSvg.propTypes = {
  className: PropTypes.string,
};

export const EyeSvg = ({ className, onClick }) => {
  return (
    <span className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
      >
        <path
          d="M8.99999 0.25C13.044 0.25 16.4085 3.16 17.1142 7C16.4092 10.84 13.044 13.75 8.99999 13.75C4.95599 13.75 1.59149 10.84 0.885742 7C1.59074 3.16 4.95599 0.25 8.99999 0.25ZM8.99999 12.25C10.5296 12.2497 12.0138 11.7301 13.2096 10.7764C14.4055 9.82264 15.2422 8.49121 15.5827 7C15.2409 5.50998 14.4037 4.18 13.208 3.22752C12.0122 2.27504 10.5287 1.7564 8.99999 1.7564C7.47126 1.7564 5.98776 2.27504 4.79202 3.22752C3.59629 4.18 2.75907 5.50998 2.41724 7C2.75781 8.49121 3.5945 9.82264 4.79035 10.7764C5.9862 11.7301 7.47039 12.2497 8.99999 12.25ZM8.99999 10.375C8.10489 10.375 7.24644 10.0194 6.61351 9.38649C5.98057 8.75355 5.62499 7.89511 5.62499 7C5.62499 6.10489 5.98057 5.24645 6.61351 4.61351C7.24644 3.98058 8.10489 3.625 8.99999 3.625C9.8951 3.625 10.7535 3.98058 11.3865 4.61351C12.0194 5.24645 12.375 6.10489 12.375 7C12.375 7.89511 12.0194 8.75355 11.3865 9.38649C10.7535 10.0194 9.8951 10.375 8.99999 10.375ZM8.99999 8.875C9.49727 8.875 9.97419 8.67746 10.3258 8.32582C10.6774 7.97419 10.875 7.49728 10.875 7C10.875 6.50272 10.6774 6.02581 10.3258 5.67417C9.97419 5.32254 9.49727 5.125 8.99999 5.125C8.50271 5.125 8.0258 5.32254 7.67417 5.67417C7.32254 6.02581 7.12499 6.50272 7.12499 7C7.12499 7.49728 7.32254 7.97419 7.67417 8.32582C8.0258 8.67746 8.50271 8.875 8.99999 8.875Z"
          fill="#99A0AE"
        />
      </svg>
    </span>
  );
};

EyeSvg.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export const EyeHideSvg = ({ className, onClick }) => {
  return (
    <span className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="14"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M2 2L22 22"
          stroke="#99A0AE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335"
          stroke="#99A0AE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818"
          stroke="#99A0AE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

EyeHideSvg.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export const InformationFill = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 14C4.6862 14 2 11.3138 2 8C2 4.6862 4.6862 2 8 2C11.3138 2 14 4.6862 14 8C14 11.3138 11.3138 14 8 14ZM7.4 7.4V11H8.6V7.4H7.4ZM7.4 5V6.2H8.6V5H7.4Z"
        fill="#FB3748"
      />
    </svg>
  );
};

export const FilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
    >
      <path
        d="M13 0V9H15.25L12.25 12.75L9.25 9H11.5V0H13ZM7 10.5V12H0.25V10.5H7ZM8.5 5.25V6.75H0.25V5.25H8.5ZM8.5 0V1.5H0.25V0H8.5Z"
        fill="#99A0AE"
      />
    </svg>
  );
};

export const SortIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.5 14.5H11.5V13H8.5V14.5ZM3.25 5.5V7H16.75V5.5H3.25ZM5.5 10.75H14.5V9.25H5.5V10.75Z"
        fill="#525866"
      />
    </svg>
  );
};
export const ErrorWarningEmpty = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5ZM8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8C2 9.5913 2.63214 11.1174 3.75736 12.2426C4.88258 13.3679 6.4087 14 8 14ZM7.25 4.25H8.75V5.75H7.25V4.25ZM7.25 7.25H8.75V11.75H7.25V7.25Z"
        fill="#FB3748"
      />
    </svg>
  );
};
export const CrossMark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <path
        d="M4.99999 3.93906L8.71249 0.226562L9.77299 1.28706L6.06049 4.99956L9.77299 8.71206L8.71249 9.77256L4.99999 6.06006L1.28749 9.77256L0.22699 8.71206L3.93949 4.99956L0.22699 1.28706L1.28749 0.226562L4.99999 3.93906Z"
        fill="#525866"
      ></path>
    </svg>
  );
};
export const BookIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 13.75V15.25H7.25V13.75H1.25C1.05109 13.75 0.860322 13.671 0.71967 13.5303C0.579018 13.3897 0.5 13.1989 0.5 13V1C0.5 0.80109 0.579018 0.610324 0.71967 0.469672C0.860322 0.32902 1.05109 0.250002 1.25 0.250002H5.75C6.17569 0.249497 6.59659 0.339819 6.98459 0.514937C7.37259 0.690056 7.71877 0.94594 8 1.2655C8.28123 0.94594 8.62741 0.690056 9.01541 0.514937C9.40341 0.339819 9.82431 0.249497 10.25 0.250002H14.75C14.9489 0.250002 15.1397 0.32902 15.2803 0.469672C15.421 0.610324 15.5 0.80109 15.5 1V13C15.5 13.1989 15.421 13.3897 15.2803 13.5303C15.1397 13.671 14.9489 13.75 14.75 13.75H8.75ZM14 12.25V1.75H10.25C9.85218 1.75 9.47064 1.90804 9.18934 2.18934C8.90804 2.47065 8.75 2.85218 8.75 3.25V12.25H14ZM7.25 12.25V3.25C7.25 2.85218 7.09196 2.47065 6.81066 2.18934C6.52936 1.90804 6.14782 1.75 5.75 1.75H2V12.25H7.25Z"
        fill="#99A0AE"
      />
    </svg>
  );
};

export const ProfileIcon = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.99991 11.75C9.74641 11.75 12.1487 12.9312 13.4552 14.6937L12.0737 15.347C11.0102 14.087 9.13516 13.25 6.99991 13.25C4.86466 13.25 2.98966 14.087 1.92616 15.347L0.54541 14.693C1.85191 12.9305 4.25341 11.75 6.99991 11.75ZM6.99991 0.5C7.99447 0.5 8.9483 0.895088 9.65156 1.59835C10.3548 2.30161 10.7499 3.25544 10.7499 4.25V6.5C10.7499 7.46635 10.3768 8.39537 9.70842 9.09333C9.04008 9.7913 8.1281 10.2043 7.16266 10.2463L6.99991 10.25C6.00535 10.25 5.05152 9.85491 4.34826 9.15165C3.645 8.44839 3.24991 7.49456 3.24991 6.5V4.25C3.24997 3.28365 3.62307 2.35463 4.2914 1.65667C4.95974 0.958705 5.87172 0.54569 6.83716 0.50375L6.99991 0.5ZM6.99991 2C6.426 1.99997 5.87377 2.21924 5.45621 2.61296C5.03865 3.00668 4.78733 3.54508 4.75366 4.118L4.74991 4.25V6.5C4.74935 7.08568 4.97717 7.6485 5.38499 8.06886C5.7928 8.48923 6.34846 8.73401 6.93388 8.7512C7.51931 8.76839 8.08837 8.55662 8.52014 8.1609C8.95191 7.76519 9.21236 7.2167 9.24616 6.632L9.24991 6.5V4.25C9.24991 3.65326 9.01286 3.08097 8.5909 2.65901C8.16894 2.23705 7.59665 2 6.99991 2Z" fill="#99A0AE"/>
</svg>

  );
};
export const BlueBookIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.32 2.31249C5.19094 1.77343 3.61281 1.50937 1.5 1.49999C1.30074 1.49729 1.10539 1.55539 0.940002 1.66655C0.804248 1.75831 0.693122 1.88203 0.616398 2.02681C0.539674 2.17159 0.499704 2.33301 0.500002 2.49687V11.4375C0.500002 12.0419 0.930002 12.4978 1.5 12.4978C3.72094 12.4978 5.94875 12.7053 7.28313 13.9666C7.30138 13.9839 7.32432 13.9955 7.34911 13.9999C7.37389 14.0043 7.39942 14.0013 7.42253 13.9913C7.44563 13.9813 7.46529 13.9648 7.47907 13.9437C7.49284 13.9226 7.50012 13.898 7.5 13.8728V3.33812C7.50004 3.26704 7.48482 3.19679 7.45539 3.1321C7.42595 3.06741 7.38298 3.00978 7.32938 2.96312C7.02372 2.70182 6.6842 2.48296 6.32 2.31249ZM15.06 1.66562C14.8945 1.55473 14.6992 1.49695 14.5 1.49999C12.3872 1.50937 10.8091 1.77218 9.68 2.31249C9.31582 2.48265 8.97621 2.70107 8.67031 2.96187C8.61681 3.0086 8.57393 3.06625 8.54456 3.13093C8.51518 3.19561 8.49999 3.26583 8.5 3.33687V13.8722C8.49999 13.8964 8.50711 13.92 8.52049 13.9402C8.53387 13.9603 8.5529 13.9761 8.57519 13.9855C8.59749 13.9948 8.62206 13.9974 8.64582 13.9929C8.66958 13.9884 8.69147 13.9769 8.70875 13.96C9.51094 13.1631 10.9188 12.4969 14.5013 12.4972C14.7665 12.4972 15.0208 12.3918 15.2084 12.2043C15.3959 12.0167 15.5013 11.7624 15.5013 11.4972V2.49718C15.5016 2.333 15.4616 2.17126 15.3846 2.02623C15.3076 1.8812 15.1962 1.75735 15.06 1.66562Z" fill="#C0D5FF"/>
</svg>
  );
};
export const StudyHourIcon = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.6667 0.333374H1.33333C0.979711 0.333374 0.640573 0.47385 0.390524 0.723898C0.140476 0.973947 0 1.31309 0 1.66671V10.3334C0 10.687 0.140476 11.0261 0.390524 11.2762C0.640573 11.5262 0.979711 11.6667 1.33333 11.6667H4L6 13.6667L8 11.6667H10.6667C11.0203 11.6667 11.3594 11.5262 11.6095 11.2762C11.8595 11.0261 12 10.687 12 10.3334V1.66671C12 1.31309 11.8595 0.973947 11.6095 0.723898C11.3594 0.47385 11.0203 0.333374 10.6667 0.333374ZM6 2.33337C7.15133 2.33337 8 3.18137 8 4.33337C8 5.48537 7.15133 6.33337 6 6.33337C4.84933 6.33337 4 5.48537 4 4.33337C4 3.18137 4.84933 2.33337 6 2.33337ZM2.78467 9.66671C3.15667 8.51804 4.44867 7.66671 6 7.66671C7.55133 7.66671 8.844 8.51804 9.21533 9.66671H2.78467Z" fill="#C0D5FF"/>
</svg>

  );
};
export const CalendarIcon = () => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.75 1C0.75 0.654825 1.02983 0.375 1.375 0.375H12.625C12.9702 0.375 13.25 0.654825 13.25 1V11C13.25 11.3452 12.9702 11.625 12.625 11.625H1.375C1.02983 11.625 0.75 11.3452 0.75 11V1ZM2 10.375H12V4.125H2V10.375ZM6.375 6.625H3.25V9.125H6.375V6.625Z" fill="#0E121B"/>
</svg>


  );
};