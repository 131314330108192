import React from "react";
import PropTypes from "prop-types";
import "./Sheet.css";
import {
  useOutsideClick,
  useToggle,
} from "../../../FeeManagementModule/FeeStructureModule/hooks";

/**
 * Button component used to trigger the Sheet.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.onClick - Function to call when button is clicked.
 * @param {React.ReactNode} props.children - Content to display inside the button.
 * @param {string} [props.className] - Optional additional CSS classes.
 * @returns {JSX.Element} The SheetTrigger component.
 */
const SheetTrigger = ({ onClick, children, className }) => (
  <div className={`custom__sheet-trigger ${className || ""}`} onClick={onClick}>
    {children}
  </div>
);

SheetTrigger.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

/**
 * Header component for the Sheet.
 *
 * @param {Object} props - Component properties.
 * @param {React.ReactNode} props.children - Content to display in the header.
 * @param {Function} props.onClose - Function to call to close the Sheet.
 * @returns {JSX.Element} The SheetHeader component.
 */
const SheetHeader = ({ children, onClose }) => (
  <header className="sheet__header">
    <h2 className="sheet__title">{children}</h2>
    <button className="sheet__close-btn" onClick={onClose}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
      >
        <path
          d="M4.99999 3.93906L8.71249 0.226562L9.77299 1.28706L6.06049 4.99956L9.77299 8.71206L8.71249 9.77256L4.99999 6.06006L1.28749 9.77256L0.22699 8.71206L3.93949 4.99956L0.22699 1.28706L1.28749 0.226562L4.99999 3.93906Z"
          fill="#525866"
        />
      </svg>
    </button>
  </header>
);

SheetHeader.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

/**
 * Body component for the Sheet.
 *
 * @param {Object} props - Component properties.
 * @param {React.ReactNode} props.children - Content to display in the body.
 * @returns {JSX.Element} The SheetBody component.
 */
const SheetBody = ({ children }) => (
  <div className="custom__sheet-body">{children}</div>
);

SheetBody.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Footer component for the Sheet.
 *
 * @param {Object} props - Component properties.
 * @param {React.ReactNode} props.children - Content to display in the footer.
 * @param {Object} [props.style] - Optional inline styles for the footer.
 * @returns {JSX.Element} The SheetFooter component.
 */
const SheetFooter = ({ children, style }) => (
  <div className="custom__sheet-footer" style={style}>
    {children}
  </div>
);

SheetFooter.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

/**
 * Content component for the Sheet.
 *
 * @param {Object} props - Component properties.
 * @param {React.ReactNode} props.children - Content to display in the content area.
 * @returns {JSX.Element} The SheetContent component.
 */
const SheetContent = ({ children }) => (
  <div className="custom__sheet-content">{children}</div>
);

SheetContent.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Sheet component that slides in from the right side of the screen.
 *
 * @param {Object} props - Component properties.
 * @param {React.ReactNode} props.children - Child components to render inside the Sheet.
 * @param {React.ReactNode} props.trigger - Content to display for the trigger button.
 * @param {string} [props.width] - Optional width of the Sheet.
 * @returns {JSX.Element} The Sheet component.
 */
const Sheet = ({ children, trigger, width,getsheethandlers=()=>{} }) => {
  const { state: isOpen, handlers } = useToggle();
  const sheetRef = React.useRef(null);

  useOutsideClick(sheetRef, handlers.off);

  React.useEffect(() => {
    getsheethandlers(handlers);
    if (isOpen) {
      document.body.classList.add("no-scroll", "sheet-open");
    } else {
      document.body.classList.remove("no-scroll", "sheet-open");
    }

    return () => {
      document.body.classList.remove("no-scroll", "sheet-open");
    };
    //eslint-disable-next-line
  }, [isOpen]);

  return (
    <>
      <SheetTrigger onClick={handlers.toggle}>{trigger}</SheetTrigger>
      {isOpen && (
        <div
          className={`sheet-overlay ${isOpen ? "sheet-overlay--open" : ""}`}
          ref={sheetRef}
        >
          <div
            className={`sheet ${isOpen ? "sheet--open" : ""}`}
            style={{ width: width }}
          >
            {React.Children.map(children, (child) => {
              if (child.type === SheetHeader) {
                return React.cloneElement(child, { onClose: handlers.off });
              }
              if (child.type === SheetBody) {
                return React.cloneElement(child, { className: "scrollable" });
              }
              if (child.type === SheetFooter) {
                return React.cloneElement(child, { className: "fixed" });
              }
              return child;
            })}
          </div>
        </div>
      )}
    </>
  );
};

Sheet.propTypes = {
  children: PropTypes.node.isRequired,
  trigger: PropTypes.node.isRequired,
  width: PropTypes.string,
};

export {
  Sheet,
  SheetHeader,
  SheetBody,
  SheetFooter,
  SheetContent,
  SheetTrigger,
};
