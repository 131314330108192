import "./CreateHeader.css";
import { NavLink } from "react-router-dom";
import React from "react";
// import CustomDropdown from "../../FeeManagementModule/components/Custom-Dropdown";
// import { FilterIcon } from "../../../assets/svgs";
// import { useSelectState } from "../../FeeManagementModule/FeeStructureModule/hooks";

import SearchableDropdown from "../../../home/components/SearchableDropdown";

export const CreateHeader = ({
  text,
  subText,
  searchdropdownlabel,
  showButton,
  buttontext,
  searchdropdownvalue="",
  setsearchdropdownvalue=()=>{},
  searchdropdown=false,
  searchdropdowndata,
  navto,
  buttonstate = () => {},
  incompleteprofileinfo = [false, 0],
  handledatachange=()=>{}
}) => {
  // console.log(incompleteprofileinfo);
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const term = queryParams.get("term");

  // const { value: selectedTerm } = useSelectState(
  //   term === "annual" ? "Annual-wise" : "Term-wise"
  // );

  return (
    <div className="feeHeader">
      <div className="feeHeader__info">
        <p className="feeHeader__title">{text}</p>
        <p className="feeHeader__subtitle">{subText}</p>
      </div>
      <div style={{ display: "flex", gap: "0.7rem" }}>
        {incompleteprofileinfo[0] && (
          <div className="incompleteprofileinfo" style={{cursor:'pointer'}} onClick={()=>{handledatachange("filter",["InCompleted Profiles"])}}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5ZM8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8C2 9.5913 2.63214 11.1174 3.75736 12.2426C4.88258 13.3679 6.4087 14 8 14ZM7.25 4.25H8.75V5.75H7.25V4.25ZM7.25 7.25H8.75V11.75H7.25V7.25Z"
                fill="#FB3748"
              />
            </svg>
            {incompleteprofileinfo[1]} Incomplete profiles
          </div>
        )}
        {showButton ? (
          <button
            className="feeHeader__button"
            onClick={() => {
              buttonstate(true);
            }}
          >
            <NavLink to={navto} className={"feeHeader__link"}>
              + {buttontext}
            </NavLink>
          </button>
        ) : (
          // <CustomDropdown
          //   defaultText={selectedTerm}
          //   icon={<FilterIcon />}
          //   items={["Annual-wise", "Term-wise"]}
          //   width={"fit-content"}
          //   dropdownName={"term"}
          // />
          <></>
        )}
        {
          searchdropdown && <SearchableDropdown
          options={searchdropdowndata}
          label={searchdropdownlabel}
          id="id"
          selectedVal={searchdropdownvalue!==null?searchdropdownvalue.name_of_the_staff:""}
          handleChange={(val) => setsearchdropdownvalue(val)}/>
        }
      </div>
    </div>
  );
};
