import React, { useEffect, useState } from "react";

import FeeHeader from "../../FeeManagementModule/components/FeeHeader";
import "../../FeeManagementModule/FeeStructureModule/FeeStructure-Empty.css";
import { IndividualTimeTable } from "./IndividualTimetable";
import { getTimetabledata ,getTimetableId} from "../../../api/api";
import { DecodeandEncodeDays } from "../../../home/components/DecodeandEncodeDays";
import { formatRomanNumber } from "../../FeeManagementModule/FeeStructureModule/hooks";

export const ClasswiseComponent = ({onCreateClick,hastt,sethastt,id,class_name="Dummy"})=>{
    const [data,setdata]=useState([]);
   
    function generatePeriods(data) {
        console.log(data);
        const response = data;
        const startTime = new Date(response[0].timetable_start_time);
        const periods = [];
      
        let currentTime = new Date(startTime);
      
        // Add prayer period if enabled
        if (response[0].is_prayer_enabled === 1) {
            const prayerDuration = response[0].prayer_duration;
            periods.push({
                period: "PRAYER",
                start: currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }),
                end: new Date(currentTime.getTime() + prayerDuration * 60000).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }),
                subject: null,
                staff: null,
            // seq_num:-1
            // days: DecodeandEncodeDays(response[0].week_days) // Include decoded days
            });
            currentTime = new Date(currentTime.getTime() + prayerDuration * 60000);
        }
            let periodnum=1;
        for (let key in response) {
          const periodData = response[key];
          const periodDuration = periodData.period_duration; // Duration is already in minutes
      
                let period = {
                    period: periodData.is_break ? "BREAK" : `PERIOD ${formatRomanNumber(periodnum)}`,
                    start: currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }),
                    end: new Date(currentTime.getTime() + periodDuration * 60000).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }),
                    subject: periodData.subject || null,
                    staff: periodData.staff_name || null,
            days: DecodeandEncodeDays("decode",periodData.week_days===''?63:periodData.week_days), // Include decoded days
            number_of_periods:periodData.number_of_periods,
                    seq_num: periodData.period_seq_num // Add sequence number
                };
                if(!periodData.is_break)periodnum+=1;
           
          if(periods.length>=1 && periods[periods.length-1].seq_num===period.seq_num){
                period.start=periods[periods.length-1].start;
                period.end=periods[periods.length-1].end;
          }
          else
                currentTime = new Date(currentTime.getTime() + periodDuration * 60000);
          periods.push(period);
      
          // Update current time to the end of the current period
        }
        console.log(periods);
        return periods;
    }
    useEffect(() => {
        if(data.length)
        sethastt(Object.keys(data).length > 0);
    //eslint-disable-next-line
    }, [data])
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                
                const rs=await getTimetableId(`class_section_mapping_id=${id}`);
                    
                if(rs.time_table_id)
                {const result = await getTimetabledata(rs.time_table_id,`class_section_mapping_id=${id}`);
                if (Object.keys(result).length > 0) {
                    // period_seq_num
                    const resultArray = Object.values(result); // Convert object to array
                    const sortedResult = resultArray.sort((a, b) => a.period_seq_num - b.period_seq_num);
                    console.log(sortedResult);
                    setdata(generatePeriods(sortedResult));
                    // console.log(generatePeriods(result));
                } else {
                    setdata([]);
                }}
                else setdata([]);
                // console.log(result);
            } catch (error) {
                console.error("Error fetching timetable data:", error);
            }
        };

        if (id !== null) {
            fetchData();
        }
    }, [id]);
    
    return <>
        <div>
            <FeeHeader
                text="Class wise schedule"
                subText="Add / Manage time schedules for your classes"
                button1={""}
                // button2={
                //     <Button onClick = {onCreateClick}>
                //         <FiPlus/> Create a Timetable
                //     </Button>
                // }
            />
           {data.length===0  && <section className="feeStructure__container">
                
                <section className="feeStructure">
                    <div className="feeStructure__div">
                        <svg
                        viewBox="0 0 94 67"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="feeStructure__svg"
                        >
                        <path
                            d="M62.5241 33.5004C60.0419 33.5004 57.7066 32.1785 56.4434 30.0635L46.9994 14.4067L37.57 30.0635C36.2922 32.1932 33.9569 33.5151 31.4747 33.5151C30.8138 33.5151 30.1528 33.427 29.5213 33.236L9.39938 27.4785V53.6223C9.39938 55.7813 10.8681 57.6613 12.9538 58.1754L44.7081 66.1213C46.2063 66.4885 47.7778 66.4885 49.2613 66.1213L81.045 58.1754C83.1306 57.6467 84.5994 55.7667 84.5994 53.6223V27.4785L64.4775 33.2213C63.8459 33.4123 63.185 33.5004 62.5241 33.5004ZM93.7497 17.021L86.1856 1.92227C85.7303 1.01165 84.7463 0.4829 83.7328 0.615087L46.9994 5.3004L60.4678 27.6401C61.0259 28.5654 62.1422 29.006 63.185 28.7123L92.2516 20.4138C93.7056 19.9879 94.4106 18.3723 93.7497 17.021ZM7.81313 1.92227L0.249068 17.021C-0.426557 18.3723 0.293131 19.9879 1.73251 20.3992L30.7991 28.6976C31.8419 28.9913 32.9581 28.5507 33.5163 27.6254L46.9994 5.3004L10.2513 0.615087C9.23782 0.497587 8.26844 1.01165 7.81313 1.92227Z"
                            fill="#F5F7FA"
                        />
                        </svg>
                        <div className="feeStructure__textContainer">
                        <p className="feeStructure__text feeStructure__text--empty">
                            It’s empty here!
                        </p>
                        <p className="feeStructure__text feeStructure__text--subtext">
                            Get Started by adding a Timetable schedule to your school
                        </p>
                        </div>
                    </div>
                </section>
            </section>}
            <div style={{width:'98%',marginLeft:'1%'}
            }>

            {data.length!==0 && <IndividualTimeTable class_name={class_name} ttdata={data}/>}
            </div>
        </div>
    </>;
}