import React, { useEffect, useState } from "react";
import "./EditDetailsSlider.css";
import { editStudentParentalInfo,createStudentParentalInfo } from "../../../api/api";
import { toast } from "sonner";

export const ParentalInfoEditSlider = ({studata,parent="Father", sliderstate, data }) => {
  const [editdata, editsetdata] = useState([]);
  useEffect(() => {
    try {
      // setloading(true);
      editsetdata(data);
      // setenrollstatus(data.enrolment_status)
    } catch (error) {
      // setloading(false);
    }
  }, [data]);
  // useEffect(() => {
    // setenrollstatus(editdata.enrolment_status);
  //   console.log(enrollstatus);
  // }, [editdata.enrolment_status]);

  const handledatachange = async (key, value) => {
    let temp = { ...editdata };
    console.log(key, value);
    // let temp = { ...editdata };
    temp[key] = value;

    console.log(temp);
    await editsetdata(temp);
  };
  const handlesavechanges = async () => {
    console.log(editdata);
    let tempdata = {
      ...editdata,
      student_user_id:studata.id,
      user_id:studata.id
    };
    if(!tempdata.first_name || tempdata.first_name==="" || (!tempdata.contact_number || tempdata.contact_number===""))
    toast.error("Please fill out all mandatory fields", {
      position: "top-right",
      autoClose: 5000,
    });
    else
    {console.log(tempdata);
    if(Object.keys(data).length<=1){
      await createStudentParentalInfo(studata.id,tempdata.relationship,tempdata);
    }
    else
    await editStudentParentalInfo(studata.id,tempdata.relationship,tempdata);
    // navigate("/student/transactions//general");
    sliderstate(false);}
  };
  //  loading && "aaa"
  return (
    <>
      <div className="EditSlider">
        <div className="EditSliderdiv">
          <div className="EditSliderdivheader">
            Edit {data.relationship} Info
            <svg
              onClick={() => {
                sliderstate(false);
              }}
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M4.99999 3.93906L8.71249 0.226562L9.77299 1.28706L6.06049 4.99956L9.77299 8.71206L8.71249 9.77256L4.99999 6.06006L1.28749 9.77256L0.22699 8.71206L3.93949 4.99956L0.22699 1.28706L1.28749 0.226562L4.99999 3.93906Z"
                fill="#525866"
              ></path>
            </svg>
          </div>
          <div className="StudentInfoEditSilderdivbody">
            <div style={{ display: "flex", gap: "1rem" }}>
              <div className="Details">
                <div style={{display:'flex'}}>

                First Name
                <div
                  className="mandatory"
                  style={{ color: "rgba(251, 55, 72, 1)" }}
                >
                  *
                </div>
                </div>
                <input
                  type="text"
                  value={editdata.first_name}
                  className="inputbox"
                  placeholder="Chaitanya"
                  onChange={(event) => {
                    handledatachange("first_name", event.target.value);
                  }}
                />
              </div>
              <div className="Details">
                Last Name
                <input
                  type="text"
                  value={editdata.last_name}
                  className="inputbox"
                  placeholder="Rao"
                  onChange={(event) => {
                    handledatachange("last_name", event.target.value);
                  }}
                />
              </div>
            </div>
          
            <div className="Details">
              <label style={{ display: "flex" }}>
                Phone Number
                <div
                  className="mandatory"
                  style={{ color: "rgba(251, 55, 72, 1)" }}
                >
                  *
                </div>
              </label>
              <input
                type="text"
                className="inputbox"
                value={editdata.contact_number}
                style={{ width: "32.5rem" }}
                placeholder="123456789"
                onChange={(event) => {
                  handledatachange("contact_number", event.target.value);
                }}
              />
            </div>
            <div className="Details">
              <label style={{ display: "flex" }}>
                Email ID
              </label>
              <input
                type="text"
                className="inputbox"
                value={editdata.pen_number}
                style={{ width: "32.5rem" }}
                placeholder="grahith@gmail.com"
                onChange={(event) => {
                  handledatachange("email", event.target.value);
                }}
              />
            </div>
            <div className="Details">
              <label style={{ display: "flex" }}>
                Occupation
              </label>
              <input
                type="text"
                className="inputbox"
                value={editdata.occupation}
                style={{ width: "32.5rem" }}
                placeholder="Teacher"
                onChange={(event) => {
                  handledatachange("occupation", event.target.value);
                }}
              />
            </div>
            <div className="Details">
              <label style={{ display: "flex" }}>
                Annual Income
              </label>
              <input
                type="text"
                className="inputbox"
                value={editdata.annual_income}
                style={{ width: "32.5rem" }}
                placeholder="1000"
                onChange={(event) => {
                  handledatachange("annual_income", event.target.value);
                }}
              />
            </div>
          <div style={{display:'flex',justifyContent:'space-between', width: "32.5rem" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "0.7rem" }}>
          <input
            type="checkbox"
            checked={editdata.is_emergency_contact}
            onClick={() => {
              editsetdata(prev => ({
                ...prev,
                is_emergency_contact: !prev.is_emergency_contact
            }));
                    }}
            name=""
            id=""
          />
          Mark as Emergency Contact
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "0.7rem" }}>
          <input
            type="checkbox"
            checked={editdata.is_guardian}
            onClick={() => {
      editsetdata(prev => ({
        ...prev,
        is_guardian: !prev.is_guardian
    }));
            }}
            name=""
            id=""
          />
          Mark as Guardian
        </div>
          </div>
          </div>
          <div className="footerbutton">
            <div
              className="cancel"
              //   onClick={() => {
              //     buttonstate(false);
              //   }}
            >
              Cancel
            </div>
            <div
              className="Proceed"
              //   onClick={() => {
              //     navigate("./createquestions");
              //   }}
              onClick={() => {
                console.log("hello continuing");
                handlesavechanges();
              }}
            >
              Save Changes
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
