import React from "react";
import Search from "../../FeeManagementModule/components/Search";
import {
  BorderButton,
  Button,
} from "../../FeeManagementModule/components/Button";
import {
  bulkTeacherSetup,
  getAffiliatedBoardSubjects,
  getAllSchoolTeachers,
  getSubjectsWithTeachers,
} from "../../../api/api";
import {
  ModalTrigger,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "../components/Modal";
import { RiUser6Line } from "react-icons/ri";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
} from "../../../auth/SetupAdminPage/components/Dropdown";
import {
  useSearch,
  useToggle,
} from "../../FeeManagementModule/FeeStructureModule/hooks";
import { useLocation } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import { toast } from "sonner";
import {SubjectActions} from "./components/SubjectActions";
import { GrayBox } from "../../../auth/SetupAdminPage/components/SetupComponents";
import Skeleton from "react-loading-skeleton";

const SchoolSubject = () => {
  const [subjects, setSubjects] = React.useState([]);
  const [subjectWithTeachers, setSubjectWithTeachers] = React.useState(["DUMMY"]);
  const [teachers, setTeachers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openModalId, setOpenModalId] = React.useState(null);
  const [selectedTeacher, setSelectedTeacher] = React.useState(null);
  const location = useLocation();
  const class_section_id = location.pathname.split("/")[3];
  const handleTeacherSelect = (teacher) => {
    setSelectedTeacher(teacher);
  };
  const { state: subjectModal, handlers: subjectHandlers } = useToggle();
  const { state: defaultState, handlers: defaultHandlers } = useToggle(true);
  const {
    searchTerm,
    filteredItems: teacherFilteredItems,
    handleSearchChange,
  } = useSearch(teachers, "full_name");
  const {
    searchTerm: subjectSearchTerm,
    filteredItems: subjectFilteredItems,
    handleSearchChange: subjectSearchChange,
  } = useSearch(subjects, "subject_name");
  const [subjectData, setSubjectData] = React.useState("");
  const [teacherData, setTeacherData] = React.useState("");
  const [errors, setErrors] = React.useState({
    subject: "",
    teacher: "",
  });
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [mappings] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const board_id = localStorage.getItem("board_id");
      if (board_id !== null && board_id !== undefined) {
        try {
          const teacherWithSubjectResponse = await getSubjectsWithTeachers(
            class_section_id
          );

          if (teacherWithSubjectResponse.response.results.rows.length > 0) {
            setSubjectWithTeachers(
              teacherWithSubjectResponse.response.results.rows
            );
          } else {
            setSubjectWithTeachers([]);
            const response = await getAffiliatedBoardSubjects(board_id);
            setSubjects(response.response.results);
          }

          const response = await getAffiliatedBoardSubjects(board_id);
          setSubjects(response.response.results);

          const teacherResponse = await getAllSchoolTeachers();
          setTeachers(teacherResponse.response.users);
        } catch (error) {
          console.error("Error fetching academic details", error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [class_section_id]);

  const handleOpenModal = (subjectId) => {
    setOpenModalId(subjectId);
  };

  const handleCloseModal = () => {
    setOpenModalId(null);
  };

  const refreshData = async () => {
    setIsLoading(true);
    try {
      const board_id = localStorage.getItem("board_id");
      if (board_id !== null && board_id !== undefined) {
        const teacherWithSubjectResponse = await getSubjectsWithTeachers(
          class_section_id
        );

        if (teacherWithSubjectResponse.response.results.rows.length > 0) {
          setSubjectWithTeachers(
            teacherWithSubjectResponse.response.results.rows
          );
        } else {
          setSubjectWithTeachers([]);
          const response = await getAffiliatedBoardSubjects(board_id);
          setSubjects(response.response.results);
        }

        const response = await getAffiliatedBoardSubjects(board_id);
        setSubjects(response.response.results);

        const teacherResponse = await getAllSchoolTeachers();
        setTeachers(teacherResponse.response.users);
      }
    } catch (error) {
      console.error("Error refreshing data", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTeacherAndSubjectSubmit = async () => {
    const errors = {
      subject: !subjectData.subject_id ? "Please select a subject." : "",
      teacher: !teacherData.id ? "Please select a teacher." : "",
    };

    setErrors(errors);

    if (errors.subject || errors.teacher) {
      return;
    }

    setButtonLoading(true);

    const newMapping = {
      class_section_id: class_section_id,
      subject_id: subjectData.subject_id,
      subject_teacher_id: teacherData.id,
    };

    try {
      const response = await bulkTeacherSetup({
        mappings: [...mappings, newMapping],
      });

      if (response.response.message === "RESOURCE_CREATED_SUCCESSFULLY") {
        await refreshData();
        setTeacherData(null);
        setSubjectData(null);
        handleCloseModal();
      } else {
        toast.error(
          "Error submitting teacher and subject, please try again later."
        );
      }
    } catch (error) {
      console.error("Error submitting teacher and subject", error);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleTeacherSubmit = async (subject_id) => {
    const errors = {
      teacher: !selectedTeacher.id ? "Please select a teacher." : "",
    };

    setErrors(errors);

    if (errors.teacher) {
      return;
    }

    setButtonLoading(true);

    const newMapping = {
      class_section_id: class_section_id,
      subject_id: subject_id,
      subject_teacher_id: selectedTeacher.id,
    };

    try {
      const response = await bulkTeacherSetup({
        mappings: [...mappings, newMapping],
      });

      console.log("response", response);

      if (response.response.message === "RESOURCE_CREATED_SUCCESSFULLY") {
        await refreshData();
        handleTeacherSelect(null);
        handleCloseModal();
      } else {
        toast.error("Error submitting teacher, please try again later.");
      }
    } catch (error) {
      console.error("Error submitting teacher", error);
    } finally {
      setButtonLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="table-container" style={{ marginTop: "1rem" }}>
        <div className="table-container-header">
          <div className="header-cell-container">Name of the subject</div>
          <div
            className="header-cell-container"
            style={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            Subject Teacher
          </div>
          <div
            className="header-cell-container"
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            Actions
          </div>
        </div>
        <div className="table-container-body">
          {Array(5)
            .fill()
            .map((_, index) => (
              <div className="row-container" key={index}>
                <div className="cell-container">
                  <Skeleton width={150} height={20} />
                </div>
                <div
                  className="cell-container"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <Skeleton width={150} height={20} />
                </div>
                <div
                  className="cell-container"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Skeleton width={150} height={20} />
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
  return (
    <>
      {subjectWithTeachers.length === 0 && (
        <>
          {defaultState && (
            <div className="default__modal">
              <div className="default__modal__content">
                <div className="default__modal__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18ZM8.1 11.7V13.5H9.9V11.7H8.1ZM8.1 4.5V9.9H9.9V4.5H8.1Z"
                      fill="var(--blue-color)"
                    />
                  </svg>
                </div>
                <p className="default__modal__text">
                  We’ve pre-filled subjects for you!{" "}
                </p>
                <p className="default__modal__subtext5">
                  Based on your "Academic Board" selection, we've pre-filled the
                  following subjects. You can now assign subject teachers or
                  add/delete subjects as needed.
                </p>
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    flexWrap: "wrap",
                    marginTop: "1rem",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {subjects.map((subject) => {
                    return <GrayBox text={subject.subject_name} />;
                  })}
                </div>
                <div className="default__modal__divider"></div>
                <div className="default__modal__buttons">
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: "0.5rem",
                    }}
                    onClick={() => {
                      defaultHandlers.off();
                    }}
                  >
                    Got it, Let’s Proceed
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1rem 0",
        }}
      >
        <Search placeholder="Search for Student Name" />
        <ModalTrigger onClick={subjectHandlers.toggle}>
          <BorderButton>+ Add Subject</BorderButton>
        </ModalTrigger>
        <Modal isOpen={subjectModal} onClose={subjectHandlers.off}>
          <ModalHeader onClose={subjectHandlers.off}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "2rem",
                  height: "2rem",
                  borderRadius: "50%",
                  border: "1.4px solid var(--gray-color-200)",
                }}
              >
                <RiUser6Line size={"1rem"} color={"var(--gray-color-600)"} />
              </span>
              Add Subject
            </div>
          </ModalHeader>
          <ModalBody>
            <p
              style={{
                fontSize: "0.875rem",
                fontWeight: "500",
              }}
            >
              Select a subject to add
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </p>
            <Dropdown
              trigger={
                <span>
                  {subjectData ? (
                    <span
                      style={{
                        color: "var(--black-color)",
                        textTransform: "capitalize",
                        fontWeight: "500",
                      }}
                    >
                      {subjectData.subject_name}
                    </span>
                  ) : (
                    "Select an subject.."
                  )}
                </span>
              }
            >
              <DropdownMenu>
                <DropdownItem>
                  <Search
                    placeholder="search for a subject name"
                    searchTerm={searchTerm}
                    onSearchChange={handleSearchChange}
                    style={{
                      width: "100%",
                    }}
                  />
                </DropdownItem>
                {subjectFilteredItems.map((subject, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => {setSubjectData(subject)}}
                  >
                    {subject.subject_name!== null ? subject.subject_name : 'null'}
                    {subjectData && subjectData.subject_name === subject.subject_name && ( 
                      <span>
                        <FaCheck color="var(--blue-color)" />
                      </span>
                    )}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            {errors.subject && (
              <p className="error__select">{errors.subject}</p>
            )}
            <p
              style={{
                fontSize: "0.875rem",
                fontWeight: "500",
                marginBottom: "0",
              }}
            >
              Select a Teacher
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </p>
            <Dropdown
              trigger={
                <span>
                  {teacherData ? (
                    <span
                      style={{
                        color: "var(--black-color)",
                        textTransform: "capitalize",
                        fontWeight: "500",
                      }}
                    >
                      {teacherData.full_name}
                    </span>
                  ) : (
                    "Select an Teacher.."
                  )}
                </span>
              }
            >
              <DropdownMenu>
                <DropdownItem
                  style={{
                    borderBottom: "1.4px solid var(--gray-color-200)",
                  }}
                >
                  <Search
                    placeholder="search for a teacher name"
                    searchTerm={subjectSearchTerm}
                    onSearchChange={subjectSearchChange}
                    style={{
                      width: "100%",
                    }}
                  />
                </DropdownItem>
                {teacherFilteredItems.map((teacher, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => setTeacherData(teacher)}
                  >
                    {teacher.full_name}
                    {
                      <span>
                        {teacherData && teacherData.full_name === teacher.full_name && (
                          <FaCheck color="var(--blue-color)" />
                        )}
                      </span>
                    }
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            {errors.teacher && (
              <p className="error__select">{errors.teacher}</p>
            )}
          </ModalBody>
          <ModalFooter
            style={{
              display: "flex",
            }}
          >
            <BorderButton
              onClick={handleCloseModal}
              style={{
                width: "50%",
              }}
              disabled={buttonLoading}
              className={`${buttonLoading && "button__loading"}`}
            >
              Cancel
            </BorderButton>
            <Button
              style={{
                width: "50%",
              }}
              onClick={handleTeacherAndSubjectSubmit}
              className={`${buttonLoading && "button__loading"}`}
            >
              Continue
            </Button>
          </ModalFooter>
        </Modal>
      </header>
      <div className="table-container" style={{ marginTop: "1rem" }}>
        <div className="table-container-header">
          <div className="header-cell-container">Name of the subject</div>
          <div
            className="header-cell-container"
            style={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            Subject Teacher
          </div>
          <div
            className="header-cell-container"
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            Actions
          </div>
        </div>
        {subjectWithTeachers.length !== 0 ? (
          <div className="table-container-body">
            {subjectWithTeachers.map((subject) => {
              return (
                <div className="row-container" key={subject.subject_teacher_id}>
                  <div className="cell-container">{subject.subject} </div>
                  <div
                    className="cell-container"
                    style={{
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src={`https://api.dicebear.com/8.x/initials/svg?seed=${subject.full_name}
                      `}
                      alt="teacher"
                      style={{
                        width: "2rem",
                        height: "2rem",
                        borderRadius: "50%",
                      }}
                    />
                    <span
                      style={{
                        color: "var(--black-color)",
                        textTransform: "capitalize",
                        fontWeight: "500",
                      }}
                    >
                      {subject.full_name}
                    </span>
                  </div>
                  <div
                    className="cell-container"
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <SubjectActions id={subject.id} refreshData={refreshData}/>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="table-container-body">
            {subjects.map((subject) => {
              return (
                <div className="row-container" key={subject.subject_id}>
                  <div className="cell-container">{subject.subject_name} </div>
                  <div
                    className="cell-container"
                    style={{
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    <ModalTrigger
                      style={{
                        color: "var(--blue-color)",
                        fontSize: "0.875rem",
                        fontWeight: "500",
                      }}
                      onClick={() => handleOpenModal(subject.subject_id)}
                    >
                      Assign a subject Teacher
                    </ModalTrigger>
                    <Modal
                      isOpen={openModalId === subject.subject_id}
                      onClose={handleCloseModal}
                      handlersOff={{ off: handleCloseModal }}
                    >
                      <ModalHeader onClose={handleCloseModal}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "2rem",
                              height: "2rem",
                              borderRadius: "50%",
                              border: "1.4px solid var(--gray-color-200)",
                            }}
                          >
                            <RiUser6Line
                              size={"1rem"}
                              color={"var(--gray-color-600)"}
                            />
                          </span>
                          Assign Subject Teacher
                        </div>
                      </ModalHeader>
                      <ModalBody>
                        <span
                          style={{
                            fontSize: "0.75rem",
                            fontWeight: "400",
                            color: "var(--gray-color-600)",
                            textTransform: "uppercase",
                            marginBottom: "0",
                          }}
                        >
                          Subject
                        </span>
                        <p
                          style={{
                            fontSize: "1.125rem",
                            fontWeight: "500",
                            color: "var(--black-color)",
                            marginTop: "0.5rem",
                          }}
                        >
                          {subject.subject_name}
                        </p>

                        <p
                          style={{
                            fontSize: "0.875rem",
                            fontWeight: "500",
                          }}
                        >
                          Assign a subject Teacher
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </p>
                        <Dropdown
                          trigger={
                            <span>
                              {selectedTeacher ? (
                                <span
                                  style={{
                                    color: "var(--black-color)",
                                    textTransform: "capitalize",
                                    fontWeight: "500",
                                  }}
                                >
                                  {selectedTeacher.full_name}
                                </span>
                              ) : (
                                "Select a teacher.."
                              )}
                            </span>
                          }
                        >
                          <DropdownMenu>
                            {teachers.map((teacher, index) => (
                              <DropdownItem
                                key={index}
                                onClick={() => handleTeacherSelect(teacher)}
                              >
                                {teacher.full_name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </ModalBody>
                      <ModalFooter
                        style={{
                          display: "flex",
                        }}
                      >
                        <BorderButton
                          onClick={handleCloseModal}
                          style={{
                            width: "50%",
                          }}
                        >
                          Cancel
                        </BorderButton>
                        <Button
                          style={{
                            width: "50%",
                          }}
                          onClick={() => {
                            handleTeacherSubmit(subject.subject_id);
                          }}
                        >
                          Continue
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                  <div
                    className="cell-container"
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <SubjectActions />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default SchoolSubject;
