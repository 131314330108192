import React, { useEffect, useState } from 'react'
import { CreateHeader } from '../StudentDirectoryModule/Components/CreateHeader'
import { AddHolidayModal } from './components/AddHolidayModal';
import "./HolidaysMainPage.css"
import { SearchBar } from '../StudentDirectoryModule/Components/SearchBar';
import { CheckBoxDropDownWithSubItems } from '../../home/components/CheckBoxDropdown/CheckBoxDropDown';
import { CalendarIcon, FilterIcon, SortIcon } from '../../assets/svgs';
import { CustomDropdownWithCustomStyle } from '../StudentDirectoryModule/Components/CustomDropdownWithCustomStyle'
import { FiEdit3 } from 'react-icons/fi';
import { AiOutlineDelete } from "react-icons/ai";
import { DeleteHolidayPopup } from './components/DeleteHolidayModal';
import { getholidayinfo } from '../../api/api';
import { FaArrowRightLong } from "react-icons/fa6";
import Pagination from '../../home/Pagination';
import Skeleton from 'react-loading-skeleton';

export const HolidaysMainPage = () => {
  const [paginateddata, setpaginateddata] = useState([]);
  const [totalpages, settotalpages] = useState(0);
  const pageSize = 8;
    const [createpopup,setcreatepopup] =useState(false);
    //eslint-disable-next-line
    const [checkeditem, setcheckeditem] = useState([]);
    //eslint-disable-next-line
    const [empty,setempty] =useState(false);
    const [deletepopup,setdeletepopup] =useState(false);
    const [holidaydata,setholidaydata] =useState([]);
    const [popupdata,setpopupdata] =useState([]);
    const [curpage, setcurpage] = useState(1);
    const [onlytableloading, setonlytableloading] = useState(false);
    const handlesearchdata = ( page=1,data = holidaydata) => {
      // let page=1;
      setcurpage(page);
      console.log(data);
      settotalpages(Math.ceil(data.length / pageSize));
      console.log((page - 1) * pageSize, pageSize);
      // setstudentdata(data);
      setpaginateddata(
        data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
      );
      console.log( data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize));
    };
    const handlePageChange = (page, data = holidaydata) => {
      setcurpage(page);
      console.log((page - 1) * pageSize, pageSize);
      setpaginateddata(
        data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
      );
    };
    const paginationhandling=(data)=>{
      if (data.length >= pageSize) {
        setpaginateddata(data.slice(0, pageSize));
      } else setpaginateddata(data);
      let items = {};
      data.forEach((item) => {
        items[item.id] = false;
      });
      items["all"] = false;
      console.log("items", items);
      setcheckeditem(items);
  
      settotalpages(Math.ceil(data.length / pageSize));
    }
    useEffect(() => {
      
      const fetchHolidayData = async () => {
          try {
            setonlytableloading(true)
              const data = await getholidayinfo();
              setholidaydata(data);
            paginationhandling(data);
          } catch (error) {
              console.error("Error fetching holiday data:", error);
          }
          finally{
            setonlytableloading(false);
          }
      };

      fetchHolidayData();
  }, []);
 
  
    const refreshdata=()=>{
      setpopupdata([]);
      const fetchHolidayData = async () => {
        try {
          setcurpage(1);
          setonlytableloading(true)
            const data = await getholidayinfo();
            setholidaydata(data);
            paginationhandling(data)
        } catch (error) {
            console.error("Error fetching holiday data:", error);
        }
        finally{
          setonlytableloading(false);
        }
    };

    fetchHolidayData();
    }
    const handledatachange =()=>{

    }
    function formatDate(dateString) {
      if(dateString===null) return null;
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();
      return `${day} ${month}, ${year}`;
  }
  return (
    <div>
          <CreateHeader
          text="Holidays"
          subText="Add / Manage holidays for your school"
          showButton
          buttontext="Add a Holiday"
        //   incompleteprofileinfo={[true, incompleteprofilecount]}
          buttonstate={setcreatepopup}
        //   navto={"/studentdirectory/addstudent"}
        //   handledatachange={handledatachange}
        />
         {empty && <section className="feeStructure__container">
                
                <section className="feeStructure">
                    <div className="feeStructure__div">
                        <svg
                        viewBox="0 0 94 67"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="feeStructure__svg"
                        >
                        <path
                            d="M62.5241 33.5004C60.0419 33.5004 57.7066 32.1785 56.4434 30.0635L46.9994 14.4067L37.57 30.0635C36.2922 32.1932 33.9569 33.5151 31.4747 33.5151C30.8138 33.5151 30.1528 33.427 29.5213 33.236L9.39938 27.4785V53.6223C9.39938 55.7813 10.8681 57.6613 12.9538 58.1754L44.7081 66.1213C46.2063 66.4885 47.7778 66.4885 49.2613 66.1213L81.045 58.1754C83.1306 57.6467 84.5994 55.7667 84.5994 53.6223V27.4785L64.4775 33.2213C63.8459 33.4123 63.185 33.5004 62.5241 33.5004ZM93.7497 17.021L86.1856 1.92227C85.7303 1.01165 84.7463 0.4829 83.7328 0.615087L46.9994 5.3004L60.4678 27.6401C61.0259 28.5654 62.1422 29.006 63.185 28.7123L92.2516 20.4138C93.7056 19.9879 94.4106 18.3723 93.7497 17.021ZM7.81313 1.92227L0.249068 17.021C-0.426557 18.3723 0.293131 19.9879 1.73251 20.3992L30.7991 28.6976C31.8419 28.9913 32.9581 28.5507 33.5163 27.6254L46.9994 5.3004L10.2513 0.615087C9.23782 0.497587 8.26844 1.01165 7.81313 1.92227Z"
                            fill="#F5F7FA"
                        />
                        </svg>
                        <div className="feeStructure__textContainer">
                        <p className="feeStructure__text feeStructure__text--empty">
                            It’s empty here!
                        </p>
                        <p className="feeStructure__text feeStructure__text--subtext">
                        Get Started by adding Holidays for your school
                        </p>
                        </div>
                    </div>
                </section>
            </section>}
            <section
              className="dropdowns--section"
              style={{ display:'flex', flexDirection:'row',marginLeft:'0.4rem' }}
            >
             
        

           
              <div className="filter__dropdowns">
                {/* <GradesDropdown/> */}
                <CheckBoxDropDownWithSubItems
                  lefticon={<SortIcon />}
                  dropdownname={"Filter by"}
                  handledatachange={handledatachange}
                />
                <CustomDropdownWithCustomStyle
                  defaultText={"Sort By"}
                  icon={<FilterIcon />}
                  items={["Classes↑", "Classes↓"]}
                  width={"fit-content"}
                  dropdownName={"sort"}
                  handledatachange={handledatachange}
                  customStyle={{height:'0.9rem'
                  }}
                />
                 </div>
                <div style={{ marginTop: "0.5rem" }}>
                  <SearchBar
                  data={holidaydata}
                  handlesearchdata={handlesearchdata}
                  key1={"holiday_title"}
                    placeholder={"Search for Student"}
                    height='1.7rem'
                  />
               
                {/* <div className="settingbox">
                  <CiSettings size={22} />
                </div> */}
              </div>
            </section>
            {( onlytableloading) && (
            <div className="StudentDirectoryBodyTable">
              <div className="table-container">
                <div className="table-container-header">
                  <div className="cell-container" style={{ flex: "2" }}>
                    <Skeleton width={"10rem"} height={"100%"} />
                  </div>
                  <div className="cell-container">
                    <Skeleton width={"5rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"7rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"8rem"} height={"100%"} />
                  </div>
                  <div className="cell-container" style={{ flex: "1.5" }}>
                    <Skeleton width={"7rem"} height={"100%"} />
                  </div>
                  {/* <div className="cell-container">
                    <Skeleton width={"2rem"} height={"100%"} />
                  </div> */}
                  <div className="cell-container">
                    <Skeleton width={"3rem"} height={"100%"} />
                  </div>
                </div>

                <div className="table-container-body">
                  {Array.from({ length: 8 }, (_, index) => (
                    <div className="row-container">
                      <div className="cell-container" style={{ flex: "2" }}>
                        <Skeleton width={"10rem"} height={"100%"} />
                      </div>
                      <div className="cell-container">
                        <Skeleton width={"5rem"} height={"100%"} />
                      </div>
                      <div className="cell-container" style={{ flex: "1.5" }}>
                        <Skeleton width={"7rem"} height={"100%"} />
                      </div>
                      <div className="cell-container" style={{ flex: "1.5" }}>
                        <Skeleton width={"8rem"} height={"100%"} />
                      </div>
                      <div className="cell-container" style={{ flex: "1.5" }}>
                        <Skeleton width={"7rem"} height={"100%"} />
                      </div>
                      {/* <div className="cell-container">
                        <Skeleton width={"2rem"} height={"100%"} />
                      </div> */}
                      <div className="cell-container">
                        <Skeleton width={"3rem"} height={"100%"} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
           {!onlytableloading && <div style={{marginTop:'1rem',marginLeft:'0.8rem',width:'99%'}}>
            <div className="table-container">
                <div className="table-container-header">
                  <div className="header-cell-container" style={{ flex: "2" }}>
                    <input
                      type="checkbox"
                    //   checked={checkedItem["all"]}
                    //   onChange={() => {
                    //     handleCheckboxChange("all");
                    //   }}
                    />
                    Name of the Holiday
                  </div>
                  {/* <div className="header-cell-container">Student ID</div> */}
                  <div
                    className="header-cell-container"
                    style={{ flex: "1" }}
                  >
                 No.of Days
                  </div>
                  <div
                    className="header-cell-container"
                    style={{ flex: "2.5" }}
                  >
                    Date(s)
                  </div>
                 
                  <div className="header-cell-container">Actions</div>
                </div>
                <div className="table-container-body">
                  {paginateddata.length > 0 &&
                    // eslint-disable-next-line
                    paginateddata.map((item) => {
                      return (
                        <div className="row-container">
                          <div className="cell-container" style={{ flex: "2" }}>
                            <input
                              type="checkbox"
                            //   onChange={() => {
                            //     handleCheckboxChange(item.id);
                            //   }}
                            //   checked={checkeditem[item.id]}
                            />
                          {item.holiday_title}
                          </div>
                          
                          <div
                            className="cell-container"
                            style={{ flex: "1" }}
                          >
                            {item.number_of_days} Days
                          </div>
                          <div
                            className="cell-container"
                            style={{ flex: "2.5" }}
                          >
                           <CalendarIcon/>{formatDate(item.holiday_from_date)}
                           {item.holiday_to_date!==null && formatDate(item.holiday_from_date)!==formatDate(item.holiday_to_date) &&
                            <>
                            <FaArrowRightLong />
                           {formatDate(item.holiday_to_date)}
                            </>
                           }
                          </div>
                         
                         
                          {/* <div className="cell-container">9.5</div> */}
                          <div className="cell-container" style={{gap:'1.5rem'}}>
                            <FiEdit3 color='blue' size={'1rem'} style={{cursor:'pointer'}} onClick={()=>{setpopupdata([item.holiday_title,item.holiday_from_date,item.holiday_to_date,item.id]);setcreatepopup(true)}}/>
                            <AiOutlineDelete size={'1.2rem'} color='rgba(153, 160, 174, 1)' style={{cursor:'pointer'}} onClick={()=>{setdeletepopup(true);setpopupdata(item)}}/>
                          </div>
                        </div>
                      );
                    })} 
                </div>
                </div>
                <Pagination
            currentPage={curpage}
            totalPages={totalpages}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
            </div>}
            {deletepopup && <DeleteHolidayPopup popupdata={popupdata} setpopup={setdeletepopup} refreshdata={refreshdata}/>}
            {createpopup && <AddHolidayModal setpopupdata={setpopupdata} popupdata={popupdata} id={18} refreshdata={refreshdata} setpopup={setcreatepopup}/>}
            </div>
  )
}
