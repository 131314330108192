import React, { useContext, useEffect, useState } from "react";
import "./AssignPeriodModal.css";
import { RxCross1 } from "react-icons/rx";
import { BookIcon, ProfileIcon, BlueBookIcon, StudyHourIcon } from "../../../assets/svgs";
import { CustomDropdownWithCustomStyle } from "../../StudentDirectoryModule/Components/CustomDropdownWithCustomStyle";
import { getStaffdetails, getSubjectBasedOnBranchID } from "../../../api/api";
import UserContext from "../../../context/UserContext";
import { DecodeandEncodeDays } from "../../../home/components/DecodeandEncodeDays";
import { toast } from "sonner";

export const Toggle = ({ isOn, handleToggle }) => {
  return (
    <div className="toggle-container" onClick={handleToggle}>
      <div className={`toggle-switch ${isOn ? "on" : "off"}`}>
        <div className="toggle-button"></div>
      </div>
    </div>
  );
};

const AssignPeriodModal = ({ slot, onClose, position, slotIndex=0, classIndex=0, slotDetails={}, setSlotDetails=()=>{} ,selected_classes}) => {
  const [editdata, setEditdata] = useState({});
  const { userInfo } = useContext(UserContext);
  const [subjects, setSubjects] = useState(slotDetails.subjects || []);
  const [availableteachers, setavailableteachers] = useState([]);
  const [staffobject, setstaffobject] = useState(slotDetails.staffObject || []);
  console.log(slotIndex,slotDetails);
  const [subjectobject,setsubjectobject] =useState(slotDetails.subjectObject);
  
  const [selectedDays, setSelectedDays] = useState({
    M: true,
    T: true,
    W: true,
    Th: true,
    F: true,
    S: true,
  });
  const fullDayNames = {
    M: 'Monday',
    T: 'Tuesday',
    W: 'Wednesday',
    Th: 'Thursday',
    F: 'Friday',
    S: 'Saturday',
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userInfo.branch_id !== undefined && userInfo.branch_id !== null && slotDetails.staff_names.length === 0) {
          // Fetch staff details
          const staffRes = await getStaffdetails("staff_type=academic_staff");
          setstaffobject(staffRes);
          let staffNames = staffRes.map(item => item.name_of_the_staff);
          setavailableteachers(staffNames);
          // setEditdata(prevData => ({ ...prevData, staffObject: staffRes, staff_names: staffNames }));
          setSlotDetails(prevDetails => ({ ...prevDetails, staffObject: staffRes, staff_names: staffNames }));
          const assigned_subject_teacher_class = {};
          staffRes.forEach(staff => {
            if (staff.teachings) { // Check if staff.teachings is not null
              staff.teachings.forEach(teaching => {
                const key = `${teaching.class}-${teaching.section}`; // Create the key
                assigned_subject_teacher_class[key] = {
                  ...assigned_subject_teacher_class[key], // Corrected spread operator syntax
                  [teaching.subject]: staff.name_of_the_staff // Set the subject with value 1
                };
              });
            }
          });
        
          console.log("assigned",assigned_subject_teacher_class)
          // Ensure the above operations are completed before proceeding
          await Promise.all([
            setstaffobject(staffRes),
            setavailableteachers(staffNames),
            // setEditdata(prevData => ({ ...prevData, staffObject: staffRes, staff_names: staffNames })),
            setSlotDetails(prevDetails => ({ ...prevDetails, staffObject: staffRes, staff_names: staffNames,"assigned_subject_teacher_class":assigned_subject_teacher_class }))
          ]);

          // Fetch subjects
          const subjectRes = await getSubjectBasedOnBranchID(userInfo.branch_id);
          setsubjectobject(subjectRes);
          let subjects = subjectRes.map(item => item.subject);
          setSubjects(subjects);
          // setEditdata(prevData => ({ ...prevData, subjectObject: subjectRes, subjects: subjects }));
          setSlotDetails(prevDetails => ({ ...prevDetails, subjectObject: subjectRes, subjects: subjects }));

          // Ensure the above operations are completed before proceeding
          await Promise.all([
            setsubjectobject(subjectRes),
            setSubjects(subjects),
            // setEditdata(prevData => ({ ...prevData, subjectObject: subjectRes, subjects: subjects })),
            setSlotDetails(prevDetails => ({ ...prevDetails, subjectObject: subjectRes, subjects: subjects }))
          ]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    //eslint-disable-next-line
  }, [userInfo.branch_id]);



// ... existing code ...
  useEffect(() => {
    const assignedteachermatrix = slotDetails.assigned_teacher;
    let assignedteacherforcurrentslot=[];
    for(let i=0;i<assignedteachermatrix.length;i++){
      if(assignedteachermatrix[i][slotIndex]!==''){
        assignedteacherforcurrentslot.push(assignedteachermatrix[i][slotIndex]);
      }
    
    }console.log(assignedteacherforcurrentslot);
    const decodedDays = slotDetails.days[classIndex][slotIndex] === '' 
    ? ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] 
    : DecodeandEncodeDays("decode", slotDetails.days[classIndex][slotIndex]);
    const updatedSelectedDays = {
      M: decodedDays.includes('Monday'),
      T: decodedDays.includes('Tuesday'),
      W: decodedDays.includes('Wednesday'),
      Th: decodedDays.includes('Thursday'),
      F: decodedDays.includes('Friday'),
      S: decodedDays.includes('Saturday'),
    };
    setSelectedDays(updatedSelectedDays);
    setEditdata({
      assigned_teacher: slotDetails.assigned_teacher[classIndex][slotIndex],
      assigned_subject_name: slotDetails.assigned_subject_name[classIndex][slotIndex],
      work_hour: slotDetails.work_hour[classIndex][slotIndex],
      Subjectid: slotDetails.subject_ids[classIndex][slotIndex],
      Staffid: slotDetails.staff_ids[classIndex][slotIndex],
      assigned_teacher2: slotDetails.assigned_teacher2[classIndex][slotIndex], // Added item
      assigned_subject_name2: slotDetails.assigned_subject_name2[classIndex][slotIndex], // Added item
      work_hour2: slotDetails.work_hour2[classIndex][slotIndex], // Added item
      Staffid2: slotDetails.staff_ids2[classIndex][slotIndex], // Added item
      Subjectid2: slotDetails.subject_ids2[classIndex][slotIndex] // Added item
    });
    setSelectedOption(slotDetails.work_hour[classIndex][slotIndex]);
    setSelectedOption2(slotDetails.work_hour2[classIndex][slotIndex]);
    setavailableteachers(slotDetails.staff_names.filter(teacher => !assignedteacherforcurrentslot.includes(teacher)));
// console.log(assignedteachermatrix);
//eslint-disable-next-line
  }, [slotIndex])
  
  const handleAutomaticTeacherAssignment = async (data,field, value) => {
   let updatedData={...data, [field]: value };
   if (field === "assigned_teacher") {
    let Staffid = staffobject.find(item => item.name_of_the_staff === value);
    console.log(Staffid.user_id);
    updatedData["Staffid"] = Staffid.user_id;
    console.log(Staffid);
  } else if (field === "assigned_teacher2") {
    let Staffid2 = staffobject.find(item => item.name_of_the_staff === value);
    updatedData["Staffid2"] = Staffid2.user_id;
    console.log(Staffid2);
  }
  setEditdata(updatedData);
};
  const handledatachange = async(field, value) => {
    console.log(field, value);
    let updatedData = { ...editdata, [field]: value };
  
    if (field === "assigned_subject_name") {
      console.log(subjectobject);
      let Subjectid = subjectobject.find(item => item.subject === value);
      updatedData["Subjectid"] = Subjectid.id;
      console.log(slotDetails.assigned_subject_teacher_class[selected_classes[classIndex]][value]);
     
    } else if (field === "assigned_subject_name2") {
      console.log(subjectobject);
      let Subjectid2 = subjectobject.find(item => item.subject === value);
      updatedData["Subjectid2"] = Subjectid2.id;
      console.log(Subjectid2);
    } else if (field === "assigned_teacher") {
      let Staffid = staffobject.find(item => item.name_of_the_staff === value);
      console.log(Staffid.user_id);
      updatedData["Staffid"] = Staffid.user_id;
      // setEditdata(updatedData);
      console.log(Staffid);
    } else if (field === "assigned_teacher2") {
      let Staffid2 = staffobject.find(item => item.name_of_the_staff === value);
      updatedData["Staffid2"] = Staffid2.user_id;
      console.log(Staffid2);
    }
    setEditdata(updatedData);
  
   // Integrate the logic for handling assigned subject names
    const assignedTeacher = slotDetails.assigned_subject_teacher_class[selected_classes[classIndex]][value];
    if (field === "assigned_subject_name" || field === "assigned_subject_name2") {
      if (assignedTeacher === undefined) {
        setEditdata(updatedData);
        toast.error("Please assign a teacher for the selected subject in this class in classroom section"); // Show toast message
      } else {
        await handleAutomaticTeacherAssignment(updatedData,field === "assigned_subject_name" ? "assigned_teacher" : "assigned_teacher2", assignedTeacher)
        // await handledatachange(field === "assigned_subject_name" ? "assigned_teacher" : "assigned_teacher2", assignedTeacher);
      }
    }
  };

  const [selectedOption, setSelectedOption] = useState(true);
  const [selectedOption2, setSelectedOption2] = useState(true);
  // const handleWorkHourChange = () => {
  //   setSelectedOption(selectedOption === 'work' ? null : 'work');
  // };

  // const handleStudyHourChange = () => {
  //   setSelectedOption(selectedOption === 'study' ? null : 'study');
  // };

  const [isToggled, setIsToggled] = useState(slotDetails.assigned_teacher2[classIndex][slotIndex]===''?false:true);

  const toggleDay = (day) => {
    setSelectedDays((prev) => ({
      ...prev,
      [day]: !prev[day],
    }));
  };

  const onApplyClick = async (id = 0) => {
    console.log(editdata);
    // Create a deep copy of the slotDetails object to avoid mutating the original state
    let tempDetails = { ...slotDetails };
    const selectedFullDays = [];
    const notSelectedDays=[];
    for (const [shortDay, isSelected] of Object.entries(selectedDays)) {
      if (isSelected) {
        selectedFullDays.push(fullDayNames[shortDay]);
      }
      else{
        notSelectedDays.push(fullDayNames[shortDay]);
      }
    }
  
    // Wait for DecodeandEncodeDays to complete
    const encodedDays = await DecodeandEncodeDays("encode", selectedFullDays);
    const nencodedDays = await DecodeandEncodeDays("encode",notSelectedDays)
    console.log(encodedDays,nencodedDays); 

    // Ensure that the arrays within tempDetails are also deeply copied
    tempDetails.assigned_subject_name = [...tempDetails.assigned_subject_name];
    tempDetails.assigned_teacher = [...tempDetails.assigned_teacher];
    tempDetails.work_hour = [...tempDetails.work_hour];
    tempDetails.subject_ids = [...tempDetails.subject_ids];
    tempDetails.staff_ids = [...tempDetails.staff_ids];
    tempDetails.days = [...tempDetails.days];
    tempDetails.assigned_subject_name2 = [...tempDetails.assigned_subject_name2];
    tempDetails.assigned_teacher2 = [...tempDetails.assigned_teacher2];
    tempDetails.work_hour2 = [...tempDetails.work_hour2];
    tempDetails.subject_ids2 = [...tempDetails.subject_ids2];
    tempDetails.staff_ids2 = [...tempDetails.staff_ids2];
    tempDetails.days2 = [...tempDetails.days2];
    // Update the specific indices for the 2D arrays
    tempDetails.assigned_subject_name[classIndex] = [
      ...tempDetails.assigned_subject_name[classIndex],
    ];
    tempDetails.assigned_teacher[classIndex] = [
      ...tempDetails.assigned_teacher[classIndex],
    ];
    tempDetails.work_hour[classIndex] = [
      ...tempDetails.work_hour[classIndex],
    ];
    tempDetails.subject_ids[classIndex] = [
      ...tempDetails.subject_ids[classIndex],
    ];
    tempDetails.staff_ids[classIndex] = [
      ...tempDetails.staff_ids[classIndex],
    ];
    tempDetails.days[classIndex] = [
      ...tempDetails.days[classIndex],
    ];
    tempDetails.assigned_subject_name2[classIndex] = [
      ...tempDetails.assigned_subject_name2[classIndex],
    ];
    tempDetails.assigned_teacher2[classIndex] = [
      ...tempDetails.assigned_teacher2[classIndex],
    ];
    tempDetails.work_hour2[classIndex] = [
      ...tempDetails.work_hour2[classIndex],
    ];
    tempDetails.subject_ids2[classIndex] = [
      ...tempDetails.subject_ids2[classIndex],
    ];
    tempDetails.staff_ids2[classIndex] = [
      ...tempDetails.staff_ids2[classIndex],
    ];
    tempDetails.days2[classIndex] = [
      ...tempDetails.days2[classIndex],
    ];

   
      tempDetails.assigned_subject_name[classIndex][slotIndex] = editdata.assigned_subject_name;
      tempDetails.assigned_teacher[classIndex][slotIndex] = editdata.assigned_teacher;
      tempDetails.work_hour[classIndex][slotIndex] = selectedOption;
      tempDetails.subject_ids[classIndex][slotIndex] = editdata.Subjectid;
      tempDetails.staff_ids[classIndex][slotIndex] = editdata.Staffid;
      tempDetails.days[classIndex][slotIndex] = encodedDays;
      console.log(tempDetails);
      if (id !== 0)
      {tempDetails.assigned_subject_name2[classIndex][slotIndex] = editdata.assigned_subject_name2;
      tempDetails.assigned_teacher2[classIndex][slotIndex] = editdata.assigned_teacher2;
      tempDetails.work_hour2[classIndex][slotIndex] = selectedOption2;
      tempDetails.subject_ids2[classIndex][slotIndex] = editdata.Subjectid2;
      tempDetails.staff_ids2[classIndex][slotIndex] = editdata.Staffid2;
      tempDetails.days2[classIndex][slotIndex] = nencodedDays;
    }
    console.log(tempDetails);
    // Update the state with the modified tempDetails object
    setSlotDetails(tempDetails);
  
    // Log the indices and the updated tempDetails for debugging
    console.log(classIndex, slotIndex);
    console.log(tempDetails);
    onClose();
  };
  

  return (
    <div className="assignperiodmodal" style={{ top: position.top+40, left: position.left+40 }}>
      <div className="assignperiodmodalheader">
        Assign Period
        <RxCross1
          className="crossicon"
          size={11}
          strokeWidth={0.5}
          color="rgba(251, 55, 72, 1)"
          onClick={onClose} // Close modal on click
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '1rem' }}>
        <Toggle isOn={isToggled} handleToggle={() => setIsToggled(!isToggled)} />
        <span>Assign Multiple subjects</span>
      </div>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div className="assignperiodmodalbody">
          <CustomDropdownWithCustomStyle
            
            defaultText={slotDetails.assigned_subject_name[classIndex][slotIndex]===''?"Select Subject":slotDetails.assigned_subject_name[classIndex][slotIndex]}
            icon={<BookIcon />}
            items={subjects}
            width={"15rem"}
            dropdownName={"assigned_subject_name"}
            handledatachange={handledatachange}
            scrollenable={true}
          />
          <CustomDropdownWithCustomStyle
            defaultText={editdata.assigned_teacher===''?"Assign Teacher":editdata.assigned_teacher}
            icon={<ProfileIcon />}
            items={availableteachers}
            width={"15rem"}
            dropdownName={"assigned_teacher"}
            handledatachange={handledatachange}
            scrollenable={true}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {Object.entries(selectedDays).map(([day, isSelected]) => (
              <div
                key={day}
                className={isSelected ? "daybox-selected" : "daybox"}
                onClick={() => toggleDay(day)}
              >
                {day}
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', gap: '0.3rem', alignItems: 'center' }}>
            <input
              type="checkbox"
              checked={selectedOption}
              onClick={(event)=>{setSelectedOption(event.target.checked)}}
            />
            <BlueBookIcon />
            Work Hour
          </div>

          <div style={{ display: 'flex', gap: '0.45rem', alignItems: 'center' }}>
            <input
              type="checkbox"
              checked={!selectedOption}
              onClick={(event)=>{setSelectedOption(!event.target.checked)}}
            />
            <StudyHourIcon />
            Study Hour
          </div>
          <button className="button" style={{ width: '5rem', fontWeight: '500' }} onClick={()=>{onApplyClick(0)}}>Apply</button>
        </div>
        {isToggled && (
          <div className="toggled-content visible">
            <div className="verticalline"></div>
            <div className="assignperiodmodalbody">
          <CustomDropdownWithCustomStyle
            
            defaultText={slotDetails.assigned_subject_name2[classIndex][slotIndex]===''?"Select Subject":slotDetails.assigned_subject_name2[classIndex][slotIndex]}
            icon={<BookIcon />}
            items={subjects}
            width={"15rem"}
            dropdownName={"assigned_subject_name2"}
            handledatachange={handledatachange}
            scrollenable={true}
          />
          <CustomDropdownWithCustomStyle
          defaultText={editdata.assigned_teacher2===''?"Assign Teacher":editdata.assigned_teacher2}
            // defaultText={slotDetails.assigned_teacher2[classIndex][slotIndex]===''?"Assign Teacher":slotDetails.assigned_teacher2[classIndex][slotIndex]}
            icon={<ProfileIcon />}
            items={availableteachers}
            width={"15rem"}
            dropdownName={"assigned_teacher2"}
            handledatachange={handledatachange}
            scrollenable={true}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {Object.entries(selectedDays).map(([day, isSelected]) => (
              <div
                key={day}
                className={!isSelected ? "daybox-selected" : "daybox"}
                onClick={() => toggleDay(day)}
              >
                {day}
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', gap: '0.3rem', alignItems: 'center' }}>
            <input
              type="checkbox"
              checked={selectedOption2}
              onClick={(event)=>{setSelectedOption2(event.target.checked)}}
            />
            <BlueBookIcon />
            Work Hour
          </div>

          <div style={{ display: 'flex', gap: '0.45rem', alignItems: 'center' }}>
            <input
              type="checkbox"
              checked={!selectedOption2}
              onClick={(event)=>{setSelectedOption2(!event.target.checked)}}
            />
            <StudyHourIcon />
            Study Hour
          </div>
          <button className="button" style={{ width: '5rem', fontWeight: '500' }} onClick={()=>{onApplyClick(1)}}>Apply</button>
        </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignPeriodModal;