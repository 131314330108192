import React, { useState, useEffect, useRef, useContext } from "react";
// import { useNavigate } from "react-router-dom";

import UserContext from "../../context/UserContext";
import {
  useOutsideClick,
  useToggle,
} from "../../modules/FeeManagementModule/FeeStructureModule/hooks";
import {  SortIcon } from "../../assets/svgs";


export const DropdownWithSubItems = ({
    Items = {
      "Profile Status": ["Completed Profiles", "InCompleted Profiles"],
      "Class Status": ["Classes assigned", "Classes not assigned"],
    },
    righticon,
    lefticon,
    dropdownname = "Select an Timetable",
    dropdownwidth = '',
    width = '',
    subitems=true,
   
    handledatachange = () => {},
  }) =>  {
    // ... existing code ...
    const [selectedItem, setSelectedItem] = useState(dropdownname); // New state for selected item
    // console.log(Items);
    // const [checkedList, setCheckedList] = useState([]);
    // const [checkAll, setCheckAll] = useState(false);
    // const [list, setList] = useState([]);
    const [dropdownItems, setdropdownItems] = useState({});
    const dropdownRef = useRef(null);
    const { state: isOpen, handlers } = useToggle();
    useOutsideClick(dropdownRef, handlers.off);
  
    const { userInfo } = useContext(UserContext);
    // const navigate = useNavigate();
  
    useEffect(() => {
      if (userInfo.branch_id !== undefined && userInfo.branch_id !== null) {
        let formattedItems = Items;
        if (Array.isArray(Items)) {
          formattedItems = { "Items": Items };
        } else if (typeof Items === 'object' && !Array.isArray(Items)) {
          formattedItems = Object.keys(Items).reduce((acc, key) => {
            acc[key] = Array.isArray(Items[key]) ? Items[key] : [];
            return acc;
          }, {});
        }
        setdropdownItems(formattedItems);
        // setCheckedList(
        //   Object.keys(formattedItems).flatMap((item) => [item, ...formattedItems[item]])
        // );
       
        
      }
      //eslint-disable-next-line
    }, [Items]);
  
    const onChange = (option, subOption) => {
      setSelectedItem(`Viewing: ${option}/${subOption}`); // Update selected item
      handledatachange("class_section_mapping_id", subOption); // Call handledatachange with the new item
      handlers.toggle();
   
    };

    return (
      <div className="dropdown_container" ref={dropdownRef}>
        <button
          className="dropdown_dropdown-button"
          style={{ justifyContent: 'space-between', width: width, gap: '0.5rem' }}
          onClick={handlers.toggle}
        >
          <SortIcon/>
          <>
            {selectedItem} {/* Display selected item */}
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="custom__dropdown--icon"
            >
              <path
                d="M10.796 9.99956L7.0835 6.28706L8.144 5.22656L12.917 9.99956L8.144 14.7726L7.0835 13.7121L10.796 9.99956Z"
                fill="#99A0AE"
              ></path>
            </svg>
          </>
        </button>
        {isOpen && (
          <div className="dropdown_dropdown" style={{ width: width ,  maxHeight: "8.5rem", overflow: "auto",zIndex:'12'  }}>
            <div className="dropdown_checkbox-group">
              {Object.entries(dropdownItems).map(([grade, subGrades]) => (
                <React.Fragment key={grade}>
                  <label className="dropdown_checkbox-label" style={{color:'rgba(153, 160, 174, 1)',paddingLeft:'0.4rem'}} >
                    {grade}
                  </label>
                  {subGrades.map((subGrade) => (
                    <label
                      className="dropdown_checkbox-child-label"
                      key={subGrade}
                      onClick={() => onChange(grade, subGrade)} // Update on click
                    >
                      <span>{subGrade}</span>
                    </label>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };