import React, { useEffect, useRef, useState } from "react";
import "./AssignClasses.css";
import { toast } from "sonner";
import AssignPeriodModal from "./AssignPeriodModal";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { AiOutlineEdit } from "react-icons/ai";
import { EditPeriodTimingsModal } from "./EditPeriodTimingsModal";
import { SubjectAssignedCell } from "./SubjectAssignedCell";
import { Tooltip } from "react-tooltip";
export const AssignClasses = ({slotDetails, setSlotDetails,timetable,timeSlots,setTimeSlots, settimetable}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null); // New state for selected class
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  // const timetable = {
  //   timetable_title: "TT-Title",
  //   selected_classes: ["VI - A", "VI - B", "VI - C", "VI - D", "VII - A"],
  //   working_hours: 8,
  //   working_days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
  //   number_of_periods: 7,
  //   periods_duration: 60,
  //   number_of_breaks: 1,
  //   breaks_duration: 10,
  //   is_prayer_enabled: true,
  //   prayer_duration: 10,
  // };

  // const initialArray = Array.from({ length: timetable.selected_classes.length }, () => Array(timetable.number_of_periods+2).fill(''));

  // const [slotDetails, setSlotDetails] = useState({
  //   assigned_subject_name: initialArray,
  //   timings: Array(timetable.number_of_periods+2).fill(['start','end']),
  //   days: initialArray,
  //   work_hour: initialArray,
  //   assigned_teacher: initialArray
  // })
console.log(slotDetails);
  useEffect(() => {
    console.log(slotDetails);
    const initialArray = Array.from({ length: timetable.selected_classes.length }, () => Array(timetable.number_of_periods+timetable.number_of_breaks+1).fill(''));
    if(slotDetails.length===0)
    setSlotDetails({
      assigned_subject_name: initialArray,
      work_hour: initialArray,
      assigned_teacher: initialArray,
      subject_ids:initialArray,
      staff_ids:initialArray,
      days: initialArray,

      assigned_subject_name2: initialArray,
      work_hour2: initialArray,
      assigned_teacher2: initialArray,
      subject_ids2:initialArray,
      staff_ids2:initialArray,
      days2: initialArray,

      staffObject:[],
      staff_names:[],
      subjects:[],
      subjectobject:[],
      // assigned_teacher_ids:initialArray,
      timings: Array(timetable.number_of_periods+2).fill(['start','end']),
      assigned_subject_teacher_classroom:{}
      
    })
    //eslint-disable-next-line
  }, [])
  

  // function generateTimeSlots() {
  //   let slots = [];
  //   if (timetable.is_prayer_enabled) {
  //     slots.push({ period: "PRAYER", start: "8:20AM", end: "8:30AM" });
  //   }
  //   slots = slots.concat([
  //     { period: "PERIOD I", start: "8:30AM", end: "9:30AM" },
  //     { period: "PERIOD II", start: "9:30AM", end: "10:30AM" },
  //     { period: "BREAK", start: "10:30AM", end: "10:40AM" },
  //     { period: "PERIOD III", start: "10:40AM", end: "11:40AM" },
  //     { period: "PERIOD IV", start: "11:40AM", end: "12:40AM" },
  //     { period: "BREAK", start: "12:40PM", end: "1:40PM" },
  //     { period: "PERIOD V", start: "1:40PM", end: "2:40PM" },
  //     { period: "PERIOD VI", start: "2:40PM", end: "3:40PM" },
  //     { period: "BREAK", start: "3:40PM", end: "3:50PM" },
  //     { period: "PERIOD VII", start: "3:50PM", end: "4:50PM" },
  //     // Add more periods and breaks as needed
  //   ]);
  //   return slots;
  // }
  // console.log(timetable);
  // const [timeSlots, setTimeSlots] = useState(generateTimeSlots([]));

  function generateTimeSlots(existingTimeSlots = []) {
    console.log(existingTimeSlots);
    // console.log(slotDetails);
    // console.log(timetable);
    let slots = [];
    let startTime = new Date();
    const parseTimeString = (timeString) => {
      const [time, modifier] = timeString.split(' ');
      let [hours, minutes] = time.split(':').map(Number);
      if (modifier === 'PM' && hours !== 12) hours += 12;
      if (modifier === 'AM' && hours === 12) hours = 0;
      return { hours, minutes };
    };
    const formatTime1 = (hour) => {
      console.log(hour);
      const ampm = hour >= 12 ? "PM" : "AM";
      const displayHour = Math.floor(hour) % 12 || 12;
      const minutes = (hour % 1) * 60;
      const displayMinutes = minutes === 0 ? "00" : minutes;
      return `${displayHour}:${displayMinutes} ${ampm}`;
    };
  
    const startTimeObj = parseTimeString(formatTime1(timetable.range[0]));
    startTime.setHours(startTimeObj.hours, startTimeObj.minutes, 0);
    const addTime = (date, minutes) => {
      return new Date(date.getTime() + minutes * 60000);
    };
  
    const formatTime = (date) => {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      hours = hours < 10 ? '0' + hours : hours; // Add leading zero to single-digit hours
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return hours + ':' + minutes + ampm;
    };
  
    const parseDuration = (duration) => {
      const time = duration.split(' ');
      if (time.length === 4) {
        return parseInt(time[0]) * 60 + parseInt(time[2]);
      } else {
        if (time[1] === "hour") return parseInt(time[0] * 60);
        return parseInt(time[0]);
      }
    };
  
    const periodsDuration = parseDuration(timetable.periods_duration);
    const breaksDuration = parseDuration(timetable.breaks_duration);
    const prayerDuration = parseDuration(timetable.prayer_duration);
  
    console.log(periodsDuration, breaksDuration, prayerDuration);
  
    if (timetable.is_prayer_enabled) {
      let endTime = addTime(startTime, prayerDuration);
      slots.push({ period: "PRAYER", start: formatTime(startTime), end: formatTime(endTime), duration: prayerDuration });
      startTime = endTime;
    }
  
    let breaksAdded = 0;
    const parseTime = (timeString) => {
      const time = timeString.slice(0, -2);
      const modifier = timeString.slice(-2);
      let [hours, minutes] = time.split(':');
      if (hours === '12') {
        hours = '00';
      }
      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }
      return new Date(new Date().setHours(hours, minutes, 0, 0));
    };
    const calculateDuration = (startTime, endTime) => {
      const start = parseTime(startTime);
      const end = parseTime(endTime);
      const durationInMinutes = (end - start) / 60000; // Convert milliseconds to minutes
      return durationInMinutes;
    };
    let endTime;
    for (let i = 1; i <= timetable.number_of_periods; i++) {
      endTime = addTime(startTime, periodsDuration);
     slots.push({ period: `PERIOD ${i}`, start: formatTime(startTime), end: formatTime(endTime), duration: calculateDuration(formatTime(startTime), formatTime(endTime)) });
     startTime = endTime;
 
     if (breaksAdded < timetable.number_of_breaks && i % 2 === 0 ) {
       let breakDuration;
       if (breaksAdded === 1 ) {
         // long break gets the remaining time
         breakDuration = parseDuration(timetable.breaks_duration) - (10 * (timetable.number_of_breaks - 1));
       } else {
         breakDuration = 10; // All other breaks are 10 minutes
       }
       endTime = addTime(startTime, breakDuration);
       slots.push({ period: "BREAK", breaknumber:breaksAdded+1 ,start: formatTime(startTime), end: formatTime(endTime), duration: breakDuration });
       startTime = endTime;
       breaksAdded++;
     }
   }

   // Add remaining breaks at the end if any
   while (breaksAdded < timetable.number_of_breaks) {
     let breakDuration = 10; // Default break duration
     if (breaksAdded === 1) {
       breakDuration = parseDuration(timetable.breaks_duration) - (10 * (timetable.number_of_breaks - 1));
     }
     endTime = addTime(startTime, breakDuration);
     slots.push({ period: "BREAK", breaknumber: breaksAdded + 1, start: formatTime(startTime), end: formatTime(endTime), duration: breakDuration });
     startTime = endTime;
     breaksAdded++;
   }
    console.log(slots);
  
    if (existingTimeSlots.length > 0) {
      const updatedSlots = existingTimeSlots.map((existingSlot, index) => {
        const newSlot = slots.find(slot => 
          slot.period === existingSlot.period && 
          (slot.period !== "BREAK" || slot.breaknumber === existingSlot.breaknumber)
        ) || {};
      console.log(newSlot);
        return { ...existingSlot, ...newSlot, start: newSlot.start, end: newSlot.end };
      });
  
      // Add new slots that are not in existingTimeSlots
      slots.forEach(slot => {
        if (slot.period !== "PRAYER" && !existingTimeSlots.some(existingSlot => existingSlot.period === slot.period)) {
          updatedSlots.push(slot);
        }
      });
  
      // Check if prayer slot needs to be added or removed
      const prayerSlotInCurrent = slots.some(slot => slot.period === "PRAYER");
      const prayerSlotInExisting = existingTimeSlots.some(slot => slot.period === "PRAYER");
  
      if (prayerSlotInCurrent && !prayerSlotInExisting) {
        const prayerSlot = slots.find(slot => slot.period === "PRAYER");
        updatedSlots.unshift(prayerSlot); // Add prayer slot at the start
      } else if (!prayerSlotInCurrent && prayerSlotInExisting) {
        const prayerSlotIndex = updatedSlots.findIndex(slot => slot.period === "PRAYER");
        if (prayerSlotIndex !== -1) {
          updatedSlots.splice(prayerSlotIndex, 1); // Remove prayer slot
        }
      }
  
      // Add additional breaks to the end if current slots have more breaks
      let currentBreaks = timetable.number_of_breaks;
      let existingBreaks = existingTimeSlots.filter(slot => slot.period === "BREAK").length;
      console.log(currentBreaks,existingBreaks);
      if (currentBreaks > existingBreaks) {
        toast.error("extra breaks are added at the end you can drag for rearrangement", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    
      const unformatTime = (timeString) => {
        console.log(timeString);
        const [time, modifier] = timeString.split(/(AM|PM)/);
        let [hours, minutes] = time.split(':').map(Number);
        if (modifier === 'PM' && hours !== 12) hours += 12;
        if (modifier === 'AM' && hours === 12) hours = 0;
        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
      };
  console.log(updatedSlots);
     while(currentBreaks > existingBreaks) {
      const lastPeriodEndTime = updatedSlots[updatedSlots.length - 1].end;
      // const lastPeriodEndTimeObj = parseTimeString(lastPeriodEndTime);
          startTime=unformatTime(lastPeriodEndTime);
          console.log(startTime);
        endTime = addTime(startTime, 10);
        updatedSlots.push({ period: "BREAK",breaknumber:existingBreaks+1, start: formatTime(startTime), end: formatTime(endTime), duration: 10 });
        endTime = startTime;
        // breaksAdded++;
        existingBreaks++;
      }
  
      // Remove extra periods if new slots have fewer periods
      // if (slots.length < existingTimeSlots.length) {
      //   updatedSlots.splice(slots.length);
      // }
  
      // Return the updated slots
      return updatedSlots;
    }
  
    return slots;
  }
 
 
  useEffect(() => {
    console.log(timeSlots);
    if(!timetable.timetable_start_time){
    const parseTimeString = (timeString) => {
      const [time, modifier] = timeString.split(' ');
      let [hours, minutes] = time.split(':').map(Number);
      if (modifier === 'PM' && hours !== 12) hours += 12;
      if (modifier === 'AM' && hours === 12) hours = 0;
      return { hours, minutes };
    };
    const formatTime1 = (hour) => {
      console.log(hour);
      const ampm = hour >= 12 ? "PM" : "AM";
      const displayHour = Math.floor(hour) % 12 || 12;
      const minutes = (hour % 1) * 60;
      const displayMinutes = minutes === 0 ? "00" : minutes;
      return `${displayHour}:${displayMinutes} ${ampm}`;
    };
    let startTime = new Date();
      const startTimeObj = parseTimeString(formatTime1(timetable.range[0]));
   startTime.setHours(startTimeObj.hours, startTimeObj.minutes, 0); 
   let endTime = new Date();
   const EndTimeObj = parseTimeString(formatTime1(timetable.range[1]));
   endTime.setHours(EndTimeObj.hours, EndTimeObj.minutes, 0); 
   settimetable({...timetable,"timetable_start_time":startTime,"timetable_end_time":endTime});
    }
    // setTimeSlots(generateTimeSlots(timeSlots));
    //eslint-disable-next-line
  }, [timeSlots]);
  const hasInitializedTimeSlots = useRef(false);
  const updateTimeSlots = async () => {
    const newTimeSlots = await generateTimeSlots(timeSlots);
    setTimeSlots(newTimeSlots);
  };
  
  useEffect(() => {
    console.log(timeSlots);
    if (!hasInitializedTimeSlots.current) {
      (async () => {
        await updateTimeSlots();
        hasInitializedTimeSlots.current = true;
      })();
    }
    //eslint-disable-next-line
  }, [timetable]);
  const [edittimeslot,setedittimeslot]=useState([]);
  const handleEditClick = (event,slot,index,isBreak) => {
    setedittimeslot({...slot,id:index,isbreak:isBreak});
    setIsModalOpen(false);
    const rect = event.target.getBoundingClientRect();
    const modalWidth = 280; // Adjust this value based on your modal's width
    const modalHeight = 150; // Adjust this value based on your modal's height
    const containerElement = event.target.closest('.timetable-container');

    if (!containerElement) {
      console.error('Container element not found');
      return;
    }

    const containerRect = containerElement.getBoundingClientRect();

    let top = rect.top + window.scrollY;
    let left = rect.left + window.scrollX;

    // Check if the modal goes out of the container's right boundary
    if (left + modalWidth > containerRect.right) {
      left = rect.left + window.scrollX - modalWidth; // Display on the left side of the icon
    }

    // Check if the modal goes out of the container's bottom boundary
    if (top + modalHeight > containerRect.bottom) {
      top = rect.top + window.scrollY - modalHeight; // Adjust the top position
    }

    console.log('Modal Position:', { top, left });
    setModalPosition({ top, left });
    setIsEditModalVisible(true);
  };
  const handleedittimeslot = (slotdetails) => {
    const updatedSlots = [...timeSlots];
    const { id, start, end } = slotdetails;
    console.log(slotdetails);

    const parseTime = (timeStr) => {
      const [time, modifier] = timeStr.split(/(AM|PM)/);
      let [hours, minutes] = time.split(':').map(Number);
      if (modifier === 'PM' && hours !== 12) hours += 12;
      if (modifier === 'AM' && hours === 12) hours = 0;
      return { hours, minutes };
    };

    const formatTime = (hours, minutes) => {
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return `${hours}:${minutes}${ampm}`;
    };

    const startTime = parseTime(start);
    const endTime = parseTime(end);
    const duration = (endTime.hours * 60 + endTime.minutes) - (startTime.hours * 60 + startTime.minutes);

    const previousEndTime = parseTime(updatedSlots[id].end);
    const timeDifference = (endTime.hours * 60 + endTime.minutes) - (previousEndTime.hours * 60 + previousEndTime.minutes);

    updatedSlots[id] = { ...updatedSlots[id], start, end, duration };

    // Update subsequent slots
    for (let i = id + 1; i < updatedSlots.length; i++) {
      const slotStartTime = parseTime(updatedSlots[i].start);
      const slotEndTime = parseTime(updatedSlots[i].end);

      const newStartTime = new Date(0, 0, 0, slotStartTime.hours, slotStartTime.minutes + timeDifference);
      const newEndTime = new Date(0, 0, 0, slotEndTime.hours, slotEndTime.minutes + timeDifference);

      updatedSlots[i] = {
        ...updatedSlots[i],
        start: formatTime(newStartTime.getHours(), newStartTime.getMinutes()),
        end: formatTime(newEndTime.getHours(), newEndTime.getMinutes())
      };
    }

    setTimeSlots(updatedSlots);
  };
  const handleCloseModal = () => {
    setIsEditModalVisible(false);
  };

  const [slotIndex, setSlotIndex] = useState();
  const [classIndex, setClassIndex] = useState();

  const handleSlotClick = (slot, className, event, si, ci) => {
    setIsEditModalVisible(false);

    const rect = event.target.getBoundingClientRect();
    const modalWidth = 280;
    const modalHeight = 280;
    const containerElement = event.target.closest('.timetable-container');

    setSlotIndex(si);
    setClassIndex(ci);
    const tempDetails = {...slotDetails};
    tempDetails.timings[slotIndex] = [slot.start,slot.end];
    setSlotDetails(tempDetails);

    if (!containerElement) {
      console.error('Container element not found');
      return;
    }

    const containerRect = containerElement.getBoundingClientRect();

    let top = rect.top + window.scrollY;
    let left = rect.left + window.scrollX;

    // Check if the modal goes out of the container's right boundary
    if (left + modalWidth > containerRect.right) {
      left = rect.left + window.scrollX - modalWidth; // Display on the left side of the icon
    }

    // Check if the modal goes out of the container's bottom boundary
    if (top + modalHeight > containerRect.bottom) {
      top = rect.top + window.scrollY - modalHeight; // Adjust the top position
    }

    console.log('Modal Position:', { top, left });
    setModalPosition({ top, left });
    setSelectedSlot(slot);
    setSelectedClass(className);
    setIsModalOpen(true);
  };

  const timetableGridRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      // setIsModalOpen(false);
      // setIsEditModalVisible(false);
    };

    const timetableGrid = timetableGridRef.current;
    if (timetableGrid) {
      timetableGrid.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (timetableGrid) {
        timetableGrid.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  

  

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
    event.dataTransfer.effectAllowed = "move";
  };

  const handleDrop = (event, index) => {
    event.preventDefault();
    const draggedIndex = event.dataTransfer.getData("text/plain");
    if (draggedIndex !== index) {
      const updatedSlots = [...timeSlots];
      const [draggedSlot] = updatedSlots.splice(draggedIndex, 1);
      updatedSlots.splice(index, 0, draggedSlot);
      setTimeSlots(recalculateTimes(updatedSlots));

      // Update slotDetails
      const updatedSlotDetails = { ...slotDetails };
      timetable.selected_classes.forEach((_, classIndex) => {
        // Update assigned_teacher
        const tempTeacher = updatedSlotDetails.assigned_teacher[classIndex][draggedIndex];
        updatedSlotDetails.assigned_teacher[classIndex].splice(draggedIndex, 1);
        updatedSlotDetails.assigned_teacher[classIndex].splice(index, 0, tempTeacher);

        // Update staff_ids
        const tempStaffId = updatedSlotDetails.staff_ids[classIndex][draggedIndex];
        updatedSlotDetails.staff_ids[classIndex].splice(draggedIndex, 1);
        updatedSlotDetails.staff_ids[classIndex].splice(index, 0, tempStaffId);

        // Update subject_ids
        const tempSubjectId = updatedSlotDetails.subject_ids[classIndex][draggedIndex];
        updatedSlotDetails.subject_ids[classIndex].splice(draggedIndex, 1);
        updatedSlotDetails.subject_ids[classIndex].splice(index, 0, tempSubjectId);

        // Update work_hour
        const tempWorkHour = updatedSlotDetails.work_hour[classIndex][draggedIndex];
        updatedSlotDetails.work_hour[classIndex].splice(draggedIndex, 1);
        updatedSlotDetails.work_hour[classIndex].splice(index, 0, tempWorkHour);

        // Update subject_name
        const tempSubjectName = updatedSlotDetails.assigned_subject_name[classIndex][draggedIndex];
        updatedSlotDetails.assigned_subject_name[classIndex].splice(draggedIndex, 1);
        updatedSlotDetails.assigned_subject_name[classIndex].splice(index, 0, tempSubjectName);

        // Update assigned_teacher2
        const tempTeacher2 = updatedSlotDetails.assigned_teacher2[classIndex][draggedIndex];
        updatedSlotDetails.assigned_teacher2[classIndex].splice(draggedIndex, 1);
        updatedSlotDetails.assigned_teacher2[classIndex].splice(index, 0, tempTeacher2);

        // Update staff_ids2
        const tempStaffId2 = updatedSlotDetails.staff_ids2[classIndex][draggedIndex];
        updatedSlotDetails.staff_ids2[classIndex].splice(draggedIndex, 1);
        updatedSlotDetails.staff_ids2[classIndex].splice(index, 0, tempStaffId2);

        // Update subject_ids2
        const tempSubjectId2 = updatedSlotDetails.subject_ids2[classIndex][draggedIndex];
        updatedSlotDetails.subject_ids2[classIndex].splice(draggedIndex, 1);
        updatedSlotDetails.subject_ids2[classIndex].splice(index, 0, tempSubjectId2);

        // Update work_hour2
        const tempWorkHour2 = updatedSlotDetails.work_hour2[classIndex][draggedIndex];
        updatedSlotDetails.work_hour2[classIndex].splice(draggedIndex, 1);
        updatedSlotDetails.work_hour2[classIndex].splice(index, 0, tempWorkHour2);

        // Update assigned_subject_name2
        const tempSubjectName2 = updatedSlotDetails.assigned_subject_name2[classIndex][draggedIndex];
        updatedSlotDetails.assigned_subject_name2[classIndex].splice(draggedIndex, 1);
        updatedSlotDetails.assigned_subject_name2[classIndex].splice(index, 0, tempSubjectName2);
      });
      setSlotDetails(updatedSlotDetails);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };
//eslint-disable-next-line
  const handleDragEnter = (event, index) => {
    event.preventDefault();
    const draggedIndex = event.dataTransfer.getData("text/plain");
    if (draggedIndex !== index) {
      const updatedSlots = [...timeSlots];
      const [draggedSlot] = updatedSlots.splice(draggedIndex, 1);
      updatedSlots.splice(index, 0, draggedSlot);
      setTimeSlots(recalculateTimes(updatedSlots));

      // Update slotDetails
      const updatedSlotDetails = { ...slotDetails };
      timetable.selected_classes.forEach((_, classIndex) => {
        const temp = updatedSlotDetails.assigned_teacher[classIndex][draggedIndex];
        updatedSlotDetails.assigned_teacher[classIndex].splice(draggedIndex, 1);
        updatedSlotDetails.assigned_teacher[classIndex].splice(index, 0, temp);
      });
      setSlotDetails(updatedSlotDetails);
    }
  };

  const recalculateTimes = (slots) => {
    let startTime = new Date();
    startTime.setHours(8, 20, 0); // Start time at 8:20 AM

    const addTime = (date, minutes) => {
      return new Date(date.getTime() + minutes * 60000);
    };

    const formatTime = (date) => {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return hours + ':' + minutes + ampm;
    };

    return slots.map(slot => {
      let endTime = addTime(startTime, slot.duration || 30); // Default duration to 30 minutes if not provided
      const updatedSlot = {
        ...slot,
        start: formatTime(startTime),
        end: formatTime(endTime)
      };
      startTime = endTime;
      return updatedSlot;
    });
  };

  const scrollTimetable = (direction) => {
    const timetableGrid = document.querySelector('.timetable-grid');
    if (timetableGrid) {
      const scrollAmount = 250; // Adjust this value as needed
      timetableGrid.scrollLeft += direction === 'left' ? -scrollAmount : scrollAmount;
    }
  };
 

    const handledeletebreak = (slotdetails) => {
      settimetable(prev => ({
        ...prev,
        number_of_breaks: prev.number_of_breaks - 1
      }));
      const updatedSlots = [...timeSlots];
      const { id } = slotdetails;
      
      // Remove the break slot
      updatedSlots.splice(id, 1);
  
      // Recalculate times for the remaining slots
      const recalculateTimes = (slots) => {
        let startTime = new Date();
    const parseTimeString = (timeString) => {
      const [time, modifier] = timeString.split(' ');
      let [hours, minutes] = time.split(':').map(Number);
      if (modifier === 'PM' && hours !== 12) hours += 12;
      if (modifier === 'AM' && hours === 12) hours = 0;
      return { hours, minutes };
    };
    const formatTime1 = (hour) => {
      console.log(hour);
      const ampm = hour >= 12 ? "PM" : "AM";
      const displayHour = Math.floor(hour) % 12 || 12;
      const minutes = (hour % 1) * 60;
      const displayMinutes = minutes === 0 ? "00" : minutes;
      return `${displayHour}:${displayMinutes} ${ampm}`;
    };
    
    const startTimeObj = parseTimeString(formatTime1(timetable.range[0]));
    startTime.setHours(startTimeObj.hours, startTimeObj.minutes, 0); 
        // startTime.setHours(8, 20, 0); // Start time at 8:20 AM
  
        const addTime = (date, minutes) => {
          return new Date(date.getTime() + minutes * 60000);
        };
  
        const formatTime = (date) => {
          let hours = date.getHours();
          let minutes = date.getMinutes();
          let ampm = hours >= 12 ? 'PM' : 'AM';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? '0' + minutes : minutes;
          return hours + ':' + minutes + ampm;
        };
  
        return slots.map(slot => {
          let endTime = addTime(startTime, slot.duration || 30); // Default duration to 30 minutes if not provided
          const updatedSlot = {
            ...slot,
            start: formatTime(startTime),
            end: formatTime(endTime)
          };
          startTime = endTime;
          return updatedSlot;
        });
      };
  
      const newSlots = recalculateTimes(updatedSlots);
      setTimeSlots(newSlots);
  
      // Update slotDetails
      const updatedSlotDetails = { ...slotDetails };
      timetable.selected_classes.forEach((_, classIndex) => {
        updatedSlotDetails.assigned_teacher[classIndex].splice(id, 1);
      });
      setSlotDetails(updatedSlotDetails);
    };
  
  return (
    <div className="timetable-container">
      <div className="timetable-header">
      <Tooltip
        id="my-tooltip"
        style={{
          maxWidth: "371px",
          position: "absolute",
          marginLeft: "0px",
          zIndex: '11',
          border:'0'
        }}
      />
        <div className="header-item">
          TOTAL PERIODS<br />
          <div style={{ height: "10px" }}></div>
          <div style={{ fontSize: "14px", fontWeight: "bold", color: "black" }}>
            {timetable.number_of_periods}
          </div>
        </div>
        <div className="header-item">
          CLASS HOURS<br />
          <div style={{ height: "10px" }}></div>
          <div style={{ fontSize: "14px", fontWeight: "bold", color: "black" }}>
            {timetable.working_hours} Hrs
          </div>
        </div>
        <div className="header-item">
          BREAK HOURS<br />
          <div style={{ height: "10px" }}></div>
          <div style={{ fontSize: "14px", fontWeight: "bold", color: "black" }}>
            {(timetable.breaks_duration) }
</div>
        </div>
      </div>
      <div
        className="timetable-grid"
        style={{
          gridTemplateColumns: `120px repeat(${timeSlots.length}, 1fr)`,
          borderRight: "none",paddingBottom:'5rem'
        }}
        ref={timetableGridRef}
      >
        <div className="time-slots">
          <div
            className="empty-header"
            style={{
              position: "sticky",
              left: 0,
              zIndex: 3,
              // borderBottom: "0px",
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2rem'
            }}
          >
            <SlArrowLeft size={10} strokeWidth={90} color="rgba(82, 88, 102, 1)" style={{ cursor: 'pointer' }} onClick={() => scrollTimetable('left')} />
            <SlArrowRight size={10} style={{ cursor: 'pointer' }} onClick={() => scrollTimetable('right')} strokeWidth={90} color="rgba(82, 88, 102, 1)" />
          </div>
          {timeSlots.map((slot, index) => (
            <div
              key={index}
              style={{ 
                width: (slot.period !== "BREAK" && slot.period !== "PRAYER") ? '' : '5rem',
                position: 'sticky', // Make the whole part sticky
                top: 0, // Stick to the top
                backgroundColor: 'white', // Ensure background color to avoid overlap issues
                zIndex: 1 // Ensure it stays above other content
              }}
              draggable={slot.period === "BREAK"}
              onDragStart={(event) => handleDragStart(event, index)}
              onDragOver={handleDragOver}
              onDrop={(event) => handleDrop(event, index)}
            >
              <div
                className="time-slot"
                style={{ 
                  borderTop: "1px solid rgba(235, 241, 255, 1)", 
                  display: 'flex', 
                  gap: '0.7rem', 
                  width: (slot.period !== "BREAK" && slot.period !== "PRAYER") ? '' : '5rem',
                  backgroundColor: 'white', // Ensure background color to avoid overlap issues
                }}
              >
              { (slot.period !== "BREAK" && slot.period !== "PRAYER") ? `${slot.start} - ${slot.end}` : `${slot.duration} mins`}
             { ( slot.period !== "PRAYER") &&  <AiOutlineEdit data-tooltip-id="my-tooltip"
              data-tooltip-content={slot.period !== "BREAK"?"Edit Period Details":"Edit and Delete Break"}
              data-tooltip-place="top" size={16} style={{ cursor: 'pointer', position: 'relative' }} onClick={(event)=>{handleEditClick(event,slot,index,slot.period === "BREAK")}} />}
              </div>
              <div
                className="time-slot"
                style={{
                  backgroundColor: "rgba(235, 241, 255, 0.25)",
                  borderRight: "1px solid rgba(235, 241, 255, 1)",
                  width: (slot.period !== "BREAK" && slot.period !== "PRAYER") ? '' : '5rem'
                }}
              >
                {slot.period}
              </div>
            </div>
          ))}
        </div>
        {timetable.selected_classes.map((className, classIndex) => (
          <div key={classIndex} className="class-row">
            <div
              className="class-header"
              style={{ borderRight: "1px solid rgba(235, 241, 255, 1)" }}
            >
              {className}
            </div>{" "}
            {/* Class Name Column */}
            {timeSlots.map((slot, slotIndex) => (
              <div
                key={slotIndex}
                className={`subject-slot ${isModalOpen && selectedSlot && selectedSlot.period === slot.period && selectedClass === className ? 'highlighted-slot' : ''}`}
                style={{
                  borderLeft: slotIndex === 0 ? "0" : "",
                  borderRight: slotIndex === timeSlots.length - 1 ? "1px solid rgba(235, 241, 255, 1)" : "none",
                  borderTop: classIndex === 0 ? '1px solid var(--state-information-lighter, rgba(235, 241, 255, 1))' : '',
                  borderBottom: (slot.period !== "BREAK" && slot.period !== "PRAYER") ? '1px solid var(--state-information-lighter, rgba(235, 241, 255, 1))' : '0px',
                  // borderBottom:'0'
                  
                  height:(slot.period !== "BREAK" && slot.period !== "PRAYER") ?'':'6.65rem',
                  backgroundColor: (slot.period !== "BREAK" && slot.period !== "PRAYER") ? '' : 'rgba(235, 241, 255, 0.25)',
                  //eslint-disable-next-line
                  borderRight: '1px solid var(--state-information-lighter, rgba(235, 241, 255, 1))',
                  width:(slot.period !== "BREAK" && slot.period !== "PRAYER")?'':'5rem'

                }}
                onClick={(event) => { if (slot.period !== "BREAK" && slot.period !== "PRAYER") handleSlotClick(slot, className, event, slotIndex, classIndex, slot) }}
              >
                {slot.period !== "BREAK" && slot.period !== "PRAYER" &&  Object.keys(slotDetails).length>0 && slotDetails.assigned_teacher[classIndex][slotIndex]!==''&& slotDetails.assigned_teacher[classIndex][slotIndex]!==undefined &&  slotDetails.assigned_teacher[classIndex][slotIndex] &&slotIndex<slotDetails.assigned_teacher[0].length &&(
                  <SubjectAssignedCell
                    slot_details = {slotDetails}
                    classIndex = {classIndex}
                    slotIndex = {slotIndex}
                    classtimings={[slot.start,slot.end]}
                  />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      {isModalOpen && (
        <AssignPeriodModal
          selected_classes={timetable.selected_classes}
          slot={selectedSlot}
          onClose={() => setIsModalOpen(false)}
          position={modalPosition}
          slotIndex={slotIndex}
          classIndex={classIndex}
          slotDetails={slotDetails}
          setSlotDetails={setSlotDetails} // Pass position to modal
        />
      )}
      {isEditModalVisible && <EditPeriodTimingsModal
        onClose={handleCloseModal}
        position={modalPosition}
        slot={edittimeslot}
        handledeletebreak={handledeletebreak}
        handleedittimeslot={handleedittimeslot}
      />}
    </div>
  );
};