import React, { useContext, useEffect } from "react";
import RouteHeader from "../FeeManagementModule/components/RouteHeader";
import {useState} from "react";
import {CreateNewTimetable} from "./components/CreateNewTimetable";
import { ClasswiseComponent } from "./components/ClasswiseComponent";
import { TeacherwiseComponent } from "./components/TeacherwiseComponent";
import { HolidaysMainPage } from "./HolidaysMainPage";

import { FiPlus } from "react-icons/fi";
import { Button } from "../FeeManagementModule/components/Button";
// import { Classsectiondetailsdropdown } from "../StudentDirectoryModule/Components/Classsectiondetailsdropdown";
import UserContext from "../../context/UserContext";
import { getClassesBasedOnBranchId ,getTimetabletitlewithclasses} from "../../api/api";
import { DropdownWithSubItems } from "../../home/components/DropdownWithSubItems";
import { formatRomanNumber } from "../FeeManagementModule/FeeStructureModule/hooks";
// import { useLocation } from "react-router-dom";



export const TimetableMain = ()=>{
    const [showCreateTimetable, setShowCreateTimetable] = useState(false);
    const [dropdowndata,setdropdowndata]=useState([]);
    const [class_section_mapping_id,setclass_section_mapping_ids]=useState(
     []
    )
    // const location =useLocation();
    // const is_ = location.pathname.slice(-1); 
    // useEffect(() => {
    //     console.log(is_)
    //     if (is_ === '?') {
    //         setShowCreateTimetable(false);
    //     }
    // }, [location.pathname]);
    const { userInfo } = useContext(UserContext);
    const [class_names, setclass_names] = useState([]);

    useEffect(() => {
        const fetchClasses = async () => {
            if (userInfo.branch_id !== undefined && userInfo.branch_id !== null) {
                const res = await getClassesBasedOnBranchId(userInfo.branch_id);
                const sections = res.response.sections;
                console.log(sections);
                let grades = {};
                // eslint-disable-next-line
                sections.map(item => {
                    grades[item.class_section_mapping_id] = `${item.class} ${item.section}`;
                });
                console.log(grades);
                setclass_names(grades); // Set the class names after fetching
                const res3=await getTimetabletitlewithclasses();
                let d={};
                const Items = Object.keys(res3).reduce((acc, key) => {
                    const timetableTitle = res3[key].timetable_title; // Get the timetable title
                    const classSectionIds = res3[key].class_section_ids; // Get the class section IDs
                    acc[timetableTitle] = classSectionIds.map(id => {
                        const className = `Class ${formatRomanNumber(grades[id].split(" ")[1])}-${grades[id].split(" ")[2]}`;
                        d[className] = id; // Store the mapping
                        return className; // Return the class name
                    });
                    // 
                    return acc;
                }, {});
                console.log(d);
                setclass_section_mapping_ids(d);
                setdropdowndata(Items)
                console.log(Items);
            }
        };
        console.log(class_names)
        if(class_names.length===0)
        fetchClasses();
        //eslint-disable-next-line
    }, []);
    const handleCreateClick = ()=>{
        setShowCreateTimetable(true);
    }

    const [tabs] = useState(["Class wise","Teacher wise","Holidays"]);
    const [activeTab, setActiveTab] = useState("Class wise");

    const [routes, setRoutes] = useState([]);

    useEffect(()=>{
        if(showCreateTimetable) setRoutes([{name : "Create New schedule", link : ""}])
        else console.log("bhooya");
    },[showCreateTimetable]);
    const [id,setid]=useState(null);
    const [hastt,sethastt]=useState(false);
    const [class_name,setclass_name]=useState("Dummy");
    const getclassname=(value)=>{
        setclass_name(value);
    }
    const handledatachange=(...value)=>{
        console.log(value);
        if(value[0]==="class_section_mapping_id"){
            console.log(value[1],class_section_mapping_id[value[1]]);
            setid(class_section_mapping_id[value[1]]);
            getclassname(value[1]);
        }
        
    }
    const scheduelHandler = ({activeTab})=>{
        console.log(activeTab);
        if(activeTab === "Class wise")
            return <ClasswiseComponent onCreateClick={()=>handleCreateClick()} hastt={hastt} sethastt={sethastt} id={id} class_name={class_name}/>;
        else if(activeTab === "Teacher wise")
            return <TeacherwiseComponent onCreateClick={()=>handleCreateClick()} class_names={class_names}/>;
        else if(activeTab === "Holidays") return <HolidaysMainPage/>
    }


    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <RouteHeader
                routeHeader="Timetable"
                routes={routes}
                search
                mainroute="/timetable??"
            />
            {showCreateTimetable?
                <CreateNewTimetable setShowCreateTimetable={setShowCreateTimetable}/>
                :
                <>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'
                }}>

                
                    <div className="tabs" style={{ marginTop: "1.25rem", marginLeft: "0.6875rem"
                     }}>
                        {tabs.map((tab, index) => (
                        <div
                            key={index}
                            className={`tab ${activeTab === tab ? "active" : ""}`}
                            onClick={() => {
                            setActiveTab(tab);
                            }}
                        >
                            {tab}
                        </div>
                        ))}
                    </div>
                   {activeTab === "Class wise" && <div style={{marginTop: "1.25rem",display:'flex',gap:'0.5rem'}}>
                        {/* <CustomDropdownWithCustomStyle
                        defaultText="select a class"
                        items={["class1","class2","class3"]}
                        /> */}
                        <DropdownWithSubItems Items={dropdowndata} handledatachange={handledatachange}/>
                        {/* <Classsectiondetailsdropdown getclassname={getclassname} handledatachange={handledatachange}defaultText="Select a Class" width="8rem"/> */}
                         <Button style={{height:'2.5rem'
                         }} onClick = {()=>{handleCreateClick()}}>
                            <FiPlus/> Create a Timetable
                        </Button>
                    </div>}
                    </div>
                    {scheduelHandler({activeTab})}
                </>
            }  
           
            
        </div>
        
    );
}