import React from 'react'
import "./SubjectAssignedCell.css"
import { DecodeandEncodeDays } from '../../../home/components/DecodeandEncodeDays';

export const SubjectAssignedCell = ({slot_details={
  assigned_subject_name:[ ["Mathematics"]],
  assigned_subject_name2:[["Science"]],
  timings: ["8:30","9:30"],
  days: [],
  work_hour: [false],
  assigned_teacher: [["Name of Invigilator"]]
}, classIndex=0, slotIndex=0,classtimings=["8:00AM","9:00PM"]},twosubjects=false) => {
  // const slot_details = {
  //   assigned_subject_name: "Mathematics",
  //   timings: ["8:30","9:30"],
  //   days: [],
  //   work_hour: true,
  //   assigned_teacher: "Name of Invigilator"
  // }

  // console.log(slotIndex,slot_details);
  function formatDays(days1) {
    let days=DecodeandEncodeDays("decode",days1)
    const dayOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayIndices = days.map(day => dayOrder.indexOf(day)).sort((a, b) => a - b);

    let result = "Every ";
    let ranges = [];
    let start = dayIndices[0];
    let end = start;

    for (let i = 1; i < dayIndices.length; i++) {
        if (dayIndices[i] === end + 1) {
            end = dayIndices[i];
        } else {
            if (start === end) {
                ranges.push(dayOrder[start].slice(0, 3));
            } else {
                ranges.push(`${dayOrder[start].slice(0, 3)}-${dayOrder[end].slice(0, 3)}`);
            }
            start = dayIndices[i];
            end = start;
        }
    }

    if (start === end) {
        ranges.push(dayOrder[start].slice(0, 3));
    } else {
        ranges.push(`${dayOrder[start].slice(0, 3)}-${dayOrder[end].slice(0, 3)}`);
    }

    result += ranges.join('/');
    return result;
}
  return (
    <>
   { slot_details.assigned_subject_name2[classIndex][slotIndex]==='' && <div className='SubjectAssignedCell' style={{display:'flex',flexDirection:'column',gap:'0.7rem'
    }}>
        <div className="SubjectAssignedCellheader">
        {slot_details.assigned_subject_name[classIndex][slotIndex]}
        {slot_details.work_hour[classIndex][slotIndex] ? <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H28C29.933 0.5 31.5 2.067 31.5 4V20C31.5 21.933 29.933 23.5 28 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z" stroke="#C0D5FF"/>
        <path d="M14.32 6.31249C13.1909 5.77343 11.6128 5.50937 9.5 5.49999C9.30074 5.49729 9.10539 5.55539 8.94 5.66655C8.80425 5.75831 8.69312 5.88203 8.6164 6.02681C8.53967 6.17159 8.4997 6.33301 8.5 6.49687V15.4375C8.5 16.0419 8.93 16.4978 9.5 16.4978C11.7209 16.4978 13.9488 16.7053 15.2831 17.9666C15.3014 17.9839 15.3243 17.9955 15.3491 17.9999C15.3739 18.0043 15.3994 18.0013 15.4225 17.9913C15.4456 17.9813 15.4653 17.9648 15.4791 17.9437C15.4928 17.9226 15.5001 17.898 15.5 17.8728V7.33812C15.5 7.26704 15.4848 7.19679 15.4554 7.1321C15.426 7.06741 15.383 7.00978 15.3294 6.96312C15.0237 6.70182 14.6842 6.48296 14.32 6.31249ZM23.06 5.66562C22.8945 5.55473 22.6992 5.49695 22.5 5.49999C20.3872 5.50937 18.8091 5.77218 17.68 6.31249C17.3158 6.48265 16.9762 6.70107 16.6703 6.96187C16.6168 7.0086 16.5739 7.06625 16.5446 7.13093C16.5152 7.19561 16.5 7.26583 16.5 7.33687V17.8722C16.5 17.8964 16.5071 17.92 16.5205 17.9402C16.5339 17.9603 16.5529 17.9761 16.5752 17.9855C16.5975 17.9948 16.6221 17.9974 16.6458 17.9929C16.6696 17.9884 16.6915 17.9769 16.7088 17.96C17.5109 17.1631 18.9188 16.4969 22.5013 16.4972C22.7665 16.4972 23.0208 16.3918 23.2084 16.2043C23.3959 16.0167 23.5013 15.7624 23.5013 15.4972V6.49718C23.5016 6.333 23.4616 6.17126 23.3846 6.02623C23.3076 5.8812 23.1962 5.75735 23.06 5.66562Z" fill="#C0D5FF"/>
        </svg>:
        <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H28C29.933 0.5 31.5 2.067 31.5 4V20C31.5 21.933 29.933 23.5 28 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z" stroke="#C0D5FF"/>
        <path d="M20.6667 5.33337H11.3333C10.9797 5.33337 10.6406 5.47385 10.3905 5.7239C10.1405 5.97395 10 6.31309 10 6.66671V15.3334C10 15.687 10.1405 16.0261 10.3905 16.2762C10.6406 16.5262 10.9797 16.6667 11.3333 16.6667H14L16 18.6667L18 16.6667H20.6667C21.0203 16.6667 21.3594 16.5262 21.6095 16.2762C21.8595 16.0261 22 15.687 22 15.3334V6.66671C22 6.31309 21.8595 5.97395 21.6095 5.7239C21.3594 5.47385 21.0203 5.33337 20.6667 5.33337ZM16 7.33337C17.1513 7.33337 18 8.18137 18 9.33337C18 10.4854 17.1513 11.3334 16 11.3334C14.8493 11.3334 14 10.4854 14 9.33337C14 8.18137 14.8493 7.33337 16 7.33337ZM12.7847 14.6667C13.1567 13.518 14.4487 12.6667 16 12.6667C17.5513 12.6667 18.844 13.518 19.2153 14.6667H12.7847Z" fill="#C0D5FF"/>
        </svg>
        }
        </div>
        <div style={{display:'flex',gap:'0.5rem',alignItems:'center' }}>
        {/* svg */}
        {/* {classtimings[0]}-{classtimings[1]}
        <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2" r="2" fill="#D9D9D9"/>
        </svg> */}

       { formatDays(slot_details.days[classIndex][slotIndex])}
        </div>
        <div className='avatarrow'>
        {/* <NameAvatar
                    name={"Chaitanya"}
                    image={`https://api.dicebear.com/8.x/initials/svg?seed=${
                      'Chaitanya'
                    }`}
                    id={null}
                  /> */}
                  <img src={`https://api.dicebear.com/8.x/initials/svg?seed=${slot_details.assigned_teacher[classIndex][slotIndex]}`} alt={slot_details.assigned_teacher[classIndex][slotIndex]} class="fee-collection__avatar false" style={{width:'20px',height:'20px'}}></img>

                  {slot_details.assigned_teacher[classIndex][slotIndex]}
        </div>
       </div>}

       {slot_details.assigned_subject_name2[classIndex][slotIndex]!=='' && 
       <div style={{padding:'0.1rem'}}>
        <div className='SubjectAssignedCell' style={{display:'flex',flexDirection:'column',height:'2rem',paddingTop:'0.2rem',border: '1px solid var(--stroke-soft-200, rgba(225, 228, 234, 1))',width:'13.35rem',borderRadius:'4px 4px 0px 0px'
    }}>
        <div className="SubjectAssignedCellheader">
        {slot_details.assigned_subject_name[classIndex][slotIndex]}
        {slot_details.work_hour[classIndex][slotIndex] ? <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H28C29.933 0.5 31.5 2.067 31.5 4V20C31.5 21.933 29.933 23.5 28 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z" stroke="#C0D5FF"/>
        <path d="M14.32 6.31249C13.1909 5.77343 11.6128 5.50937 9.5 5.49999C9.30074 5.49729 9.10539 5.55539 8.94 5.66655C8.80425 5.75831 8.69312 5.88203 8.6164 6.02681C8.53967 6.17159 8.4997 6.33301 8.5 6.49687V15.4375C8.5 16.0419 8.93 16.4978 9.5 16.4978C11.7209 16.4978 13.9488 16.7053 15.2831 17.9666C15.3014 17.9839 15.3243 17.9955 15.3491 17.9999C15.3739 18.0043 15.3994 18.0013 15.4225 17.9913C15.4456 17.9813 15.4653 17.9648 15.4791 17.9437C15.4928 17.9226 15.5001 17.898 15.5 17.8728V7.33812C15.5 7.26704 15.4848 7.19679 15.4554 7.1321C15.426 7.06741 15.383 7.00978 15.3294 6.96312C15.0237 6.70182 14.6842 6.48296 14.32 6.31249ZM23.06 5.66562C22.8945 5.55473 22.6992 5.49695 22.5 5.49999C20.3872 5.50937 18.8091 5.77218 17.68 6.31249C17.3158 6.48265 16.9762 6.70107 16.6703 6.96187C16.6168 7.0086 16.5739 7.06625 16.5446 7.13093C16.5152 7.19561 16.5 7.26583 16.5 7.33687V17.8722C16.5 17.8964 16.5071 17.92 16.5205 17.9402C16.5339 17.9603 16.5529 17.9761 16.5752 17.9855C16.5975 17.9948 16.6221 17.9974 16.6458 17.9929C16.6696 17.9884 16.6915 17.9769 16.7088 17.96C17.5109 17.1631 18.9188 16.4969 22.5013 16.4972C22.7665 16.4972 23.0208 16.3918 23.2084 16.2043C23.3959 16.0167 23.5013 15.7624 23.5013 15.4972V6.49718C23.5016 6.333 23.4616 6.17126 23.3846 6.02623C23.3076 5.8812 23.1962 5.75735 23.06 5.66562Z" fill="#C0D5FF"/>
        </svg>:
        <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H28C29.933 0.5 31.5 2.067 31.5 4V20C31.5 21.933 29.933 23.5 28 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z" stroke="#C0D5FF"/>
        <path d="M20.6667 5.33337H11.3333C10.9797 5.33337 10.6406 5.47385 10.3905 5.7239C10.1405 5.97395 10 6.31309 10 6.66671V15.3334C10 15.687 10.1405 16.0261 10.3905 16.2762C10.6406 16.5262 10.9797 16.6667 11.3333 16.6667H14L16 18.6667L18 16.6667H20.6667C21.0203 16.6667 21.3594 16.5262 21.6095 16.2762C21.8595 16.0261 22 15.687 22 15.3334V6.66671C22 6.31309 21.8595 5.97395 21.6095 5.7239C21.3594 5.47385 21.0203 5.33337 20.6667 5.33337ZM16 7.33337C17.1513 7.33337 18 8.18137 18 9.33337C18 10.4854 17.1513 11.3334 16 11.3334C14.8493 11.3334 14 10.4854 14 9.33337C14 8.18137 14.8493 7.33337 16 7.33337ZM12.7847 14.6667C13.1567 13.518 14.4487 12.6667 16 12.6667C17.5513 12.6667 18.844 13.518 19.2153 14.6667H12.7847Z" fill="#C0D5FF"/>
        </svg>
        }
        </div>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center' }}>
        { formatDays(slot_details.days[classIndex][slotIndex])}
        <div className='avatarrow'>
                  <img src={`https://api.dicebear.com/8.x/initials/svg?seed=${slot_details.assigned_teacher[classIndex][slotIndex]}`} alt={slot_details.assigned_teacher[classIndex][slotIndex]} class="fee-collection__avatar false" style={{width:'20px',height:'20px'}}></img>

                  {slot_details.assigned_teacher[classIndex][slotIndex]}
        </div>
        </div>
       
       </div>
       <div className='SubjectAssignedCell' style={{display:'flex',flexDirection:'column',paddingTop:'0.2rem',gap:'0rem',height:'1.8rem',width:'13.35rem',border: '1px solid var(--stroke-soft-200, rgba(225, 228, 234, 1))',borderTop:'0',borderRadius:'0px 0px 4px 4px '
    }}>
        <div className="SubjectAssignedCellheader">
        {slot_details.assigned_subject_name2[classIndex][slotIndex]}
        {slot_details.work_hour2[classIndex][slotIndex] ? <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H28C29.933 0.5 31.5 2.067 31.5 4V20C31.5 21.933 29.933 23.5 28 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z" stroke="#C0D5FF"/>
        <path d="M14.32 6.31249C13.1909 5.77343 11.6128 5.50937 9.5 5.49999C9.30074 5.49729 9.10539 5.55539 8.94 5.66655C8.80425 5.75831 8.69312 5.88203 8.6164 6.02681C8.53967 6.17159 8.4997 6.33301 8.5 6.49687V15.4375C8.5 16.0419 8.93 16.4978 9.5 16.4978C11.7209 16.4978 13.9488 16.7053 15.2831 17.9666C15.3014 17.9839 15.3243 17.9955 15.3491 17.9999C15.3739 18.0043 15.3994 18.0013 15.4225 17.9913C15.4456 17.9813 15.4653 17.9648 15.4791 17.9437C15.4928 17.9226 15.5001 17.898 15.5 17.8728V7.33812C15.5 7.26704 15.4848 7.19679 15.4554 7.1321C15.426 7.06741 15.383 7.00978 15.3294 6.96312C15.0237 6.70182 14.6842 6.48296 14.32 6.31249ZM23.06 5.66562C22.8945 5.55473 22.6992 5.49695 22.5 5.49999C20.3872 5.50937 18.8091 5.77218 17.68 6.31249C17.3158 6.48265 16.9762 6.70107 16.6703 6.96187C16.6168 7.0086 16.5739 7.06625 16.5446 7.13093C16.5152 7.19561 16.5 7.26583 16.5 7.33687V17.8722C16.5 17.8964 16.5071 17.92 16.5205 17.9402C16.5339 17.9603 16.5529 17.9761 16.5752 17.9855C16.5975 17.9948 16.6221 17.9974 16.6458 17.9929C16.6696 17.9884 16.6915 17.9769 16.7088 17.96C17.5109 17.1631 18.9188 16.4969 22.5013 16.4972C22.7665 16.4972 23.0208 16.3918 23.2084 16.2043C23.3959 16.0167 23.5013 15.7624 23.5013 15.4972V6.49718C23.5016 6.333 23.4616 6.17126 23.3846 6.02623C23.3076 5.8812 23.1962 5.75735 23.06 5.66562Z" fill="#C0D5FF"/>
        </svg>:
        <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H28C29.933 0.5 31.5 2.067 31.5 4V20C31.5 21.933 29.933 23.5 28 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z" stroke="#C0D5FF"/>
        <path d="M20.6667 5.33337H11.3333C10.9797 5.33337 10.6406 5.47385 10.3905 5.7239C10.1405 5.97395 10 6.31309 10 6.66671V15.3334C10 15.687 10.1405 16.0261 10.3905 16.2762C10.6406 16.5262 10.9797 16.6667 11.3333 16.6667H14L16 18.6667L18 16.6667H20.6667C21.0203 16.6667 21.3594 16.5262 21.6095 16.2762C21.8595 16.0261 22 15.687 22 15.3334V6.66671C22 6.31309 21.8595 5.97395 21.6095 5.7239C21.3594 5.47385 21.0203 5.33337 20.6667 5.33337ZM16 7.33337C17.1513 7.33337 18 8.18137 18 9.33337C18 10.4854 17.1513 11.3334 16 11.3334C14.8493 11.3334 14 10.4854 14 9.33337C14 8.18137 14.8493 7.33337 16 7.33337ZM12.7847 14.6667C13.1567 13.518 14.4487 12.6667 16 12.6667C17.5513 12.6667 18.844 13.518 19.2153 14.6667H12.7847Z" fill="#C0D5FF"/>
        </svg>
        }
        </div>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center' }}>
        { formatDays(slot_details.days2[classIndex][slotIndex])}
        <div className='avatarrow'>
                  <img src={`https://api.dicebear.com/8.x/initials/svg?seed=${slot_details.assigned_teacher2[classIndex][slotIndex]}`} alt={slot_details.assigned_teacher2[classIndex][slotIndex]} class="fee-collection__avatar false" style={{width:'20px',height:'20px'}}></img>

                  {slot_details.assigned_teacher2[classIndex][slotIndex]}
        </div>
        </div>
       
       </div>
       </div>
      
       }
        </>
  )
}
