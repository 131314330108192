import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineCalendar } from "react-icons/ai";
import { DatePickerWrapper } from "../../modules/FeeManagementModule/FeeStructureModule/components/AddFeeShedule";

export const MultiDateSelector = ({ width, handledatachange = () => {}, field, value, data = [] }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (endDate) {
      setIsOpen(false);
    }
    console.log(startDate,endDate);
  }, [startDate,endDate]);

  useEffect(() => {
    if (data.holiday_from_date) {
      const [start, end] = [data.holiday_from_date, data.holiday_to_date];
      const [startYear, startMonth, startDay] = start.split("-");
      const [endYear, endMonth, endDay] = end.split("-");
      setEndDate(new Date(`${endYear}-${endMonth}-${endDay}`));
      setStartDate(new Date(`${startYear}-${startMonth}-${startDay}`));
    }
  }, [data]);

  const dateformattodaymonthyear = (start, end) => {
    const format = (date) => {
      let day = String(date.getDate()).padStart(2, "0");
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };
    handledatachange(field, [format(start), format(end)]);
  };

  const dayClassName = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to midnight for accurate comparison
  
    if (startDate && endDate) {
      const resetTime = (d) => {
        const dateCopy = new Date(d);
        dateCopy.setHours(0, 0, 0, 0);
        return dateCopy;
      };
    
      if (resetTime(date).getTime() === resetTime(endDate).getTime()) {
        return "react-datepicker__day--selected"; // Assign both classes
      }
      if (date >= startDate && date <= endDate && date.getTime() !== today.getTime()) {
        return "selected-range";
      }
    }
    return "";
  };

  const customStyles = `
    .selected-range {
      background-color: rgba(245, 247, 250, 1) !important;
      color: #fff !important;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      background-color: #007bff !important;
    }
    .end-date {
      background-color: #ff0000 !important; /* Corrected color for end date */
      color: #fff !important;
    }
  `;
// ... existing code ...

  return (
    <div className="DateDropdown" style={{ display: "flex", alignItems: "center" }}>
      <style>{customStyles}</style>
      <DatePickerWrapper>
        <DatePicker
          selected={startDate}
          onChange={(dates) => {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
            if (start && end) {
              dateformattodaymonthyear(start, end);
              setIsOpen(false);
            }
          }}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          customInput={<CustomInput width={width} setIsOpen={setIsOpen} />}
          open={isOpen}
          dayClassName={dayClassName}
        />
      </DatePickerWrapper>
    </div>
  );
};

const CustomInput = React.forwardRef(
  ({ value = "Select a date range", onClick, width, setIsOpen }, ref) => (
    <div
      onClick={() => { setIsOpen(true); }}
      style={{ width: width }}
      ref={ref}
      className="inputbox"
    >
      <span style={{ color: "rgba(153, 160, 174, 1)" }}>
        {value || "Select a date range"}
      </span>
      <AiOutlineCalendar
        style={{ marginLeft: "10px", color: "#9EA1B0" }}
        size={"1.3rem"}
      />
    </div>
  )
);