import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { CustomDropdownWithCustomStyle } from "../../StudentDirectoryModule/Components/CustomDropdownWithCustomStyle";
// import { Classsectiondetailsdropdown } from "../../StudentDirectoryModule/Components/Classsectiondetailsdropdown";
import { getClassesBasedOnBranchId } from "../../../api/api";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./TimeRangeSelector.css";
import UserContext from "../../../context/UserContext";
import { CheckBoxDropDown } from "../../../home/components/CheckBoxDropdown/CheckBoxDropDown";
import { DecodeandEncodeDays } from "../../../home/components/DecodeandEncodeDays";
// import { AssignPeriodModal } from "./AssignPeriodModal";
// import { EditPeriodTimingsModal } from "./EditPeriodTimingsModal";
// import { matchPath } from "react-router-dom";

export const SetupTimings = ({ findmandatorychecks, editdata, setEditdata }) => {
  // const [editdata, setEditdata] = useState({
  console.log(editdata);
    const { userInfo } = useContext(UserContext);
  //   timetable_title: "",
  //   select_classes: "",
  //   class_section: "",
  //   select_academic_programme: "",
  //   choose_program: "",
  //   range: [8,16],
  //   working_hours: "",
  //   working_days: [],
  //   number_of_periods: "",
  //   periods_duration: "",
  //   number_of_breaks: "",
  //   breaks_duration: "",
  //   is_prayer_enabled: false,
  //   prayer_duration: "",
  // });
  const [classes,setclasses]=useState(editdata.classes_available);
  const [selectedclasses,setselectedclasses] =useState(editdata.selected_classes);
  const [classsectionmappingIds, setclasssectionMappingIds] = useState(editdata.class_section_mappings_available);
  // const [selectedclasssectionmappingIds, setselectedclasssectionMappingIds] = useState([]);
  const containerRef = useRef(null);
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  useEffect(() => {
    if (userInfo.branch_id !== undefined && userInfo.branch_id !== null && editdata.classes_available.length===0) {
      getClassesBasedOnBranchId(userInfo.branch_id).then((res) => {
        const sections = res.response.sections;
        console.log(sections);
        let grades = [];
        sections.reduce((acc, { class: className, section }) => {
          if (!acc[className]) {
            acc[className] = [];
          }
          // acc[className].push(`${className}-${section}`);
          grades.push(`${className}-${section}`);
          return acc;
        }, {});

        const ids = sections.map(
          ({ class_section_mapping_id }) => class_section_mapping_id
        );
        // const classAndSectionIdTogether = sections.map(
        //   ({ class_id, section_id }) => ({ class_id, section_id })
        // );

        // setclassidandsectionid(classAndSectionIdTogether);
        
        setclasses(grades);
        setEditdata({ ...editdata, classes_available: grades,class_section_mappings_available:ids });
        setclasssectionMappingIds(ids);
      
        // const url = new URL(window.location.href);
        // url.searchParams.set("grades", "all");
        // navigate(url.pathname + url.search);
      });
    }
    //eslint-disable-next-line
  }, [userInfo.branch_id]);
  useEffect(() => {
    findmandatorychecks(editdata);
    //eslint-disable-next-line
  }, [editdata])
  
  const handledatachange = (field, value) => {
    console.log(field, value);

    if (field === "classes") {
      console.log(value);
      let temp=[];
      let temp2=[]
      for(let i=0;i<value.length;i++){
        if(value[i]){
          temp.push(classes[i]);
          temp2.push(classsectionmappingIds[i])
        }
      }
      setselectedclasses(temp);
      // setselectedclasssectionMappingIds(temp2);
      console.log(temp2);
      const updatedData = { ...editdata, selected_classes: temp,class_section_mapping_ids:temp2 };
      console.log(updatedData);
      setEditdata(updatedData);
    }
     else {
      const updatedData = { ...editdata, [field]: value };
      setEditdata(updatedData);
      // sessionStorage.setItem("studentInfo", JSON.stringify(updatedData));
      // console.log(updatedData);
    }
    // findmandatorychecks(editdata);
    // console.log(await findmandatorychecks())
  };

  const [range, setRange] = useState([editdata.range[0], editdata.range[1]]); // Initial values in hours (8 AM to 4 PM)
  const handleChange = (newRange) => {
    setRange(newRange);
    const updatedData = { ...editdata, "range": newRange, "working_hours": newRange[1]-newRange[0]};
    setEditdata(updatedData);
  };

  const [selectedDays, setSelectedDays] = useState(editdata.working_days);
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedDays;
  
    if (checked) {
      // Add the day to the selectedDays array
      updatedDays = [...selectedDays, value];
    } else {
      // Remove the day from the selectedDays array
      updatedDays = selectedDays.filter((day) => day !== value);
    }
  
    setSelectedDays(updatedDays);
    const week_working_days= DecodeandEncodeDays("encode",updatedDays);
    console.log(week_working_days);
    const updatedData = { ...editdata, "working_days": updatedDays ,"week_working_days":week_working_days};
    setEditdata(updatedData);
  };

  const formatTime = (hour) => {
    console.log(hour);
    const ampm = hour >= 12 ? "PM" : "AM";
    const displayHour = Math.floor(hour) % 12 || 12;
    const minutes = (hour % 1) * 60;
    const displayMinutes = minutes === 0 ? "00" : minutes;
    return `${displayHour}:${displayMinutes} ${ampm}`;
  };

  const periodOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; // Example options
  const breakOptions = [0, 1, 2, 3, 4, 5]; // Example options
  const periodDurationOptions = [
    "30 mins",
    "45 mins",
    "1 hour",
    "1 hour 15 mins",
    "1 hour 30 mins",
    "2 hour",
  ];
  const breakDurationOptions = [
    "5 mins", "10 mins", "15 mins", "20 mins", "30 mins", 
    "45 mins", "1 hour", "1 hour 15 mins", "1 hour 30 mins", 
    "1 hour 45 mins", "2 hour"
  ];
  // const buttonStyle = {
  //     backgroundColor: 'white',
  //     border: '1px solid #ccc',
  //     borderRadius: '4px',
  //     padding: '5px 10px',
  //     marginRight: '10px',
  //     cursor: 'pointer'
  //   };
  const fromToRef = useRef(null);
  const toFromRef = useRef(null);
  
  useEffect(() => {
    const updateHandlePositions = () => {
      const fromHandle = document.querySelector('.rc-slider-handle-1');
      const toHandle = document.querySelector('.rc-slider-handle-2');
      const container = fromToRef.current?.parentElement;
    
      if (fromHandle && container) {
        const fromHandleRect = fromHandle.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        console.log(`From Handle position - Top: ${fromHandleRect.top}, Left: ${fromHandleRect.left}`);
        fromToRef.current.style.left = `${fromHandleRect.left - containerRect.left+18}px`;
      }
    
      if (toHandle && container) {
        const toHandleRect = toHandle.getBoundingClientRect();
        const containerRect = containerRef.current?.getBoundingClientRect();
        console.log(`To Handle position - Top: ${toHandleRect.top}, Left: ${toHandleRect.left}`);
        toFromRef.current.style.left = `${toHandleRect.left - containerRect.left}px`;
      }
    };
  
    updateHandlePositions(); // Initial call to set positions
  
    window.addEventListener('resize', updateHandlePositions); // Update positions on resize
  
    return () => {
      window.removeEventListener('resize', updateHandlePositions); // Cleanup on unmount
    };
  }, [range]);
  
  return (
    <div ref={containerRef}>
      <div>
        <div className="detailsHeader">Setup Timings</div>
        <div className="detailsHeaderspan">Enter the details to proceed </div>
      </div>
      <div
        className="StudentInfoEditSilderdivbody"
        style={{ paddingBottom: "17rem" }}
      >
        <div className="Details">
          <label style={{ display: "flex" }}>
            Timetable Title
            <div
              className="mandatory"
              style={{ color: "rgba(251, 55, 72, 1)" }}
            >
              *
            </div>
          </label>
          <input
            type="text"
            className="inputbox"
            value={editdata.timetable_title}
            style={{ width: "28.5rem" }}
            placeholder="Ex: AY 24-25 Class 6-10"
            onChange={(event) => {
              handledatachange("timetable_title", event.target.value);
            }}
          />
        </div>
  
        <div className="Details">
          <label style={{ display: "flex" }}>
            Select Classes
            <div
              className="mandatory"
              style={{ color: "rgba(251, 55, 72, 1)" }}
            >
              *
            </div>
          </label>
          {/* <Classsectiondetailsdropdown
            defaultText={
              editdata.class_section === undefined ||
              editdata.class_section === null ||
              editdata.class_section === ""
                ? "Choose classes"
                : editdata.class_section.slice(
                    1,
                    editdata.class_section.length - 1
                  )
            }
            handledatachange={handledatachange}
            // field={}
            width="28.5rem"
          /> */}

          <CheckBoxDropDown selecteditems={selectedclasses} showselected={true}defaultname= "Choose Classes" Items={classes}  handledatachange={handledatachange} subitems={false} width="30rem"  dropdownname="classes"/>
        </div>
  
        <div className="Details">
          <label style={{ display: "flex" }}>
            Select Academic Programme
            <div
              className="mandatory"
              style={{ color: "rgba(251, 55, 72, 1)" }}
            >
              *
            </div>
          </label>
          {/* <Classsectiondetailsdropdown
            defaultText={
              editdata.choose_program === undefined ||
              editdata.choose_program === null ||
              editdata.choose_program === ""
                ? "Choose a programme"
                : editdata.choose_program.slice(
                    1,
                    editdata.choose_program.length - 1
                  )
            }
            handledatachange={handledatachange}
            // field={}
            width="28.5rem"
          /> */}
          <CustomDropdownWithCustomStyle  handledatachange={handledatachange} dropdownName="select_academic_programme" defaultText={editdata.select_academic_programme!==''?editdata.select_academic_programme:"Select Academic Programme"} items={["Telangana State Board"]} width={"28.5rem"} dropdownwidth={"28.5"} scrollenable={true}/>
        </div>
  
        <div
          style={{
            display: "flex",
            gap: "1rem",
            height: "228px",
            width: "472px",
            paddingBottom: "10rem"
          }}
        >
          <div
            className="Details"
            style={{
              // backgroundColor: "black",
              width: "33rem",
              height: "14.25",
              position: "relative", // Add this line
            }}
          >
            <label style={{ display: "flex",marginBottom:'2.8rem' }}>
              Select School working hours{" "}
              <div
                className="mandatory"
                style={{ color: "rgba(251, 55, 72, 1)" }}
              >
                *
              </div>
            </label>
        
            <div className="fromto" style={{ position: 'absolute', top: '20px', left: '-1000px'}} ref={fromToRef}>
              From
            </div>
         
            <div className="tofrom" style={{ position: 'absolute', top: '20px', right: '0px'  }} ref={toFromRef}>
              To
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                width:"466px"
              }}
            >
              {/* <button style={buttonStyle}>From</button> */}
              <div style={{ marginTop: "7rem" }}></div>
              <Slider
                range
                min={8}
                max={20}
                value={range}
                onChange={handleChange}
                marks={{
                  8: "8 AM",
                  10: "10 AM",
                  12: "12 PM",
                  14: "2 PM",
                  16: "4 PM",
                  18: "6 PM",
                  20: "8PM",
                }}
                step={0.25}
                styles={{
                  marginTop: "10rem",
                  tract: { backgroundColor: "lightblue", height: "20px" },
                  handle: {
                    backgroundColor: "blue",
                    borderColor: "blue",
                    height: "60px",
                    width: "2px",
                    marginTop: "-52px",
                  },
                }}
                
              />
              {/* <button style={buttonStyle}>To</button> */}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1px",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#1F2937" }}>
                {formatTime(range[0])}
              </span>
              <div
                style={{
                  flex: 1,
                  height: "2px",
                  backgroundColor: "#E5E9F2",
                  margin: "0 10px",
                }}
              ></div>
              <span
                style={{
                  width: "97px",
                  height: "26px",
                  alignContent: "center",
                  textAlign: "center",
                  backgroundColor: "#E5E9F2",
                  borderRadius: "12px",
                  color: "#6B7280",
                  fontSize: "12px",
                }}
              >
                {range[1] - range[0]} hours total
              </span>
              <div
                style={{
                  flex: 1,
                  height: "2px",
                  backgroundColor: "#E5E9F2",
                  margin: "0 10px",
                }}
              ></div>
              <span style={{ fontWeight: "bold", color: "#1F2937" }}>
                {formatTime(range[1])}
              </span>
            </div>
            <div className="Details" style={{ marginTop: "20px" }}>
              <label style={{ display: "flex", alignItems: "center" }}>
                Select working days
                <div
                  className="mandatory"
                  style={{ color: "rgba(251, 55, 72, 1)", marginLeft: "4px" }}
                >
                  *
                </div>
              </label>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {daysOfWeek.map((day, index) => (
                  <div
                    key={day}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "108px",
                      marginBottom: "10px",
                      marginRight: index % 4 === 3 ? "0px" : "16px", // Add margin except after the fourth element
                      gap:'0.25rem',
                      // alignText:'center'
                    }}
                  >
                    <input
                      type="checkbox"
                      id={day}
                      name={day}
                      value={day}
                      checked={editdata.working_days.includes(day)}
                      onChange={(event)=>{handleCheckboxChange(event)}}
                      // style={{ marginRight: "8px" }}
                    />
                    <label
                      htmlFor={day}
                      style={{
                        fontSize: "14px",
                        color: "#2C2C2C",
                      }}
                    >
                      {day}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="Details" style={{ marginTop: "20px" }}>
              <label style={{ display: "flex", alignItems: "center" }}>
                Number of Periods & Duration
                <div
                  className="mandatory"
                  style={{ color: "rgba(251, 55, 72, 1)", marginLeft: "4px" }}
                >
                  *
                </div>
              </label>
              <div style={{ display: "flex", gap: "0" }}>
                {/* <select
                  value={editdata.number_of_periods}
                  onChange={(e) =>
                    handledatachange("number_of_periods", e.target.value)
                  }
                  style={{
                    width: "7rem",
                    padding: "0.5rem",
                    paddingRight: "1.5rem",
                    marginRight: "-0.5rem",
                    appearance: "none",
                    backgroundImage:
                      "url(\"data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e\")",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right 0.5rem center",
                    backgroundSize: "1rem",
                    outline: "none",
                  }}
                >
                  <option value="">Periods</option>
                  {periodOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select> */}
                 <CustomDropdownWithCustomStyle
                defaultText={editdata.number_of_periods!==''?editdata.number_of_periods:'periods'}
                // icon={<FilterIcon />}
                // handledatachange={handledatachange}
                items={periodOptions}
                width={"5rem"}
                dropdownName={"number_of_periods"}
                handledatachange={handledatachange}
                scrollenable={true}
                customStyle={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                   borderRight: 'none'
                }}
              />
               <CustomDropdownWithCustomStyle
               
                defaultText={editdata.periods_duration!==''?editdata.periods_duration:'Ex. 1 hour'}
                // icon={<FilterIcon />}
                // handledatachange={handledatachange}
                items={periodDurationOptions}
                width={"22rem"}
                dropdownName={"periods_duration"}
                handledatachange={handledatachange}
                scrollenable={true}
                customStyle={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              />
               
              </div>
            </div>
            <div className="Details" style={{ marginTop: "1.25rem" }}>
              <label style={{ display: "flex", alignItems: "center" }}>
                Number of Breaks & Total Duration
                <div
                  className="mandatory"
                  style={{ color: "rgba(251, 55, 72, 1)", marginLeft: "4px" }}
                >
                  *
                </div>
              </label>
              <div style={{ display: "flex", gap: "0" }}>
                <CustomDropdownWithCustomStyle
                defaultText={editdata.number_of_breaks!==''?editdata.number_of_breaks:'breaks'}
                // icon={<FilterIcon />}
                // handledatachange={handledatachange}
                items={breakOptions}
                width={"5rem"}
                dropdownName={"number_of_breaks"}
                handledatachange={handledatachange}
                scrollenable={true}
                customStyle={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                   borderRight: 'none'
                }}
              />
               <CustomDropdownWithCustomStyle
               
                defaultText={editdata.breaks_duration!==''?editdata.breaks_duration:'Ex. 1 hour'}
                // icon={<FilterIcon />}
                // handledatachange={handledatachange}
                items={breakDurationOptions}
                width={"22rem"}
                dropdownName={"breaks_duration"}
                handledatachange={handledatachange}
                scrollenable={true}
                customStyle={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              />
                
              </div>
            </div>
            <div className="Details" style={{ marginTop: "1.25rem"}}>
              <div style={{display:'flex',gap:'0.8rem',alignItems:'center'}}>

              <input 
                type="checkbox" 
                name="Enable Prayer Timings" 
                id="" 
                checked={editdata.is_prayer_enabled} 
                onClick={() => handledatachange("is_prayer_enabled", !editdata.is_prayer_enabled)} 
              />
              Enable Prayer Timings
              </div>
            </div>
           {editdata.is_prayer_enabled && <div className="Details" style={{ marginTop: "1.25rem",paddingBottom:'10rem'  }}>
            <label style={{ display: "flex" }}>
            Duration of Prayer
            <div
              className="mandatory"
              style={{ color: "rgba(251, 55, 72, 1)" }}
            >
              *
            </div>
          </label>
            <CustomDropdownWithCustomStyle

                defaultText={editdata.prayer_duration!==''?editdata.prayer_duration:'Ex. 1 Hour 30 mins'}
                // icon={<FilterIcon />}
                // handledatachange={handledatachange}
                items={periodDurationOptions}
                width={"29rem"}
                dropdownName={"prayer_duration"}
                scrollenable={true}
                handledatachange={handledatachange}
              />
            </div>}
           {/* <EditPeriodTimingsModal/>
          <AssignPeriodModal/> */}
          </div>
        </div>
      </div>
    </div>
  );
};
