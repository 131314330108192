import React from "react";
import RouteHeader from "../../FeeManagementModule/components/RouteHeader";
import FeeHeader from "../../FeeManagementModule/components/FeeHeader";
import {
  Button,
  BorderButton,
  PrimaryButton,
} from "../../FeeManagementModule/components/Button";
import { FiEdit3 } from "react-icons/fi";
import "./Classroom.css";
import {
  GrayBox,
  TextBox,
} from "../../../auth/SetupAdminPage/components/SetupComponents";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { getAllSchoolStructures } from "../../../api/api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { NavLink } from "react-router-dom";
import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetFooter,
  SheetHeader,
} from "./components/Sheet";
// import { TbEdit } from "react-icons/tb";
import ToggleButton from "../../../home/ToggleButton";
import { formatRomanNumber } from "../../FeeManagementModule/FeeStructureModule/hooks";
import { MdKeyboardArrowDown } from "react-icons/md";
import { RiDeleteBin6Line, RiUser6Line } from "react-icons/ri";
import UserContext from "../../../context/UserContext";

const Classroom = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [classes, setClasses] = React.useState([]);
  const [expandedRow, setExpandedRow] = React.useState(null);
  const { userInfo } = React.useContext(UserContext);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (
        userInfo.branch_id !== null ||
        userInfo.branch_id !== undefined ||
        userInfo.branch_id !== ""
      ) {
        try {
          const res = await getAllSchoolStructures(userInfo.branch_id);
          setClasses(res.response.results);
        } catch (error) {
          console.error("Error fetching academic details", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [userInfo.branch_id]);

  const handleRowClick = (classId) => {
    setExpandedRow(expandedRow === classId ? null : classId);
  };

  const images = [
    {
      id: 1,
      image: "https://api.dicebear.com/9.x/initials/svg?seed=Shishiro",
    },
    {
      id: 2,
      image: "https://api.dicebear.com/9.x/initials/svg?seed=sathvik",
    },
    {
      id: 3,
      image: "https://api.dicebear.com/9.x/initials/svg?seed=Chaitanya",
    },
  ];
 let sheethandlers;
 const getsheethandlers=(handler)=>{
  sheethandlers=handler;
 }
  return (
    <>
      <RouteHeader routeHeader="School Directory" routes={[]} search />
      <FeeHeader
        text="Classrooms"
        subText="Manage your classrooms"
        button1={""}
        button2={
          <Sheet
            trigger={
              <Button>
                <FiEdit3 /> Edit Structure
              </Button>
            }
            getsheethandlers={getsheethandlers}
            width="45%"
          >
            <SheetHeader onClose={sheethandlers}>Edit School Structure</SheetHeader>
            <SheetBody>
              <SheetContent
                style={{
                  overflowX: "hidden",
                }}
              >
                <div className="edit-school-structure__header">
                  <p>SCHOOL STRUCTURE</p>
                  <div>
                    <p
                      style={{
                        fontSize: "1.125rem",
                        color: "var(--black-color)",
                        fontWeight: "500",
                        marginTop: "0.3rem",
                      }}
                    >
                      Higher Secondary School
                    </p>
                    <GrayBox text={"Class VI to X"} />
                  </div>
                </div>
                <div className="edit-school-structure__body">
                  <div className="table-container" style={{ margin: "1rem 0" }}>
                    <div className="table-container-header">
                      <div
                        className="header-cell-container"
                        style={{ flex: "2" }}
                      >
                        Class
                      </div>
                      <div
                        className="header-cell-container"
                        style={{ flex: "1.5" }}
                      >
                        Sections
                      </div>
                      <div className="header-cell-container">Actions</div>
                    </div>
                    <div className="table-container-body">
                      {classes?.map((classItem) => (
                        <React.Fragment key={classItem.class_id}>
                          <div
                            className="row-container"
                            onClick={() => handleRowClick(classItem.class_id)}
                            style={{
                              borderBottom:
                                expandedRow === classItem.class_id
                                  ? "none"
                                  : "1px solid #ddd",
                            }}
                          >
                            <div
                              className="cell-container"
                              style={{ flex: "2" }}
                            >
                              <MdKeyboardArrowDown size={"1.25rem"} />
                              Class{" "}
                              {formatRomanNumber(
                                classItem.class_name.split(" ")[1]
                              )}
                            </div>
                            <div
                              className="cell-container"
                              style={{ flex: "1.5" }}
                            >
                              <TextBox
                                text={`${classItem.sections.length} sections`}
                              />
                            </div>
                            <div className="cell-container">
                              <RiDeleteBin6Line
                                color={"var(--error-color)"}
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                          {expandedRow === classItem.class_id && (
                            <>
                              {classItem.sections?.map((sectionItem, index) => (
                                <div
                                  key={sectionItem.class_section_id}
                                  className="row-container"
                                  style={{
                                    borderBottom:
                                      index === classItem.sections.length - 1
                                        ? "1px solid var(--gray-color-200)"
                                        : "none",
                                  }}
                                >
                                  <div className="cell-container">
                                    Class{" "}
                                    {formatRomanNumber(
                                      classItem.class_name.split(" ")[1]
                                    )}{" "}
                                    - {sectionItem.section_name}
                                  </div>
                                  <div
                                    className="cell-container"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "0.3rem",
                                      paddingRight: "5rem",
                                    }}
                                  >
                                    <RiUser6Line />
                                    <span>
                                      {sectionItem.no_of_students} students
                                    </span>
                                  </div>
                                  <div className="cell-container"></div>
                                </div>
                              ))}
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  {/* <BorderButton>+ Add a Class</BorderButton> */}
                </div>
              </SheetContent>
            </SheetBody>
            <SheetFooter
              style={{
                display: "flex",
                gap: "0.5rem",
              }}
            >
              <PrimaryButton
                style={{
                  width: "50%",
                }}
                onClick={()=>{ sheethandlers.off()}}
              >
                Back
              </PrimaryButton>
              <Button
                style={{
                  width: "50%",
                }}
              >
                Save Changes
              </Button>
            </SheetFooter>
          </Sheet>
        }
      />

      <section className="classroom" style={{display:'flex',gap:'2.5rem',flexDirection:'column'}}>
        {isLoading ? (
          <div>
            <Skeleton height={20} width={200} count={1} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                gap: "8px",
              }}
            >
              <div style={{ flex: "1 1 calc(32%)" }}>
                <Skeleton height={150} width="100%" />
              </div>
              <div style={{ flex: "1 1 calc(32%)" }}>
                <Skeleton height={150} width="100%" />
              </div>
              <div style={{ flex: "1 1 calc(32%)" }}>
                <Skeleton height={150} width="100%" />
              </div>
            </div>
            <Skeleton
              height={20}
              width={200}
              count={1}
              style={{
                marginTop: "30px",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                gap: "8px",
              }}
            >
              <div style={{ flex: "1 1 calc(32%)" }}>
                <Skeleton height={150} width="100%" />
              </div>
              <div style={{ flex: "1 1 calc(32% )" }}>
                <Skeleton height={150} width="100%" />
              </div>
              <div style={{ flex: "1 1 calc(32% )" }}>
                <Skeleton height={150} width="100%" />
              </div>
            </div>
            <Skeleton
              height={20}
              width={200}
              count={1}
              style={{
                marginTop: "30px",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                gap: "8px",
              }}
            >
              <div style={{ flex: "1 1 calc(32%)" }}>
                <Skeleton height={150} width="100%" />
              </div>
              <div style={{ flex: "1 1 calc(32% )" }}>
                <Skeleton height={150} width="100%" />
              </div>
              <div style={{ flex: "1 1 calc(32% )" }}>
                <Skeleton height={150} width="100%" />
              </div>
            </div>
          </div>
        ) : (
          classes?.map((classItem) => (
            <React.Fragment key={classItem.class_id} >
              <div >
              <div className="classroom__header--container" style={{justifyContent:'space-between'}}>
                <div className="classroom__header">
                  <p className="classroom__heading">
                    Class{" "}
                    {formatRomanNumber(classItem.class_name.split(" ")[1])}
                  </p>
                  <TextBox text={`${classItem.total_student_count} students`} />
                  <TextBox
                    text={`${classItem.sections_completed}/${classItem.total_sections} sections incomplete`}
                    icon={<BsExclamationTriangleFill color="#FF8447" />}
                  />
                </div>
                <Sheet
                  trigger={
                    <FiEdit3 color={"var(--blue-color)"} size={"1rem"} />
                  }
                  getsheethandlers={getsheethandlers}
                  width="40%"
                >
                  <SheetHeader>Edit School Structure</SheetHeader>
                  <SheetBody>
                    <SheetContent>
                      <div className="edit-section-structure__header">
                        <p>Higher Secondary School</p>
                        <GrayBox text={"Class VI to X"} />
                      </div>
                      <div
                        className="table-container"
                        style={{
                          marginBottom: "1rem",
                        }}
                      >
                        <div className="table-container-header">
                          <div
                            className="header-cell-container"
                            style={{ flex: "2" }}
                          >
                            Section
                          </div>
                          <div
                            className="header-cell-container"
                            style={{ flex: "1.5" }}
                          >
                            No. of Students
                          </div>
                          <div className="header-cell-container">Actions</div>
                        </div>
                        <div className="table-container-body">
                          {classItem.sections?.map((sectionItem) => (
                            <div
                              key={sectionItem.class_section_id}
                              className="row-container"
                              style={{
                                borderBottom: "1px solid #ddd",
                              }}
                            >
                              <div
                                className="cell-container"
                                style={{ flex: "2" }}
                              >
                                {formatRomanNumber(
                                  classItem.class_name.split(" ")[1]
                                )}
                                -{sectionItem.section_name}
                              </div>
                              <div
                                className="cell-container"
                                style={{
                                  flex: "1.5",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "0.3rem",
                                }}
                              >
                                <RiUser6Line />
                                <span>
                                  {sectionItem.no_of_students} students
                                </span>
                              </div>
                              <div className="cell-container">
                                <ToggleButton checked={false} />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <BorderButton
                        style={{
                          width: "fit-content",
                        }}
                      >
                        + Add a section
                      </BorderButton>
                    </SheetContent>
                  </SheetBody>
                  <SheetFooter
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                    }}
                  >
                    <PrimaryButton
                      style={{
                        width: "50%",
                      }}
                      onClick={()=>{ sheethandlers.off()}}
                    >
                      Back
                    </PrimaryButton>
                    <Button
                      style={{
                        width: "50%",
                      }}
                    >
                      Save Changes
                    </Button>
                  </SheetFooter>
                </Sheet>{" "}
              </div>
              <div className="classroom__classes">
                {classItem.sections?.map((sectionItem) => (
                  <div
                    key={sectionItem.class_section_id}
                    className="classroom__body"
                  >
                    <div className="classroom__section">
                      <p className="classroom__section-heading">
                        <span>
                          {formatRomanNumber(
                                  classItem.class_name.split(" ")[1]
                                )} - {sectionItem.section_name}
                        </span>
                        <NavLink
                          to={`/directory/school/${sectionItem.class_section_mapping_id}`}
                          style={{marginTop:'-0.5rem'}}
                        >
                          Setup
                          <IoIosArrowForward />
                        </NavLink>
                      </p>
                      <div className="classroom__section-body">
                        <div className="classroom__section-info">
                          {sectionItem.academic_programme_name &&
                          sectionItem.academic_programme_name !== "" ? (
                            <GrayBox
                              text={sectionItem.academic_programme_name}
                            />
                          ) : (
                            <GrayBox text={"Programme not assigned"} />
                          )}
                        </div>
                      </div>
                      <div className="classroom__section-footer">
                        {sectionItem.no_of_students &&
                        sectionItem.no_of_students !== "" ? (
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.2rem",
                            }}
                          >
                            {images?.map((image) => {
                              return (
                                <img
                                  key={image.id}
                                  src={image.image}
                                  alt={sectionItem.class_teacher_name}
                                  className="classroom__student-logo classroom__student-logo--image"
                                />
                              );
                            })}
                            +{sectionItem.no_of_students} Students
                          </p>
                        ) : (
                          <p>No students</p>
                        )}
                        {sectionItem.class_teacher_name &&
                        sectionItem.class_teacher_name !== "" ? (
                          <p className="teacher__image--box">
                            <img
                              src={`https://api.dicebear.com/8.x/initials/svg?seed=${sectionItem.class_teacher_name}`}
                              alt={sectionItem.class_teacher_name}
                              className="classroom__student-logo"
                            />
                            {sectionItem.class_teacher_name}
                          </p>
                        ) : (
                          <p>Teacher not assigned</p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              </div>
            </React.Fragment>
          ))
        )}
      </section>
    </>
  );
};

export default Classroom;
