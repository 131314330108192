import React from 'react'

export const ProceedPopupInTT = ({
    setpopup=()=>{},
    setprogress,
    senddata
  }) => {
 
  
    return (
      <div className="DeletePopup">
        <div className="DeletePopupContent">
          <div className="DeletePopupHeader">
            <div className="modal-icon">
            <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 10C0.5 4.47715 4.97715 0 10.5 0H30.5C36.0228 0 40.5 4.47715 40.5 10V30C40.5 35.5228 36.0228 40 30.5 40H10.5C4.97715 40 0.5 35.5228 0.5 30V10Z" fill="#EBF1FF"/>
<path d="M20.5 29C15.5293 29 11.5 24.9707 11.5 20C11.5 15.0293 15.5293 11 20.5 11C25.4707 11 29.5 15.0293 29.5 20C29.5 24.9707 25.4707 29 20.5 29ZM19.6 19.1V24.5H21.4V19.1H19.6ZM19.6 15.5V17.3H21.4V15.5H19.6Z" fill="#335CFF"/>
</svg>

            </div>
          </div>
          You can make changes to Timetable once setup
          <span>
          Timetable schedule can be edited from Timetable module or From respective Classes from School Directory > Class
          </span>
          
          <div className="popup-footer">
            <button
              className="cancel-button"
               onClick={() => {
                setprogress(1);
              }}
            >
              Cancel
            </button>
            <button
              className="save-button"
              style={{backgroundColor:'rgba(51, 92, 255, 1)'}}
              onClick={async() => {setprogress(3);await senddata()}}
           
            >
              Got it, Let’s Proceed
            </button>
          </div>
        </div>
      </div>
    );
}
