import React from "react";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { CancelButton } from "../../FeeManagementModule/FeeStructureModule/components/CancelButton";
import {SetupTimings} from "./SetupTimings";
import {AssignClasses} from "./AssignClasses";

import { IoIosArrowForward } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { createtimetable,BulkAssignSubjecttoTeacher } from "../../../api/api";
import { ProceedPopupInTT } from "../../StudentDirectoryModule/Components/ProceedPopupInTT";
// import { DecodeandEncodeDays } from "../../../home/components/DecodeandEncodeDays";
// DecodeandEncode
export const CreateNewTimetable = ({setShowCreateTimetable})=>{

  // const navigate = useNavigate();
  const [progress, setprogress] = useState(0);
  // const [finaldata, setfinaldata] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [editdata, setEditdata] = useState({
    timetable_title: "",
    selected_classes: [],
    classes_available:[],
    class_section_mappings_available:[],
    class_section: "",
    select_academic_programme: "",
    choose_program: "",
    range: [8, 16],
    working_hours: 8,
    working_days: [
    "Monday",
    "Friday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Saturday"
],
    number_of_periods: "",
    periods_duration: "",
    number_of_breaks: "",
    breaks_duration: "",
    enable_prayer: false,
    prayer_duration: "",
  });
  // const initialArray = Array.from({ length: timetable.select_classes.length }, () => Array(timetable.number_of_periods+2).fill(''));

  const [slotDetails, setSlotDetails] = useState([]);
  // const getfinaldata = (value) => {
  //   setfinaldata(value);
  // };
  useEffect(() => {
   console.log("slott details changed ",slotDetails);
  //  if(progress===3)
  }, [slotDetails])
  const parseTime = (timeString) => {
    const time = timeString.slice(0, -2);
    const modifier = timeString.slice(-2);
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
        hours = '00';
    }
    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }
    return new Date(new Date().setHours(hours, minutes, 0, 0));
};
const calculateDuration = (startTime, endTime) => {
  const start = parseTime(startTime);
  const end = parseTime(endTime);
  const durationInMinutes = (end - start) / 60000; // Convert milliseconds to minutes
  return durationInMinutes;
};
  const senddata = async () => {
    console.log("senfding",progress);
    console.log("slott details changed ",slotDetails);
    console.log("tt data",editdata);
    console.log("slots");
     let updatedwithduration =[];
     for(let i=0;i<timeSlots.length;i++){
      let temp=timeSlots[i];
      if(temp.start!==undefined)
      {const dur=calculateDuration(temp.start,temp.end);
      temp["duration"]=dur;
      updatedwithduration.push(temp);}
     }
     console.log(updatedwithduration);
     let prayer=editdata.is_prayer_enabled ?1:0;
     console.log(prayer);
    //  const ttid=34;
    let assignbulkdata=[];
    console.log(editdata.class_section_mapping_ids);
    for(let i=0;i<editdata.class_section_mapping_ids.length;i++){
      for(let j=0;j<updatedwithduration.length;j++){
        console.log(updatedwithduration[j]);
        if(updatedwithduration[j].period==='PRAYER')continue;
        // if(slotDetails.days[i][j]!=='')
        let temp={"class_section_mapping_id":editdata.class_section_mapping_ids[i],
          // time_table_id:ttid
          // "subject_id":1,
          // "period_duration": 30,
          // "period_seq_num": 2

        };
       
        if(updatedwithduration[j].period==='BREAK'){
          continue;
        }
        else{
          // console.log('hello');
          temp["subject_id"]=slotDetails.subject_ids[i][j];
          temp["assigned_staff_id"]=Number(slotDetails.staff_ids[i][j]);
          temp["week_days"]=slotDetails.days[i][j];
          temp["period_type_id"]=slotDetails.work_hour[i][j]===true?1:2;

        }
      
        temp["period_seq_num"]=j-prayer;
        temp["period_duration"]=updatedwithduration[j].duration;
        assignbulkdata.push(temp);
        if(updatedwithduration[j].period!=='BREAK' && slotDetails.days[i][j]!=='' && slotDetails.days[i][j]!==63){
          let temp={"class_section_mapping_id":editdata.class_section_mapping_ids[i],
          };
          temp["subject_id"]=slotDetails.subject_ids2[i][j];
          temp["assigned_staff_id"]=Number(slotDetails.staff_ids2[i][j]);
          temp["week_days"]=slotDetails.days2[i][j];
          temp["period_type_id"]=slotDetails.work_hour2[i][j]===true?1:2;
          temp["period_seq_num"]=j-prayer;
          temp["period_duration"]=updatedwithduration[j].duration;
          if(temp["assigned_staff_id"]!==0)
          assignbulkdata.push(temp);
        }
        // console.log(temp);
      }

    }
    for(let j=0;j<updatedwithduration.length;j++){
      let temp={
      };
     
      if(updatedwithduration[j].period==='BREAK'){
        temp["is_break"]=true;
         temp["period_seq_num"]=j-prayer;
      temp["period_duration"]=updatedwithduration[j].duration;
      assignbulkdata.push(temp);
      }
    }
    console.log(assignbulkdata);
    // const res = await createStudent(finaldata);
    // console.log(res);
    try {
      const ttid = await createtimetable(editdata);
     
      if (ttid) {
          await BulkAssignSubjecttoTeacher(ttid, {"classes": assignbulkdata});
          toast.success("Timetable created and subjects assigned successfully", {
              position: "top-right",
              autoClose: 5000,
          });
          // navigate('/timetable')
      } else {
          throw new Error("Failed to create timetable");
      }
  } catch (error) {
      console.error("Error creating timetable or assigning subjects:", error);
      toast.error("Failed to create timetable or assign subjects", {
          position: "top-right",
          autoClose: 5000,
      });
  }
    // if (res === "RESOURCE_CREATED_SUCCESSFULLY") {
    //   toast.success("Successfully Added Student", {
    //     position: "top-right",
    //     autoClose: 5000,
    //   });
    //   sessionStorage.clear();
    //   navigate("/studentdirectory");
    // }
    // else  {toast.success("Student Addition unsuccessfull", {
      //   position: "top-right",
    //   autoClose: 5000,
    // });}
  };
  const routes = [
    { number: 1, text: "Setup Timings", path: "" },
    { number: 2, text: "Assign Classes", path: "" },
    // { number: 3, text: "Setup Academic Details", path: ""},
    // { number: 4, text: "Finalise", path: "" },
  ];

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const [buttonstate, setbuttonstate] = useState(false);
  const findmandatorychecks = async (data = editdata) => {
    console.log(data);
    if(progress===0)
    {const requiredFields = [
      "timetable_title",
      "selected_classes",
     
     
      "select_academic_programme",
      "range",
      "working_hours",
      "working_days",
      "number_of_periods",
      "periods_duration",
      "number_of_breaks",
      "breaks_duration",
      "is_prayer_enabled",
      // "prayer_duration",
      // "timetable_start_time",
      // "timetable_end_time",
      
    ];
  
    for (const field of requiredFields) {
      if(field==="is_prayer_enabled"){
        if(data[field]===true){
          if(data["prayer_duration"]==="") {setbuttonstate(false);return;}
        }
      }
      else if (
        data?.[field] === undefined ||
        data?.[field] === null ||
        (Array.isArray(data?.[field]) && data?.[field].length === 0) ||
        (data?.[field] === '')
      ) {
        console.log(field,data[field]);
        setbuttonstate(false);
        return;
      }
    }}
  
  setbuttonstate(true);
  return;
  };
  useEffect(() => {
    console.log(progress);
    findmandatorychecks();
    //eslint-disable-next-line
  }, [progress]);

  const dynamicStyles = {
    display: "flex",
    background: "rgb(245, 247, 250)",
    color: "black",
    // pointerEvents: "none",
    cursor: "not-allowed",
  };

    return <div style={{ height: "90vh", overflow:"hidden" }}>

        <div className="headeradd">Create New Timetable</div>

        <div
        className="addstudentdiv"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "82vh",
          overflow: "hidden",
        }}
      >
        <div
          className="AddStudentBody"
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            height: "10rem",
          }}
        >
          <div className="SetupBarHeader" style={{ flexShrink: 0 }}>
            <div className="backbutton">
              <header className="setup-header">
                <div className="setup-header__routes">
                  {routes.map((route, index) => {
                    const isCompleted = index < progress;
                    const isCurrentRoute = index === progress;

                    return (
                      <div
                        key={route.number}
                        className={`setup-header__route ${
                          isCurrentRoute ? "current-route" : ""
                        }`}
                      >
                        {isCompleted ? (
                          <span className="setup-header__route-number route--checked">
                            <FaCheck size={"0.65rem"} />
                          </span>
                        ) : (
                          <span
                            className={`setup-header__route-number ${
                              isCurrentRoute ? "current-route-number" : ""
                            }`}
                          >
                            {route.number}
                          </span>
                        )}
                        <div
                          className={`setup-header__route-text ${
                            isCompleted || isCurrentRoute ? "checked-text" : ""
                          }`}
                          onClick={() => {
                            if (progress > index) setprogress(index);
                          }}
                        >
                          {route.text}
                        </div>
                        {index < routes.length - 1 && (
                          <IoIosArrowForward
                            size={"0.85rem"}
                            className="setup-header__route-arrow"
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </header>
            </div>
            <div>
              <button
                onClick={() => {
                  setIsCancelModalOpen(!isCancelModalOpen);
                }}
                style={{ cursor: "pointer" }}
                className="Cancel"
              >
                Cancel{" "}
                <RxCross1 className="crossicon" size={11} strokeWidth={0.5} />
              </button>
              <CancelButton
                isOpen={isCancelModalOpen}
                onClose={() => {
                  setIsCancelModalOpen(!isCancelModalOpen);
                  
                }}
                navto={"/timetable"}
              />
            </div>
          </div>
          <div
            className="AddStudentBodyComponents"
            style={{ flex: "1", overflowY: "auto", padding: "16px" }}
          >
            <button
              type="submit"
              className="back-button"
              onClick={() => {
                if (progress > 0) setprogress(progress - 1);
              }}
            >
              <div className="lefticon">
                <SlArrowLeft size={10} strokeWidth={90} />{" "}
              </div>
              Back
            </button>
            {progress === 0 && (
              <SetupTimings
                findmandatorychecks={findmandatorychecks}
                editdata={editdata}
                setEditdata={setEditdata}
                progressstate={setprogress}
              />
            )}
            {progress >= 1 && (
              <AssignClasses
                // findmandatorychecks={findmandatorychecks}
                slotDetails={slotDetails}
                setSlotDetails={setSlotDetails}
                timeSlots={timeSlots}
                setTimeSlots={setTimeSlots}
                timetable={editdata}
                settimetable={setEditdata}
                progressstate={setprogress}
              />
            )}
            
            <div> </div>
          </div>
          <div style={{ position: "relative" }}>
            <div className="buttonpopup-container" style={{zIndex:'10'}}>
              <button
                className="buttonpopup"
                style={
                  buttonstate === false ? dynamicStyles : { display: "flex" }
                }
                onClick={async (e) => {
                  console.log(progress);
                  if (progress >=3) {
                  
                    e.preventDefault();

                    // await senddata();
                  } else if (buttonstate === true) {
                    setprogress(progress + 1);
                  }
                  else{
                    toast.error("Please fill all Mandatory Fields", {
                      position: "top-right",
                      autoClose: 5000,
                  });
                  }
                }}
              >
                Save and Continue
                <div className="righticon" style={{ marginLeft: "6px",color: buttonstate === false ?'black':'' }}>
                  <SlArrowRight size={9} strokeWidth={70} />
                </div>
              </button>
            </div>
          </div>
        </div>
       {progress===2 && <ProceedPopupInTT setprogress={setprogress} senddata={senddata}/>}
      </div>
    </div>;
}