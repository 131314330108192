export const DecodeandEncodeDays = (task=63, days) => {
    console.log("Function called with task:", task, "and days:", days);
    // Bit values for each day of the week
    const daysMap = {
        'Monday': 1 << 0,      // 1st bit
        'Tuesday': 1 << 1,     // 2nd bit
        'Wednesday': 1 << 2,   // 3rd bit
        'Thursday': 1 << 3,    // 4th bit
        'Friday': 1 << 4,      // 5th bit
        'Saturday': 1 << 5,    // 6th bit
        'Sunday': 1 << 6       // 7th bit
    };

    // Encode a list of days into a unique number
    function encodeDays(days) {
        let encoded = 0;
        days.forEach(day => {
            if (daysMap[day]) {
                encoded |= daysMap[day];  // Turn on the bit for this day
            }
        });
        console.log("Encoded value:", encoded);
        return encoded;
    }

    // Decode a number back into the list of days
    function decodeDays(encoded) {
        const decodedDays = [];
        for (const [day, bitValue] of Object.entries(daysMap)) {
            if (encoded & bitValue) {  // Check if the bit for this day is set
                decodedDays.push(day);
            }
        }
        console.log("Decoded days:", decodedDays);
        return decodedDays;
    }

    // Return encoded number or decoded days based on the task
    if (task === "encode") {
        return encodeDays(days);
    } else if (task === "decode") {
        return decodeDays(days);
    } else {
        console.error("Invalid task:", task);
        return null; // or throw an error if task is invalid
    }
}