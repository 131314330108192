import React, { useRef, useState } from 'react';
import styles from './WeekDatePicker.module.css';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
// import { useOutsideClick } from '../../FeeManagementModule/FeeStructureModule/hooks';

export const WeekDatePicker = ({ onWeekChange,state=()=>{} }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedWeek, setSelectedWeek] = useState(2);
  const dropdownRef = useRef(null);
  // useOutsideClick(dropdownRef, state(false));
  React.useEffect(() => {
    // console.log("hello")
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // console.log("outside")
        state(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    //eslint-disable-next-line
  }, [dropdownRef]);
  const getMonthLabel = (date) => {
    const month = date.toLocaleDateString(undefined, { month: 'long' });
    const year = date.toLocaleDateString(undefined, { year: 'numeric' });
    return `${month}, ${year}`;
  };

  const getWeekLabel = (week) => {
    const suffix = ['th', 'st', 'nd', 'rd'];
    const v = week % 100;
    return `${week}${suffix[(v - 20) % 10] || suffix[v] || suffix[0]} week`;
  };

  const handlePrevWeek = () => {
    setSelectedWeek((prev) => {
      const newWeek = prev > 1 ? prev - 1 : prev;
      const newStartDate = getStartOfWeek(currentDate, newWeek);
      onWeekChange(newStartDate);
      return newWeek;
    });
  };

  const handleNextWeek = () => {
    setSelectedWeek((prev) => {
      const newWeek = prev + 1;
      const newStartDate = getStartOfWeek(currentDate, newWeek);
      onWeekChange(newStartDate);
      return newWeek;
    });
  };

  const handlePrevMonth = () => {
    const newDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
    setCurrentDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    setCurrentDate(newDate);
  };

  const getStartOfWeek = (date, week) => {
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const day = start.getDay();
    const diff = start.getDate() - day + (day === 0 ? -6 : 1) + (week - 1) * 7;
    return new Date(start.setDate(diff));
  };

  const handleDayClick = (date) => {
    const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    setCurrentDate(newDate);

    const startOfMonth = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    const dayOfWeek = startOfMonth.getDay() === 0 ? 6 : startOfMonth.getDay() - 1;
    const dayOfMonth = newDate.getDate();
    const weekNumber = Math.ceil((dayOfMonth + dayOfWeek) / 7);
    setSelectedWeek(weekNumber);
    onWeekChange(newDate);
  };

  const renderDates = () => {
    const startOfWeek = getStartOfWeek(currentDate, selectedWeek);
    const dates = [];
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  
    let week = [];
    let dayOfWeek = startDate.getDay() === 0 ? 6 : startDate.getDay() - 1; // Adjust for Monday start

    // Fill initial empty days with previous month's dates
    const prevMonthEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    for (let i = dayOfWeek - 1; i >= 0; i--) {
      const date = new Date(prevMonthEndDate.getFullYear(), prevMonthEndDate.getMonth(), prevMonthEndDate.getDate() - i);
      week.push(
        <div
          key={`prev-${date.getDate()}`}
          className={`${styles['pre-date']}`}
          onClick={() => handleDayClick(date)}
        >
          {date.getDate()}
        </div>
      );
    }

    for (let i = 1; i <= endDate.getDate(); i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
      const isSelected = date >= startOfWeek && date < new Date(startOfWeek.getTime() + 7 * 24 * 60 * 60 * 1000);
      const dayClass = isSelected
        ? (date.getDay() === 1 || date.getDay() === 6) // Monday or Saturday
          ? styles.highlighted
          : date.getDay() !== 0 // Exclude Sunday
          ? styles.gray
          : ''
        : '';

      week.push(
        <div
          key={i}
          className={`${dayClass} ${styles.date}`}
          style={{ color: isSelected && (date.getDay() === 1 || date.getDay() === 6) ? 'rgba(255, 255, 255, 1)' : isSelected && date.getDay() !== 0 ? 'rgba(14, 18, 27, 1)' : '' }}          onClick={() => handleDayClick(date)}
        >
          {i}
        </div>
      );

      if (week.length === 7) {
        dates.push(<div key={`week-${i}`} className={styles.week}>{week}</div>);
        week = [];
      }
    }

    // Fill remaining days with next month's dates
    const nextMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    for (let i = 1; week.length < 7; i++) {
      const date = new Date(nextMonthStartDate.getFullYear(), nextMonthStartDate.getMonth(), i);
      week.push(
        <div
          key={`next-${date.getDate()}`}
          className={`${styles['pre-date']}`}
          onClick={() => handleDayClick(date)}
        >
          {date.getDate()}
        </div>
      );
    }
    dates.push(<div key={`week-next`} className={styles.week}>{week}</div>);

    return dates;
  };
  const getWeeksInMonth = (date) => {
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const startDay = start.getDay() === 0 ? 6 : start.getDay() - 1; // Adjust for Monday start
    // const endDay = end.getDay() === 0 ? 6 : end.getDay() - 1; // Adjust for Monday start
    return Math.ceil((end.getDate() + startDay) / 7);
  };
  return (
    <div className={styles.datepicker} ref={dropdownRef}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={styles.header}>
         {selectedWeek > 1 && <button onClick={handlePrevWeek} disabled={selectedWeek <= 1}>
            <SlArrowLeft size={10} strokeWidth={90} color="rgba(82, 88, 102, 1)" />
          </button>}
          <span style={{marginTop:'0.1rem'}}>{getWeekLabel(selectedWeek)}</span>
          {selectedWeek < getWeeksInMonth(currentDate) &&<button onClick={handleNextWeek}  disabled={selectedWeek >= getWeeksInMonth(currentDate)}>
            <SlArrowRight size={10} strokeWidth={90} color="rgba(82, 88, 102, 1)" />
          </button>}
        </div>
        <div className={styles.header} style={{backgroundColor:'rgba(245, 247, 250, 1)',border:'0'}}>
          <button onClick={handlePrevMonth}>
            <SlArrowLeft size={10} strokeWidth={90} color="rgba(82, 88, 102, 1)" />
          </button>
          <span style={{marginTop:'0.2rem'}}>{getMonthLabel(currentDate)}</span>
          <button onClick={handleNextMonth}>
            <SlArrowRight size={10} strokeWidth={90} color="rgba(82, 88, 102, 1)" />
          </button>
        </div>
      </div>

      <div className={styles.calendar}>
        <div className={styles.days}>
          <div>MO</div>
          <div>TU</div>
          <div>WE</div>
          <div>TH</div>
          <div>FR</div>
          <div>SA</div>
          <div>SU</div>
        </div>
        <div className={styles.dates}>{renderDates()}</div>
      </div>
    </div>
  );
};